// prettier-ignore
export default {
  "-0.10 Very Low": "-0.10 Très bas",
  "% Mapa alertas": "% Carte d'alertes",
  "% Media Fosforo": "% Moyenne Phosphore",
  "% Media Nitrogeno": "% Moyenne Azote",
  "% Media Potasio": "% Moyenne Potassium",
  "% resiembra": "% Replantation",
  "0.10 Low": "0.10 Bas",
  "0.20 Very Low": "0.20 Très bas",
  "0.25 Very Low": "0.25 Très bas",
  "0.30 Low": "0.30 Bas",
  "0.30 Medium": "0.30 Moyen",
  "0.40  Low": "0.40  Bas",
  "0.40 Medium": "0.40 Moyen",
  "0.45 High": "0.45 Haut",
  "0.50 High": "0.50 Haut",
  "0.55 Medium": "0.55 Moyen",
  "0.65 Very High": "0.65 Très haut",
  "0.70 High": "0.70 Haut",
  "0.85 Very High": "0.85 Très haut",
  "abonado de cobertera": "Fertilisation de couverture",
  "abonado de fondo": "Fertilisation de fond",
  "abonado de fondo ": "Fertilisation de fond",
  "abonado dosificado": "Fertilisation dosée",
  "abonado tasa fija": "Fertilisation à taux fixe",
  "abonado zonificado": "Fertilisation zonée",
  "accumulated vegetative growth": "Croissance végétative accumulée",
  "Acre": "Acre",
  "active": "actif",
  "Add at least one recipient (+)": "Ajoutez au moins un destinataire (+)",
  "Add Field Button": "Bouton Ajouter un champ",
  "Add Field Tutorial": "Tutoriel : Ajouter des champs",
  "add location": "ajouter un lieu",
  "Add new sample": "Ajouter un nouvel échantillon",
  "add photo": "ajouter une photo",
  "added to pending": "ajouté en attente",
  "Advanced": "Avancé",
  "advanced season parameters": "paramètres saisonniers avancés",
  "afección": "affection",
  "afección extensivo": "affection extensive",
  "Affected Surface": "Surface affectée",
  "After filling the form, you can draw the field": "Après avoir rempli le formulaire, vous pouvez dessiner le champ",
  "agrodelivery": "AgroLivraison",
  "all": "tous",
  "ALMENDRA": "AMANDE",
  "alto estrés hídrico": "fort stress hydrique",
  "alto pot prod": "fort potentiel de production",
  "ambiente": "environnement",
  "An error occured": "Une erreur s'est produite",
  "An error occurred from the server. Please try again": "Une erreur s'est produite sur le serveur. Veuillez réessayer",
  "And that's it!": "Et voilà !",
  "anexo información": "annexe information",
  "Applied filters": "Filtres appliqués",
  "Apply": "Appliquer",
  "apply date filter": "appliquer le filtre de date",
  "Apply selection": "Appliquer la sélection",
  "Are you sure to close the editor?": "Êtes-vous sûr de vouloir fermer l'éditeur ?",
  "Are You sure?": "Êtes-vous sûr ?",
  "area": "zone",
  "Area": "Surface",
  "ATR": "Sucre Récupérable Total",
  "author": "Auteur",
  "Automatic harvest": "Récolte automatique",
  "auxiliary files": "fichiers auxiliaires",
  "back": "retour",
  "bajo estrés hídrico": "faible stress hydrique",
  "bajo pot prod": "faible potentiel de production",
  "Base temperature": "Température de base",
  "baseLayer": "Couche de base",
  "Basic": "Basique",
  "basic season parameters": "paramètres saisonniers de base",
  "bipolaris_sacchari_g1": "bipolaris sacchari \"tache d'œil\" - g1",
  "bipolaris_sacchari_g2": "bipolaris sacchari \"tache d'œil\" - g2",
  "bipolaris_sacchari_g3": "bipolaris sacchari \"tache d'œil\" - g3",
  "bipolaris_sacchari_g4": "bipolaris sacchari \"tache d'œil\" - g4",
  "bipolaris_sacchari_g5": "bipolaris sacchari \"tache d'œil\" - g5",
  "Both first and last points of the slice line must be outside of the polygon/circle": "Les premier et dernier points de la ligne de coupe doivent être à l'extérieur du polygone/cercle",
  "Buried": "Enterré",
  "calendar": "Calendrier",
  "calibre": "Calibre",
  "camera error message": "Il semble y avoir un problème de permission avec la caméra",
  "camera_unavailable": "caméra non disponible",
  "Can't modify field with active planet subscription": "Impossible de modifier le champ avec un abonnement planète actif",
  "cancel": "Annuler",
  "cane_num": "Nombre de cannes",
  "cantidad": "Quantité",
  "capa base": "Couche de base",
  "Capture screenshot": "Capturer une capture d'écran",
  "Change opacity": "Changer l'opacité",
  "Choose your layer": "Choisissez votre couche",
  "Choose your tool": "Choisissez votre outil",
  "Circle Drawing": "Dessin de cercle",
  "class": "Classe",
  "Class values must be numerical": "Les valeurs des classes doivent être numériques",
  "classes": "Classes",
  "classes number": "Nombre de classes",
  "clear": "Effacer",
  "Clear all": "Tout effacer",
  "clear form": "effacer le formulaire",
  "clear selected fields": "effacer les champs sélectionnés",
  "Clicking this button, you can toggle the visibility of the selected layer.": "En cliquant sur ce bouton, vous pouvez basculer la visibilité de la couche sélectionnée.",
  "clientDeviation": "Déviation du client",
  "Close": "Fermer",
  "Close editor": "Fermer l'éditeur",
  "Close selection": "Fermer la sélection",
  "Close the temporal Comparison": "Fermer la comparaison temporelle",
  "close_selected": "Fermer sélectionné",
  "closed": "fermé",
  "cloud": "Nuage",
  "Cloud Coverage": "Couverture nuageuse",
  "comment": "commentaire",
  "comp. temporal target": "Cible de comparaison temporelle",
  "comp. temporal variabilidad": "Variabilité de comparaison temporelle",
  "comparación de conteo": "Comparaison de comptage (%)",
  "comparación de estado hídrico": "Comparaison d'état hydrique (%)",
  "comparación de estado hídrico drone": "Comparaison d'état hydrique - Drone (%)",
  "comparación de estado hídrico sat": "Comparaison d'état hydrique - SAT (%)",
  "comparación de estimación de producción": "Comparaison d'estimation de production (%)",
  "comparación de fósforo": "Comparaison de phosphore (%)",
  "comparación de mapa de alertas": "Comparaison de carte d'alertes (%)",
  "comparación de mapa de faltas": "Comparaison de carte de défauts (%)",
  "comparación de media de conteo": "Comparaison de moyenne de comptage (%)",
  "comparación de media de estado hídrico": "Comparaison de moyenne d'état hydrique (%)",
  "comparación de media de fósforo": "Comparaison de moyenne de phosphore (%)",
  "comparación de media de mapa de alertas": "Comparaison de moyenne de carte d'alertes (%)",
  "comparación de media de mapa de faltas": "Comparaison de moyenne de carte de défauts (%)",
  "comparación de media de nitrógeno": "Comparaison de moyenne de nitrogène (%)",
  "comparación de media de potasio": "Comparaison de moyenne de potassium (%)",
  "comparación de media de producción total": "Comparaison de moyenne de production totale (%)",
  "comparación de media de rendimiento graso": "Comparaison de moyenne de rendement en matières grasses (%)",
  "comparación de medias de estimación de producción": "Comparaison de moyennes d'estimation de production (%)",
  "comparación de medias de ndvi": "Comparaison de moyennes de NDVI (%)",
  "comparación de medias de proteína": "Comparaison de moyennes de protéine (%)",
  "comparación de medias de variabilidad": "Comparaison de moyennes de variabilité (%)",
  "comparación de ndvi": "Comparaison de NDVI (%)",
  "comparación de nitrógeno": "Comparaison de nitrogène (%)",
  "comparación de nitrógeno relativo": "Comparaison de nitrogène relatif (%)",
  "comparación de potasio": "Comparaison de potassium (%)",
  "comparación de producción total": "Comparaison de production totale (%)",
  "comparación de proteína": "Comparaison de protéine (%)",
  "comparación de rendimiento graso": "Comparaison de rendement en matières grasses (%)",
  "comparación de sacarosa": "Comparaison de saccharose",
  "comparación de variabilidad": "Comparaison de variabilité (%)",
  "confirm": "Confirmer",
  "Congratulations! Your field has been created.": "Félicitations ! Votre champ a été créé.",
  "Congratulations! Your field has been edited.": "Félicitations ! Votre champ a été modifié.",
  "Contact": "Contact",
  "Contenido de sacarosa ( % )": "Teneur en saccharose ( % )",
  "conteo - pdf rgb": "comptage - pdf rgb",
  "conteo - shps": "comptage - shps",
  "conteo de individuos": "Comptage d'individus",
  "conteo lechugas": "Comptage de laitues",
  "continue": "continuer",
  "CORN": "MAÏS",
  "correction": "Correction",
  "Corte nitrogeno": "Coupe de nitrogène",
  "cost": "Coût",
  "count (units)": "Compte (unités)",
  "Counting": "Comptage",
  "create": "créer",
  "Create New Field": "Créer un nouveau champ",
  "CREATE_RANGES": "En fonction de l'histogramme de l'image, créez des plages avec un pourcentage de données similaires dans chaque plage.",
  "created": "Créé",
  "crecimiento cultivo": "Croissance des cultures",
  "Crop": "Culture",
  "Crop type": "Type de culture",
  "CROP_INSPECTION": "Inspection des cultures",
  "cultive": "Cultiver",
  "Cultivo": "Culture",
  "current season": "saison actuelle",
  "current selection could take a moment to process": "La sélection actuelle pourrait prendre un moment à traiter",
  "Customer": "Client",
  "customerProductionEstimation": "Estimation de production du client",
  "customers": "Clients",
  "cut": "couper",
  "Cutter": "Coupeur",
  "dashboard": "Tableau de bord",
  "date": "date",
  "DD/MM/YYYY": "JJ/MM/AAAA",
  "delete": "supprimer",
  "Delete": "Supprimer",
  "Delete downloaded report": "Supprimer le rapport téléchargé",
  "delete downloaded sample": "supprimer l'échantillon téléchargé",
  "Delete field": "Supprimer le champ",
  "delete layers?": "supprimer les couches ?",
  "delete sample": "supprimer l'échantillon",
  "delete sample edition": "supprimer l'édition de l'échantillon",
  "DeleteField": "Le champ a été supprimé",
  "Deliverables tutorial": "Tutoriel : Livrables",
  "Description": "Description",
  "Deselect": "Désélectionner",
  "deselect all": "tout désélectionner",
  "deviation": "Déviation",
  "Did you forget your password ?": "As-tu oublier ton mot de passe ?",
  "disponible en visor": "disponible dans le visualiseur",
  "do you want to delete": "voulez-vous supprimer",
  "documentation": "Documentation",
  "don't show again": "ne plus afficher",
  "Done": "Terminé",
  "dosificación genérica": "Dosage générique",
  "Dosis Media Cliente": "Dose moyenne client",
  "Dosis media Propuesta": "Dose moyenne proposée",
  "dosis riego": "Dose d'irrigation",
  "Download": "Télécharger",
  "download failed": "Le téléchargement a échoué",
  "Download list": "Liste de téléchargement",
  "Download selected fields samples": "Télécharger les échantillons des champs sélectionnés",
  "download selected geometries": "télécharger les géométries sélectionnées",
  "download values": "télécharger les valeurs",
  "downloaded": "téléchargé",
  "downloading layers": "téléchargement des couches",
  "downloads": "Téléchargements",
  "Drag and drop your layers to arrange them in the list.": "Glissez-déposez vos couches pour les organiser dans la liste.",
  "Drag Fields": "Glisser les champs",
  "Drag your layers": "Glissez vos couches",
  "Draw Field": "Dessiner un champ",
  "dron": "Drone",
  "Dron sectores": "Secteurs du drone",
  "drop here": "déposer ici",
  "Dry": "Sec",
  "dry matter": "Matière sèche",
  "dryMatterField": "Matière sèche du champ",
  "dryMatterLaboratory": "Matière sèche en laboratoire",
  "dsm": "DSM",
  "earliest harvest date": "Date de récolte la plus précoce",
  "edit": "modifier",
  "Edit Field": "Modifier le champ",
  "Edit geometry": "Modifier la géométrie",
  "edit range": "Modifier la plage",
  "Edit values": "Modifier les valeurs",
  "ej 100": "Ex 100",
  "End Date": "Date de fin",
  "Equal Interval": "Intervalle Égal",
  "Equidistant ranges": "Plages équidistantes",
  "error fetching classes": "Le ciel est trop nuageux à la date sélectionnée. Ajustez le filtre nuages du calendrier pour trouver une journée plus dégagée.",
  "estado hídrico": "État hydrique",
  "estado hídrico (ndmi)": "État hydrique (NDMI)",
  "estado hidrico sat": "NDRE",
  "estimación": "Estimation",
  "estimación producción": "Estimation de la production",
  "Estimacion produccion ( Tn / Ha )": "Estimation de la production ( Tn / Ha )",
  "estimación producción cualitativa": "Estimation qualitative de la production",
  "estimación proteína": "Estimation de la protéine",
  "Estimacion proteina ( % )": "Estimation de la protéine ( % )",
  "estimación sacarosa": "Estimation de la saccharose",
  "estimación sacarosa ": "Estimation de la saccharose",
  "estimación sacarosa cualitativa": "Estimation qualitative de la saccharose",
  "Estimacion Total ( Kg )": "Estimation totale ( Kg )",
  "estimation gauging": "Jaugeage d'estimation",
  "estrategia de dosificacion": "Stratégie de dosage",
  "estrés hídrico (lws)": "Stress hydrique (LWS)",
  "evolución de NDVI": "Évolution du NDVI",
  "evolution": "Évolution",
  "Ex : comment": "Ex : commentaire",
  "Ex : field R02": "Ex : champ R02",
  "Ex : Tresbolillos, row, tree": "Ex : Tresbolillos, rangée, arbre",
  "expected_final_weight": "Poids final attendu",
  "external_id": "ID externe",
  "extra parameters": "Paramètres supplémentaires",
  "falhas carreadores": "Lacunes des transporteurs",
  "falhas internas": "Lacunes internes",
  "falhas terraços": "Lacunes des terrasses",
  "falla": "Écart",
  "fat performance": "Performance des matières grasses",
  "faults map": "Carte des défauts",
  "fdn": "FDN",
  "Fecha de vuelo": "Date du vol",
  "Fecha Plantacion": "Date de plantation",
  "Feddan": "Feddan",
  "fertilization strategy": "Stratégie de fertilisation",
  "Fertilizer recommendation": "Recommandation d'engrais",
  "field": "Champ",
  "Field Name": "Nom du champ",
  "Field without geometry": "Champ sans géométrie",
  "FIELD_FILTER": "Filtres de champ",
  "field_name": "Nom du champ",
  "FIELD_TEMPORAL_EVOLUTION": "Évolution temporelle du champ",
  "fields": "Champs",
  "Fields without geometries": "Champs sans géométries",
  "File cannot be generated due to high amounts of clouds. Please select another date": "Le fichier ne peut pas être généré en raison d'une grande quantité de nuages. Veuillez sélectionner une autre date",
  "File generator": "Générateur de fichiers",
  "File Generator": "Générateur de fichiers",
  "File name": "Nom du fichier",
  "file type": "Type de fichier",
  "file-is-not-available-in-selected-language": "Ce fichier n'est pas disponible dans la langue sélectionnée. Généré en anglais.",
  "Fill plantation date of current field, showing one year of historic data": "Renseigner la date de plantation du champ actuel, montrant un an de données historiques",
  "Fill the field form": "Remplir le formulaire du champ",
  "Fill the form to apply for all our services": "Remplissez le formulaire pour solliciter tous nos services",
  "filtered field": "Champ filtré",
  "filtered fields": "Champs filtrés",
  "Filters": "Filtres",
  "final_weight": "Poids final",
  "fitopatógenos": "Phytopathogènes",
  "Flight Slider": "Curseur de vol",
  "FLIGHT_FILTER": "Comparaison temporelle",
  "flights type": "Type de vols",
  "fmsavi": "FMSAVI",
  "for everyone": "pour tout le monde",
  "for me": "pour moi",
  "forecasts": "Prévisions",
  "formulario de abonado": "Formulaire de fertilisation",
  "formulario de dosis": "Formulaire de dosage",
  "fósforo foliar": "Phosphore foliaire",
  "fr": "Francium",
  "Fraction": "Fraction",
  "free": "gratuit",
  "from": "De",
  "fruits_100_weight": "Poids de 100 fruits",
  "fruits_actual1_weight": "Poids réel du fruit",
  "Fuente de informacion": "Source d'information",
  "gallery": "galerie",
  "gauging date": "Date de jaugeage",
  "generation error": "Erreur de génération",
  "geometry": "géométrie",
  "get a free trial": "essayer gratuitement",
  "Get back to editor": "Retourner à l'éditeur",
  "global files": "Fichiers globaux",
  "global layer": "Couche globale",
  "gm_cut_number": "Numéro de coupe",
  "gm_data_generation": "Date des données par satellite (semaines)",
  "gm_day_cycle": "Jours de culture",
  "gm_harvest_estimated_date": "Date estimée de la récolte (semaines)",
  "gm_hemav_atr_estimation": "Prévision du TRS",
  "gm_hemav_pol_estimation": "Prévision de la polarisation",
  "gm_hemav_prodph_estimation": "Prévision du rendement",
  "gm_hemav_realprodph_lastseason": "Rendement réel de la saison précédente",
  "gm_hemav_sac_estimation": "Prévision de la saccharose",
  "gm_luvi_date_generation": "Date des données par satellite - LUVI (semaines)",
  "gm_luvi_day_cycle": "Jours de culture (NDVI-LUVI)",
  "gm_luvi_mean": "Vigueur végétative (NDVI-LUVI)",
  "gm_luvi_ndvi_std": "Variabilité (NDVI-LUVI)",
  "gm_lws_mean": "Stress hydrique (LWS)",
  "gm_ndmi_date_generation": "Date des données par satellite - NDMI (semaines)",
  "gm_ndmi_day_cycle": "Jours de culture (NDMI)",
  "gm_ndre_date_generation": "Date des données par satellite - NDRE (semaines)",
  "gm_ndre_day_cycle": "Jours de culture (NDRE)",
  "gm_season_label": "Saison",
  "gm_sub_type_name": "Variété",
  "gm_type_name": "Culture",
  "go to field": "Aller au champ",
  "grid": "grille",
  "harvest date": "Date de récolte",
  "harvest planning": "Planification de la récolte",
  "HARVEST_PLANNING_TOOL": "Outil de planification de la récolte",
  "HARVEST_PREDICTION": "Prédiction de la récolte",
  "Hectare": "Hectare",
  "Hemav Default": "Défaut Hemav",
  "HEMAV Default": "HEMAV Défaut",
  "Here, you can play with the preview of your different layers.": "Ici, vous pouvez jouer avec l'aperçu de vos différentes couches.",
  "hide": "cacher",
  "Hide All Layers": "Cacher toutes les couches",
  "hide filter list": "cacher la liste des filtres",
  "Hide Global Layer": "Cacher la couche globale",
  "Hide Measure Tools": "Cacher les outils de mesure",
  "high": "Élevé",
  "high level": "Niveau élevé",
  "high need": "Besoin élevé",
  "higher clay content": "Teneur plus élevée en argile",
  "humedad de suelo - beta (%)": "Humidité du sol - Beta (%)",
  "humedad vegetativa (ndmi)": "Humidité végétative (NDMI)",
  "Hybrid View": "Vue hybride",
  "hydric status": "État hydrique - NDMI",
  "hydric status drone": "État hydrique - Drone",
  "If foliar sampler haven't been made, the date you must enter is the current": "Si aucun échantillon foliaire n'a été prélevé, la date à saisir est la date actuelle",
  "If you have two or more flights, you can compare them temporally using this tool.": "Si vous avez deux vols ou plus, vous pouvez les comparer temporellement à l'aide de cet outil.",
  "If you have undone an action, you can press this button to redo it.": "Si vous avez annulé une action, vous pouvez appuyer sur ce bouton pour la refaire.",
  "image": "image",
  "Impacto estimado": "Impact estimé",
  "In this tab, you can download the generated PDFs.": "Dans cet onglet, vous pouvez télécharger les PDF générés.",
  "individuals_num": "Nombre d'individus",
  "infection": "Infection",
  "info": "Informations",
  "Information": "Information",
  "informe": "Rapport",
  "integración con maquinaria": "Intégration avec la machinerie",
  "Invalid credentials": "Identifiants invalides",
  "invalid date": "Date invalide",
  "Invalid email": "E-mail invalide",
  "invest": "Investir",
  "Irrigation recommendation": "Recommandation d'irrigation",
  "Irrigation type": "Type d'irrigation",
  "It is commonly adjusted to establish the amount of fertilizer or seeds to be applied per unit area": "Il est couramment ajusté pour établir la quantité d'engrais ou de graines à appliquer par unité de surface.",
  "item_num": "Numéro d'article",
  "Keep selection": "Conserver la sélection",
  "label": "Étiquette",
  "Label": "Étiquette",
  "languaje": "Langage",
  "last sample": "Dernier échantillon",
  "lat": "Latitude",
  "layers": "Couches",
  "layers estrés hídrico (lws)": "Couches de stress hydrique (LWS)",
  "layers status": "Statut des couches",
  "Legal limitation": "Limitation légale",
  "leptosphaeria_sacchari_g1": "leptosphaeria sacchari \"tache annulaire\" - g1",
  "leptosphaeria_sacchari_g2": "leptosphaeria sacchari \"tache annulaire\" - g2",
  "leptosphaeria_sacchari_g3": "leptosphaeria sacchari \"tache annulaire\" - g3",
  "leptosphaeria_sacchari_g4": "leptosphaeria sacchari \"tache annulaire\" - g4",
  "leptosphaeria_sacchari_g5": "leptosphaeria sacchari \"tache annulaire\" - g5",
  "letter only": "Lettres uniquement",
  "linear meters": "Mètres linéaires",
  "líneas de cultivo": "Lignes de culture",
  "list": "liste",
  "loading": "Chargement",
  "location": "Localisation",
  "Log in": "Se connecter",
  "logout": "Se déconnecter",
  "lon": "Longitude",
  "looks like its taking too long": "on dirait que ça prend trop de temps",
  "low": "Faible",
  "low level": "Niveau faible",
  "low need": "Faible besoin",
  "lower clay content": "Teneur en argile plus faible",
  "LUVI variability": "Variabilité LUVI",
  "Machines Type": "Type de machines",
  "main_macronutrient": "Macronutriment principal",
  "malas hierbas": "Mauvaises herbes",
  "malas hierbas - hoja estrecha": "Mauvaises herbes - Feuille étroite",
  "malas hierbas - hoja larga": "Mauvaises herbes - Feuille large",
  "malas hierbas - zonificado": "Mauvaises herbes - Zoné",
  "malas hierbas shps": "Mauvaises herbes SHP",
  "malas hierbas shps - hoja estrecha": "Mauvaises herbes SHP - Feuille étroite",
  "malas hierbas shps - hoja larga": "Mauvaises herbes SHP - Feuille large",
  "malas hierbas shps - zonificado": "Mauvaises herbes SHP - Zoné",
  "maleza": "Mauvaises herbes",
  "Manage report": "Gérer le rapport",
  "max 2 years": "Max 2 ans",
  "max comparable fields": "Champs comparables max",
  "maximo de variacion": "Variation maximale",
  "máximo vigor dos meses (ndvi)": "Vigueur maximale sur deux mois (NDVI)",
  "máximo vigor un año (ndvi)": "Vigueur maximale sur un an (NDVI)",
  "Maximun cloud coverage": "Couverture nuageuse maximale",
  "mean": "Moyenne",
  "Measures tool tutorial": "Tutoriel : Outils de mesure",
  "Measures tools": "Outils de mesure",
  "media de faltas": "Moyenne des manquements",
  "medias": "Médias",
  "medio pot prod": "Potentiel de production moyen",
  "medium": "Moyen",
  "medium clay content": "Teneur moyenne en argile",
  "medium level": "Niveau moyen",
  "medium_weight_bigSize": "Poids moyen des gros fruits",
  "medium_weight_mediumSize": "Poids moyen des fruits moyens",
  "medium_weight_smallSize": "Poids moyen des petits fruits",
  "Mensual limit": "Limite mensuelle",
  "Message": "Message",
  "METEOROLOGICAL_STATISTICS": "Statistiques météorologiques",
  "mode": "Mode",
  "model type": "Type de modèle",
  "Move the slider": "Déplacer le curseur",
  "msavi": "MSAVI",
  "N_BUNCHES": "Nombre de régimes",
  "Name": "Nom",
  "nameless field": "Champ sans nom",
  "Natural Breaks (Jenks)": "Ruptures Naturelles (Jenks)",
  "ndre": "NDRE",
  "ndvi": "NDVI",
  "ndvi real": "NDVI réel",
  "ndvi-curvas de nivel": "NDVI-Courbes de niveau",
  "Network request failed": "Échec de la demande réseau",
  "new sample": "Nouvel échantillon",
  "New sample created.": "Nouvel échantillon créé.",
  "NITRO": "Azote",
  "nitrogen chlorophyll (ndre)": "Chlorophylle azotée (NDRE)",
  "nitrogen_assimilable": "Azote assimilable",
  "nitrógeno foliar": "Azote foliaire",
  "Nivel de estres": "Niveau de stress",
  "no data": "Pas de données",
  "no data available": "Aucune donnée disponible",
  "no data with current selection": "Pas de données avec la sélection actuelle",
  "No fields selected": "Aucun champ sélectionné",
  "no fields to clear": "Aucun champ à effacer",
  "NO_DELIVERABLES_IMAGES_MESSAGE": "Il n'y a pas d'images pour cette journée.",
  "Not a user yet ?": "Pas encore utilisateur ?",
  "not enough data to display this chart": "Pas assez de données pour afficher ce graphique",
  "Not supported by browser": "Non pris en charge par le navigateur",
  "notes": "Notes",
  "number only": "Nombres uniquement",
  "Numero vuelo": "Nombre de vols",
  "observations": "Observations",
  "offline access": "Accès hors ligne",
  "offline mode": "Hors ligne",
  "oil_yield": "Rendement en huile",
  "ok": "OK",
  "Once you are done, click this button to save the changes.": "Une fois terminé, cliquez sur ce bouton pour enregistrer les modifications.",
  "open": "Ouvrir",
  "Open": "Ouvrir",
  "Open selection": "Ouvrir la sélection",
  "open temporal comparison": "Ouvrir la comparaison temporelle",
  "open_selected": "Ouvrir sélectionné",
  "optimize": "Optimiser",
  "or": "ou",
  "Order by a - z": "Trier de A à Z",
  "Order by distance to the field": "Trier par distance au champ",
  "Order by z - a": "Trier de Z à A",
  "ourDeviation": "Notre écart",
  "ourProductionEstimation": "Notre estimation de production",
  "Parcela": "Parcelle",
  "Password": "Mot de passe",
  "pb": "Pb",
  "pcd": "PCD",
  "pcd arbol": "PCD arbre",
  "pcd interpolado": "PCD interpolé",
  "pcd row": "Rangée PCD",
  "pdf": "PDF",
  "pending": "en attente",
  "phospohorus": "Phosphore",
  "phosporus": "Phosphore (ppm)",
  "photo": "photo",
  "photoid": "ID photo",
  "photoId": "Photo",
  "picture_path": "Chemin de l'image",
  "plague": "Fléau",
  "planned dosage": "Dosage prévu",
  "Plant emitters": "Émetteurs de plantes",
  "plantation date": "Date de plantation",
  "Plantation date": "Date de plantation",
  "plantation_end_date_range": "Plage de dates de plantation",
  "plantation_start_date_range": "Plage de dates de début de plantation",
  "Plantations": "Plantations",
  "plants": "Plantes",
  "Plants distance": "Distance entre les plantes",
  "plants_density_range": "Plage de densité des plantes",
  "Please wait or order one to have data to visualized.": "Veuillez attendre ou en commander un pour avoir des données à visualiser.",
  "Please, choose a field variety": "Veuillez choisir une variété de champ",
  "Please, choose the field owner": "Veuillez choisir le propriétaire du champ",
  "plural selected": "sélectionnés",
  "POL": "Polarisation",
  "Polygon drawing": "Dessin de polygone",
  "posible falla": "Écart potentiel",
  "posible maleza": "Mauvaises herbes potentielles",
  "positive number only": "Nombre positif uniquement",
  "positive number only and minor 100": "Nombre positif uniquement et inférieur à 100",
  "pot. horticolar 90": "pot. horticole 90",
  "pot. otros cultivos 150": "pot. autres cultures 150",
  "pot. otros cultivos 90": "pot. autres cultures 90",
  "potasio foliar": "Potassium foliaire",
  "potencial productivo histórico (ndvi)": "Potentiel de production historique (NDVI)",
  "precio": "Prix",
  "PRED_TECH": "PRED_TECH",
  "Predefined ranges by HEMAV which are generally appropriate for the majority of fields": "Plages prédéfinies par HEMAV qui sont généralement appropriées pour la majorité des champs.",
  "prediction": "Prédiction",
  "preprocessing": "Prétraitement",
  "Press this button to add a new field.": "Appuyez sur ce bouton pour ajouter un nouveau champ.",
  "Pressing this button lets you go back to the field view.": "Appuyer sur ce bouton vous permet de revenir à la vue du champ.",
  "Preview": "Aperçu",
  "price Kg": "Prix par kg",
  "primer vuelo": "Premier vol",
  "PROD": "Rendement par unité de surface",
  "producción esperada": "Rendement attendu",
  "product Kg": "Produit en kg",
  "product Kg is mandatory": "Le produit en kg est obligatoire",
  "Production estimate": "Estimation de la production",
  "production estimate (units)": "Estimation de la production (unités)",
  "profile": "Profil",
  "protein": "Protéine",
  "puccinia_sp_g1": "puccinia sp \"rouille\" - g1",
  "puccinia_sp_g2": "puccinia sp \"rouille\" - g2",
  "puccinia_sp_g3": "puccinia sp \"rouille\" - g3",
  "puccinia_sp_g4": "puccinia sp \"rouille\" - g4",
  "puccinia_sp_g5": "puccinia sp \"rouille\" - g5",
  "Quick start with Azure": "Accès rapide avec Azure",
  "Random fertilization": "Fertilisation aléatoire",
  "Random seedling": "Semis aléatoire",
  "Range": "Gamme",
  "Ranges adjusted to the index": "Plages ajustées à l'indice",
  "Ranges of the same size will be generated according to the minimum and maximum values of the image. In cases where the image is very homogeneous, it is likely that no differentiation will be seen.": "Des plages de même taille seront générées selon les valeurs minimale et maximale de l'image. Dans les cas où l'image est très homogène, il est probable qu'aucune différenciation ne soit visible.",
  "realProduction": "Production réelle",
  "Recipients": "Destinataires",
  "recomendación abonado dosificado": "Prescription de fertilisation dosée",
  "recomendación abonado zonificado": "Recommandation de fertilisation zonée",
  "Recomendacion de riego": "Conseil d'irrigation",
  "recomendación riego": "Recommandation d'irrigation",
  "Redo Action": "Refaire l'action",
  "Related date": "Date associée",
  "relative nitrogen": "Azote relatif",
  "Rendimiento graso ( % )": "Rendement en matière grasse ( % )",
  "report": "Rapport",
  "Report sent correctly": "Rapport envoyé correctement",
  "report type": "Type",
  "Report type": "Type de rapport",
  "report_type_action_description": "Proposition de traitements ou d'actions à appliquer",
  "report_type_annotation_description": "Diagnostic : Analyse sur le terrain",
  "report_type_inspection_description": "Analyse à partir des COUCHES (elle se ferme une fois analysée)",
  "report_type_result_description": "Analyse des résultats de la solution ou du traitement appliqué.",
  "reporte agronómico": "Rapport agronomique",
  "Reported by": "Rapporté par",
  "Reported fields": "Champs rapportés",
  "reports manager": "Gestionnaire de rapports",
  "required": "requis",
  "Required": "Requis",
  "Requires 3 characters minimun": "Nécessite un minimum de 3 caractères",
  "Reset": "Réinitialiser",
  "resiembra": "Resemis",
  "resolution": "Résolution SHP (m)",
  "resolution (only SHP)": "Résolution (uniquement pour SHP)",
  "retención de agua": "Rétention d'eau",
  "retry": "réessayer",
  "rgb": "RVB",
  "richness": "Richesse",
  "riego zonificado": "Irrigation zonée",
  "riesgo malherbología": "Risque de mauvaises herbes",
  "Ruler": "Règle",
  "SAC": "Saccharose par unité de surface",
  "saccharose estimate": "Estimation du saccharose",
  "sample": "Échantillon",
  "sample type": "type d'échantillon",
  "Sample was deleted.": "L'échantillon a été supprimé.",
  "Sample was successfully modified.": "L'échantillon a été modifié avec succès.",
  "sample_creation_date": "Date de création",
  "samples": "Échantillons",
  "satélite": "Satellite",
  "Satellite button tutorial": "Tutoriel du bouton Satellite",
  "Satellite View": "Vue Satellite",
  "save": "Sauvegarder",
  "Save field": "Sauvegarder le champ",
  "save_": "sauvegarder",
  "search": "Rechercher",
  "Search": "Recherche",
  "season": "saison",
  "Season label": "Étiquette de la saison",
  "season name": "nom de la saison",
  "SEASON_EVOLUTION": "Évolution de la saison",
  "Sector": "Secteur",
  "sectores target": "Secteurs cibles",
  "See? Now, clicking the button again lets you go back to the satellite view.": "Voyez ? Maintenant, en cliquant à nouveau sur le bouton, vous pouvez revenir à la vue satellite.",
  "seguimiento_cultivo.xlsx": "seguimiento_cultivo.xlsx",
  "segundo vuelo": "Deuxième vol",
  "Select": "Sélectionner",
  "select a crop and sample": "sélectionnez une culture et un échantillon",
  "select all": "tout sélectionner",
  "Select all": "Tout sélectionner",
  "select at least one field": "sélectionnez au moins un champ",
  "select fields by": "sélectionnez les champs par",
  "select fields by variability range": "sélectionnez les champs par plage de variabilité",
  "select fields to open temporal comparison": "sélectionnez les champs pour ouvrir la comparaison temporelle",
  "select one": "sélectionnez-en un",
  "select one customer please": "veuillez sélectionner un client",
  "select one subtype please": "veuillez sélectionner un sous-type",
  "select one type please": "veuillez sélectionner un type",
  "select sample base layer and date": "sélectionnez la couche de base de l'échantillon et la date",
  "select sample location": "sélectionnez l'emplacement de l'échantillon",
  "select season": "sélectionnez la saison",
  "selected": "sélectionné",
  "Selected fields names": "Noms des champs sélectionnés",
  "Selected fields samples": "Échantillons des champs sélectionnés",
  "Selection includes too much information, try selecting a shorter date range": "La sélection comprend trop d'informations, essayez de sélectionner une plage de dates plus courte",
  "Send": "Envoyer",
  "Send report": "Envoyer le rapport",
  "settings": "Paramètres",
  "Share fields report": "Partager le rapport des champs",
  "show all": "tout afficher",
  "Show All Layers": "Afficher toutes les couches",
  "Show current season only": "Afficher uniquement la saison en cours",
  "show fields with no data": "afficher les champs sans données",
  "show filter list": "afficher la liste des filtres",
  "Show Global Layer": "Afficher la couche globale",
  "Show Measure Tools": "Afficher les outils de mesure",
  "shp-tooltip": "Ces chiffres peuvent être modifiés selon les besoins d'utilisation et sont associés à chaque classe de géométrie dans la table d'attributs SHP exportée. Il est couramment ajusté pour définir la quantité d'engrais ou de semences à appliquer par unité de surface.",
  "singular selected": "sélectionné",
  "Slice tool only works on Polygons and Circle": "L'outil de découpe fonctionne uniquement sur les polygones et les cercles",
  "Slider Tool": "Outil curseur",
  "soil": "Sol",
  "soil_type": "Type de sol",
  "sólo para la generación de SHP": "Uniquement pour la génération de SHP",
  "Sort": "Trier",
  "sort by date": "trier par date",
  "Start date": "Date de début",
  "static layers": "couches statiques",
  "Statistics": "Statistiques",
  "status": "Statut",
  "stem_num": "Nombre de tiges",
  "Streets distance": "Distance des rues",
  "Subtype": "Sous-type",
  "Suc/Ha": "Suc/Ha",
  "sum_of_bases": "Somme des bases",
  "Superficie ( Ha )": "Superficie ( Ha )",
  "Superficie Sector ( Ha )": "Superficie Secteur ( Ha )",
  "surfaceUnit": "Unité de surface",
  "switch between all samples and current season samples": "basculer entre tous les échantillons et les échantillons de la saison en cours",
  "Switch to hybrid map": "Passer à la carte hybride",
  "Switch to Mapbox": "Passer à la carte satellite2",
  "Switch to satellite map": "Passer à la carte satellite",
  "Switch to Terrain map": "Passer à la carte du terrain",
  "target date": "date cible",
  "target multiparcela": "Cible multi-parcelles",
  "tell us": "dites-nous",
  "Temporal Comparison tutorial": "Tutoriel de comparaison temporelle",
  "textura del suelo": "Texture du sol",
  "The date should be in DD/MM/YYYY format": "La date doit être au format JJ/MM/AAAA",
  "The distance must be in meters": "La distance doit être en mètres",
  "the extra parameters will be removed": "les paramètres supplémentaires seront supprimés",
  "the following layers are no longer available in offline mode, download again if necessary": "les couches suivantes ne sont plus disponibles en mode hors ligne, téléchargez-les à nouveau si nécessaire",
  "The production must be numeric/decimal": "La production doit être numérique/décimale",
  "The right column shows the total amount to be applied according to the value entered per class, and the total per plot at the bottom": "La colonne de droite montre la quantité totale à appliquer en fonction de la valeur saisie par classe, et le total par parcelle en bas.",
  "The sample location must be inside one of the fields.": "L'emplacement de l'échantillon doit être à l'intérieur de l'un des champs.",
  "There are no samples to download": "Il n'y a pas d'échantillons à télécharger",
  "There is no operated flight on this field.": "Il n'y a pas de vol opéré sur ce champ",
  "Thermal Accumulation": "Accumulation thermique",
  "These numbers can be modified according to application needs and are associated with each geometry in the attribute table of the exported SHP": "Ces nombres peuvent être modifiés selon les besoins de l'application et sont associés à chaque géométrie dans la table d'attributs du SHP exporté.",
  "This action will delete the field and all its related data": "Cette action supprimera le champ et toutes ses données associées",
  "this action will modify your field selection": "cette action modifiera votre sélection de champs",
  "this action will open field view and lose current field selection": "cette action ouvrira la vue du champ et perdra la sélection actuelle du champ",
  "This button allows you to draw lines or polygons to measure a distance or area.": "Ce bouton vous permet de dessiner des lignes ou des polygones pour mesurer une distance ou une surface.",
  "This button allows you to switch to the hybrid view.": "Ce bouton vous permet de passer à la vue hybride",
  "This can take a long time": "Cela peut prendre beaucoup de temps",
  "This field is not downloaded.": "Ce champ n'est pas téléchargé",
  "This tool lets you cut polygons. Use single clicks to draw the line and a double click to finish drawing.": "Cet outil vous permet de découper des polygones. Utilisez des clics simples pour tracer la ligne et un double clic pour terminer le dessin.",
  "This tool lets you draw polygons. Try to draw a simple one. Make sure you close it!": "Cet outil vous permet de dessiner des polygones. Essayez d'en dessiner un simple. Assurez-vous de le fermer !",
  "This tool lets you measure the distance between a set of points. Use single clicks to set the points and a double click to finish drawing.": "Cet outil vous permet de mesurer la distance entre un ensemble de points. Utilisez des clics simples pour définir les points et un double clic pour terminer le dessin.",
  "Those fields are mandatory": "Ces champs sont obligatoires",
  "TIME_COMPARISON_DROP_ZONE": "Déposez ici...",
  "TINTA BARROCA": "ENCRE BAROQUE",
  "TINTA FRANCISCA": "ENCRE FRANCISCA",
  "TINTO": "ROUGE",
  "TINTO CÃO": "ROUGE CÃO",
  "tipo de dosificacion": "Type de dosage",
  "to": "À",
  "today": "aujourd'hui",
  "todos": "tous",
  "tons of cane - annual vision": "Tonnes de canne - vision annuelle",
  "Total": "Total",
  "total production": "Production totale (Kg)",
  "total_fruits_bigSize": "Total des gros fruits",
  "total_fruits_mediumSize": "Total des fruits moyens",
  "total_fruits_smallSize": "Total des petits fruits",
  "total_weight": "Poids total",
  "total_weight_bigSize": "Poids total des gros fruits",
  "total_weight_canes": "Poids total des cannes",
  "total_weight_mediumSize": "Poids total des fruits moyens",
  "total_weight_smallSize": "Poids total des petits fruits",
  "TOURIGA FRANCA": "TOURIGA FRANCA",
  "training": "Formation",
  "TRINCADEIRA": "TRINCADEIRA",
  "Try our mobile app for a better experience": "Essayez notre application mobile pour une meilleure expérience",
  "type": "Culture",
  "Type": "Type de culture",
  "type here your notes": "tapez ici vos notes",
  "Type your text": "Tapez votre texte",
  "type_sampler": "type d'échantillonneur",
  "Undo Action": "Annuler l'action",
  "unidades": "Unités",
  "unitSystem": "Système d'unités",
  "Unknown": "Inconnu",
  "unnamed field": "Champ sans nom",
  "unsaved changes will be lost": "les modifications non enregistrées seront perdues",
  "update sample": "mettre à jour l'échantillon",
  "updated": "Actualisé",
  "Use this buttons to select which layer to compare.": "Utilisez ces boutons pour sélectionner quelle couche comparer.",
  "Use this tool to compare two flights temporally. To start, drag one of the flights into the one you want to compare it with.": "Utilisez cet outil pour comparer deux vols dans le temps. Pour commencer, faites glisser l'un des vols vers celui que vous souhaitez comparer.",
  "Use this tool to freely move the created polygons.": "Utilisez cet outil pour déplacer librement les polygones créés.",
  "used": "utilisé",
  "Username": "Nom d'utilisateur",
  "users": "Utilisateurs",
  "Value": "Valeur",
  "variabilidad multiparcela": "Variabilité multi-parcelles",
  "variabilidad parcela (vra)": "Zone à Taux Variable (VRA)",
  "variability": "Variabilité",
  "Variedad": "Variété",
  "vegetative growth": "Croissance végétative",
  "vegetative growth per day": "Croissance végétative par jour",
  "vegetative growth variability": "Variabilité de la croissance végétative",
  "Ver detalles": "Voir les détails",
  "very high": "Très élevé",
  "very high level": "Niveau très élevé",
  "very low": "Très faible",
  "very low level": "Niveau très faible",
  "view": "vue",
  "vigor": "VIGUEUR",
  "vigor a nivel árbol": "vigueur / plante",
  "vigor acumulado dos meses (ndvi)": "Vigueur accumulée deux mois (NDVI)",
  "vigor acumulado un año (ndvi)": "Vigueur accumulée un an (NDVI)",
  "vigor vegetativo": "Vigueur végétative",
  "vigor vegetativo - pcd": "PCD - Vigueur Végétative",
  "vigor vegetativo (ndvi)": "Vigueur végétative (NDVI)",
  "vigor vegetativo (ndvi) - fases tempranas": "Vigueur Végétative (NDVI) - Étapes Précoces",
  "vigor zonificado": "Zonage de la vigueur",
  "VINHÃO": "VINHÃO",
  "Visibility": "Visibilité",
  "Vulnerable area": "Zone vulnérable",
  "wait": "attendre",
  "warning": "Avertissement",
  "warning map": "Carte d'avertissement",
  "Water level": "Niveau d'eau",
  "We are processing the data for your flight. Results will be available soon.": "Nous traitons les données de votre vol. Les résultats seront bientôt disponibles.",
  "weeds area": "Zone de mauvaises herbes",
  "week": "Semaine",
  "weight_1": "Poids 1",
  "weight_2": "Poids 2",
  "weight_3": "Poids 3",
  "weight_4": "Poids 4",
  "weight_5": "Poids 5",
  "With this tool you can draw circles. Click to place the center, and click again to choose the radius.": "Avec cet outil, vous pouvez dessiner des cercles. Cliquez pour placer le centre et cliquez à nouveau pour choisir le rayon.",
  "With this tool, you can draw a polygon with 3 or more points to measure its area.": "Avec cet outil, vous pouvez dessiner un polygone avec 3 points ou plus pour mesurer sa superficie",
  "without warning": "Sans avertissement",
  "Wrong credentials": "Identifiants incorrects",
  "year": "Année",
  "years": "Années",
  "You can change here the information of the new field.": "Vous pouvez changer ici les informations du nouveau champ",
  "You can move this bar to compare the layers temorally.": "Vous pouvez déplacer cette barre pour comparer les couches temporellement.",
  "You can only compare dates from the same sources": "Vous pouvez uniquement comparer des dates provenant des mêmes sources",
  "You can use this button at any moment to undo your last actions.": "Vous pouvez utiliser ce bouton à tout moment pour annuler vos dernières actions.",
  "You can use this slider to change the opacity of the selected layer.": "Vous pouvez utiliser ce curseur pour changer l'opacité de la couche sélectionnée.",
  "You cannot create field because you don't have any customers. Please ask us for adding ones": "Vous ne pouvez pas créer de champ car vous n'avez aucun client. Veuillez nous demander d'en ajouter.",
  "You must enter a decimal": "Vous devez entrer un décimal",
  "You must enter an integer": "Vous devez entrer un entier",
  "You must select an irrigation type": "Vous devez sélectionner un type d'irrigation",
  "You must type a field name": "Vous devez taper un nom de champ",
  "You must type a text": "Vous devez taper un texte",
  "Your field has been successfully deleted.": "Votre champ a été supprimé avec succès",
  "Your LAYERS version has expired. Click to update.": "Votre version de LAYERS a expiré. Cliquez pour mettre à jour."
}
