// prettier-ignore
export default {
  "% Fallas": "% Неудач",
  "% Mala Hierba": "% Сорняков",
  "ACELGA": "МАНГОЛЬД",
  "ACELGA VERDE": "ЗЕЛЕНЫЙ МАНГОЛЬД",
  "action": "действие",
  "ag_accesses_the_viewer": "k. AG доступ к просмотрщику?",
  "AGUACATE": "АВОКАДО",
  "AJO": "ЧЕСНОК",
  "ALBARICOQUE": "АБРИКОС",
  "ALBARINO": "АЛЬБАРИНО",
  "ALFALFA": "ЛЮЦЕРНА",
  "ALGODON": "ХЛОПОК",
  "aluminum": "Алюминий",
  "aluminum_saturation": "Насыщение алюминием",
  "annotation": "аннотация",
  "aphid_controller": "тля - контролер",
  "aphid_infested": "тля - заражение",
  "aphid_infested_leafs": "тля - зараженные листья",
  "aphid_mite_total_leafs": "тля и клещ - всего листья",
  "APIO": "СЕЛЬДЕРЕЙ",
  "apparent_density": "Очевидная плотность",
  "ARANDANOS": "ЧЕРНИКА",
  "ARBOSANA": "АРБОСАНА",
  "ARROZ": "РИС",
  "assesor_name": "Имя оценщика",
  "atr": "Атр",
  "AVENA": "ОВЕС",
  "BARBECHO": "ПАР",
  "BATAVIA": "БАТАВИЯ",
  "beans_10_plants": "Растения 10 бобов",
  "billaea_healthy_pupe": "billaea - здоровые куколки",
  "billaea_larva": "billaea - личинки",
  "billaea_parasitized_larva": "billaea - паразитированные личинки",
  "billaea_residual_pupe": "billaea - остаточные куколки",
  "bipolaris_sacchari_incidence": "bipolaris sacchari \"глазное пятно\" - частота",
  "bipolaris_sacchari_severity": "bipolaris sacchari \"глазное пятно\" - тяжесть",
  "bipolaris_sacchari_total_infected_leafs": "bipolaris sacchari \"глазное пятно\" - всего зараженных листьев",
  "BLANCO": "БЕЛЫЙ",
  "blastobasis_healthy_chrysalis": "blastobasis - здоровые куколки",
  "blastobasis_larva_number": "blastobasis - личинки",
  "blastobasis_residual_chrysalis": "blastobasis - остаточные куколки",
  "boron": "Бор",
  "bouquet10roots_2_8cm": "02.Букет 10 корней (2-8см)",
  "bouquet10roots_2cm": "01.Букет 10 корней (0-2см)",
  "bouquet10roots_8cm": "03.Букет 10 корней (>8см)",
  "brix": "Брикс",
  "BROCOLI": "БРОККОЛИ",
  "bud_blastobasis_drilled": "диатрея blastobasis бутоны перфорированные",
  "bud_dehydrated": "обезвоженные бутоны",
  "bud_diatrea_drilled": "перфорированные бутоны диатреи",
  "bud_mechanical_damage": "бутоны механические повреждения",
  "bud_sprout": "бутоны - побеги",
  "bud_total": "всего бутонов",
  "bud_turgid": "тугие бутоны",
  "CABERNET SAUVIGNON": "КАБЕРНЕ СОВИНЬОН",
  "CALABACIN": "КАБАЧОК",
  "calcium": "Кальций",
  "calcium_saturation": "Насыщение кальцием",
  "CAÑA DE AZUCAR": "САХАРНЫЙ ТРОСТНИК",
  "CAQUI": "ХАКИ",
  "casida_afa_perc": "Кассида - AFA(%)",
  "casida_n_of_adults_in_50_plants": "Cásida - Количество взрослых особей в 50 растениях",
  "casida_n_of_eggs_in_50_plants": "Cásida - Количество яиц в 50 растениях",
  "casida_n_of_large_larvae_in_50_plants": "Cásida - Количество крупных личинок в 50 растениях",
  "casida_n_of_small_larvae_in_50_plants": "Cásida - Количество мелких личинок в 50 растениях",
  "CAUCHO": "КАУЧУК",
  "CEBADA": "ЯЧМЕНЬ",
  "CEBADA HIBRIDA": "ГИБРИДНЫЙ ЯЧМЕНЬ",
  "CEBADA MALTERA": "СОЛОДОВЫЙ ЯЧМЕНЬ",
  "CEBOLLA": "ЛУК",
  "cercospora_20_100_spots_leaf": "Церкоспора -F- 20-100 пятен/лист",
  "cercospora_50_afa": "Церкоспора -L- 50% AFA",
  "cercospora_50_perc_leaves_ext_with_80_100_severity": "Церкоспора -K- 50% листьев ext с 80-100% тяжестью",
  "cercospora_75_afa": "Церкоспора -M- 75%AFA",
  "cercospora_90_100_afa": "Церкоспора -N- 90-100%AFA",
  "cercospora_healthy_plant": "Церкоспора -A- Здоровое растение",
  "cercospora_isolated_spots_on_some_leaves_10_15_sl_100_leaves": "Церкоспора -D- Изолированные пятна на некоторых листьях (10-50%SL/100 листьев).",
  "cercospora_isolated_spots_on_some_leaves_5_10_sl_100_leaves": "Церкоспора -C- Изолированные пятна на некоторых листьях (5-10%SL/100 листьев)",
  "cercospora_isolated_spots_on_some_leaves_minus_5_sl_100_leaves": "Церкоспора -B- Изолированные пятна на некоторых листьях (<5%SL/100 листьев)",
  "cercospora_leaf_necrosis_ext": "Церкоспора -H- Некроз листьев EXT.",
  "cercospora_leaves_ext_almost_total_necrosis": "Церкоспора -J- Листья EXT: почти полный некроз",
  "cercospora_leaves_with_major_100_sports": "Церкоспора -G- Листья с более чем 100 пятнами",
  "cercospora_minus_20_spots_leaf": "Церкоспора -E- <20 пятен/лист",
  "cercospora_necrosis_on_most_leaves_int": "Церкоспора -I- Некроз на большинстве листьев INT.",
  "cercospora_treatment": "Церкоспора -o- Лечение",
  "CEREZA": "ВИШНЯ",
  "CESPED": "ТРАВА",
  "CHARDONNAY": "ШАРДОНЕ",
  "chromium": "Хром",
  "cic": "ЦИК",
  "CIRUELA": "СЛИВА",
  "clay": "Глина",
  "CLEMENTINA": "КЛЕМЕНТИН",
  "close": "закрыть",
  "COLZA": "РАПС",
  "Conteo": "подсчет",
  "copper": "Медь",
  "cotesia_cocon": "cotesia - коконы",
  "cotesia_parasitized_larva": "cotesia - паразитированные личинки",
  "cotyledon": "семядоля",
  "current_ndre_status": "a. Текущий статус NDRE",
  "date_of_first_informative_email_sent": "j. Дата отправки первого информационного письма",
  "date_of_hemav_ndre_layer_screenshot": "h. Дата снимка слоя Hemav NDRE",
  "defoliator_leaf_evaluated": "дефолиатор - оцененные листья",
  "defoliator_marasmia_sp_larva_alive": "дефолиатор - marasmia sp. - живые личинки",
  "defoliator_marasmia_sp_larva_death": "дефолиатор - marasmia sp. - мертвые личинки",
  "defoliator_marasmia_sp_larva_per_stem": "дефолиатор - marasmia sp. - личинки на стебель",
  "defoliator_mythimna_sp_larva_alive": "дефолиатор - mythimna sp. - живые личинки",
  "defoliator_mythimna_sp_larva_death": "дефолиатор - mythimna sp. - мертвые личинки",
  "defoliator_mythimna_sp_larva_per_stem": "дефолиатор - mythimna sp. - личинки на стебель",
  "defoliator_spodoptera_sp_larva_alive": "дефолиатор - spodoptera sp. - живые личинки",
  "defoliator_spodoptera_sp_larva_death": "дефолиатор - spodoptera sp. - мертвые личинки",
  "defoliator_spodoptera_sp_larva_per_stem": "дефолиатор - spodoptera sp. - личинки на стебель",
  "defoliator_stem_total": "дефолиатор - всего стеблей",
  "depth": "Глубина",
  "desconocido": "Неизвестно",
  "DESCONOCIDO": "НЕИЗВЕСТНО",
  "diatraea_saccharalis_larva_big": "diatraea sachharalis - крупные личинки",
  "diatraea_saccharalis_larva_healthy_chrysalis": "diatraea sachharalis - здоровые куколки",
  "diatraea_saccharalis_larva_medium": "diatraea sachharalis - средние личинки",
  "diatraea_saccharalis_larva_residual_chrysalis": "diatraea sachharalis - остаточные куколки",
  "diatraea_saccharalis_larva_small": "diatraea sachharalis - мелкие личинки",
  "diatraea_saccharalis_larva_total": "diatraea sachharalis - всего личинок",
  "disease": "Болезнь",
  "Disease": "Болезнь",
  "disease_level": "08. Уровень болезни",
  "distance_between_rows": "03. Расстояние между рядами",
  "does_it_follow_the_recommendation": "f. Следует ли это рекомендациям?",
  "Drip": "Капельное",
  "dry_weight": "Сухой вес",
  "effective_cation_exchange_capacity": "Эффективная емкость катионного обмена (ECEC)",
  "ESCAROLA": "ЭНДИВИЙ",
  "ESCAROLA LISA": "ГЛАДКИЙ ЭНДИВИЙ",
  "ESCAROLA RIZADA": "КУДРЯВЫЙ ЭНДИВИЙ",
  "ESPARRAGO": "СПАРЖА",
  "ESPINACA": "ШПИНАТ",
  "estrés hídrico": "Водный стресс",
  "Estrés hídrico (LWS)": "Водный стресс (LWS)",
  "evaluator": "10. Имя оценщика",
  "Ex": "Пример",
  "exchangeable_acidity": "Обменная кислотность",
  "fallas": "Разрывы",
  "fire": "Пожар",
  "flowering": "Цветение",
  "forecast_yield_sampling_1": "ПРОГНОЗ УРОЖАЙНОСТИ ОБРАЗЕЦ 1",
  "forecast_yield_sampling_2": "ПРОГНОЗ УРОЖАЙНОСТИ ОБРАЗЕЦ 2",
  "forecast_yield_sampling_3": "ПРОГНОЗ УРОЖАЙНОСТИ ОБРАЗЕЦ 3",
  "forecast_yield_sampling_4": "ПРОГНОЗ УРОЖАЙНОСТИ ОБРАЗЕЦ 4",
  "fresh_weight": "Свежий вес",
  "FRIJOL": "ФАСОЛЬ",
  "fruits_count": "Подсчет фруктов",
  "FRUTAL": "ФРУКТЫ",
  "Generic": "Общий",
  "GEWURZTRAMINNER": "ГЕВЮРЦТРАМИНЕР",
  "GIRASOL": "ПОДСОЛНЕЧНИК",
  "gm_avg_ndvi": "Вегетативная сила (NDVI-Дрон)",
  "gm_luvi_ndvi_mean": "Вегетативная сила (NDVI-LUVI)",
  "gm_ndmi_mean": "Вегетативная влажность (NDMI)",
  "gm_ndre_mean": "Азот-Хлорофилл (NDRE)",
  "gm_perc_overseeding": "% Пропусков",
  "gm_perc_weeds": "% Сорняков",
  "GODELLO": "ГОДЕЛЛО",
  "GOLDEN DELICIOUS": "ГОЛДЕН ДЕЛИШЕС",
  "GOLDEN REINDERS": "ГОЛДЕН РЕЙНДЕРС",
  "Grams": "Граммы",
  "GRANADA": "ГРАНАТ",
  "green_dots": "зеленые точки",
  "GRENACHE BLANC": "ГРЕНАШ БЛАН",
  "GUISANTE": "ГОРОХ",
  "HABA": "БАБ",
  "hanger_installation": "Установка насеста",
  "height": "Высота",
  "HIBRIDO": "ГИБРИД",
  "high irrigation": "Высокий водный стресс",
  "high_n_from_irrigation_water": "d. Высокий N из поливной воды",
  "high_n_from_nitrogen_input": "e. Высокий N из азотных удобрений",
  "high_n_from_previous_years": "c. Высокий N из предыдущих лет",
  "higher level": "Более высокий уровень",
  "HIGO": "ИНЖИР",
  "Humedad Vegetativa (NDMI) ": "Вегетативная влажность (NDMI) ",
  "humidity": "Влажность",
  "humidity_100_beans": "Влажность в 100 бобах",
  "Imperial": "Империал",
  "inspection": "инспекция",
  "internodes_blastobasis_damage_longitude": "длина повреждений blastobasis междоузлий",
  "internodes_blastobasis_drilled": "перфорированные blastobasis междоузлия",
  "internodes_diatraea_damage_longitude": "длина повреждений diatrea междоузлий",
  "internodes_diatraea_drilled": "перфорированные diatrea междоузлия",
  "internodes_evaluated": "всего междоузлий",
  "internodes_healthy": "здоровые междоузлия",
  "internodes_mechanical_damage": "механические повреждения междоузлий",
  "internodes_physiological_damage": "физиологические повреждения междоузлий",
  "iron": "Железо",
  "irrigation": "ОРОШЕНИЕ",
  "Irrigation": "Орошение",
  "irrigation_dosage": "Доза орошения",
  "irrigation_uniformity": "Однородность орошения",
  "KUMQUATS": "КУМКВАТЫ",
  "language": "Язык системы",
  "larva_number_big": "личиночная стадия - крупная личинка",
  "larva_number_medium": "личиночная стадия - средняя личинка",
  "larva_number_small": "личиночная стадия - мелкая личинка",
  "last_fertilizer_app_date": "b. Дата последнего применения удобрения",
  "last_irrigation": "Последнее орошение",
  "lead": "Свинец",
  "Leaf": "Лист",
  "leaf_moisture_status": "Статус влажности листьев",
  "LECHUGA": "САЛАТ",
  "LECHUGA ICEBERG": "САЛАТ АЙСБЕРГ",
  "leptosphaeria_sacchari_incidence": "leptosphaeria sacchari \"кольцевая пятнистость\" - частота",
  "leptosphaeria_sacchari_severity": "leptosphaeria sacchari \"кольцевая пятнистость\" - тяжесть",
  "leptosphaeria_sacchari_total_infected_leafs": "leptosphaeria sacchari \"кольцевая пятнистость\" - всего зараженных листьев",
  "LIMON": "ЛИМОН",
  "linear_meters": "02. Линейные метры",
  "loam": "Суглинок",
  "loss_of_vigor": "Потеря жизненной силы",
  "low irrigation": "Низкий водный стресс",
  "lower level": "Нижний уровень",
  "lowered_unload": "03. Понижен при выгрузке",
  "MACABEU": "МАКАБЕО",
  "MACABEU VIURA": "МАКАБЕО ВИУРА",
  "magnesium": "Магний",
  "magnesium_saturation": "Насыщение магнием",
  "MAIZ": "КУКУРУЗА",
  "maleza (ciclo<60 dias)": "Сорняки (Цикл<60 дней)",
  "MALVASIA AROMATICA": "АРОМАТИЧЕСКАЯ МАЛЬВАЗИЯ",
  "MALVASIA DE SITGES": "МАЛЬВАЗИЯ ДЕ СИТЖЕС",
  "MALVASIA GROSSA": "МАЛЬВАЗИЯ ГРОССА",
  "MANDARINA": "МАНДАРИН",
  "manganese": "Марганец",
  "MANZANA": "ЯБЛОКО",
  "mean level": "Средний уровень",
  "medium irrigation": "Средний водный стресс",
  "medium_moisture_saturation": "Средняя насыщенность влагой",
  "MELOCOTON": "ПЕРСИК",
  "MELON": "ДЫНЯ",
  "mercury": "Ртуть",
  "MERLOT": "МЕРЛО",
  "Metric": "Метрическая система",
  "microorganisms_larva": "личинки микроорганизмов",
  "mite_grade": "средний уровень",
  "mite_infested": "клещевое заражение",
  "mite_infested_leafs": "клещ - зараженные листья",
  "moisture_status": "07. Статус влажности",
  "molybdenum": "Молибден",
  "MONTONEC": "МОНТОНЕК",
  "MONTONEGA": "МОНТОНЕГА",
  "MOSCATELL DE ALEXANDRIA": "МУСКАТ АЛЕКСАНДРИЙСКИЙ",
  "MOSCATELL ROMA": "МУСКАТ РИМСКИЙ",
  "NARANJA": "АПЕЛЬСИН",
  "NDRE": "NDRE",
  "NDVI": "NDVI",
  "NECATRINA": "НЕКАТРИНА",
  "NECTARINA": "НЕКТАРИН",
  "nickel": "Никель",
  "Nitrogen": "Азот",
  "Nitrógeno Foliar": "Фолярный азот",
  "nitrógeno-clorofila (ndre)": "Азот-Хлорофилл (NDRE)",
  "Nitrógeno-Clorofila (NDRE)": "Азот-Хлорофилл (NDRE)",
  "No crop or too dry": "Нет урожая или слишком сухо",
  "NO CULTIVO": "БЕЗ УРОЖАЯ",
  "No hay datos": "Нет данных",
  "NOGAL": "ГРЕЦКИЙ ОРЕХ",
  "notification_to_the_farmer_high_n_index": "g. Уведомление фермеру: Высокий индекс N",
  "Observations": "Наблюдения",
  "OLIVO": "ОЛИВА",
  "opogona_larva_number": "opogona - личинки",
  "organic_material": "Органический материал",
  "organic_oxidizable_carbon": "Окисляемый органический углерод",
  "PANSA BLANCA": "БЕЛАЯ ПАНСА",
  "PANSAL": "ПАНСАЛ",
  "PARAGUAYO": "ПАРАГВАЙСКИЙ ПЕРСИК",
  "PARELLADA": "ПАРЕЛЛАДА",
  "PATATA": "КАРТОФЕЛЬ",
  "pattern": "шаблон",
  "PERA": "ГРУША",
  "percent_disease": "Процент болезни",
  "perkinsiella_345_instar_nymph": "perkinsiella - нимфы 3-4-5 возраста",
  "perkinsiella_adult": "perkinsiella - взрослые особи",
  "perkinsiella_controller_coccinelidos": "perkinsiella - контролеры - кокцинелиды",
  "perkinsiella_controller_crysopas": "perkinsiella - контролеры - хризопы",
  "perkinsiella_controller_spiders": "perkinsiella - контролеры - пауки",
  "perkinsiella_controller_tytthus_parviceps": "perkinsiella - контролеры - tytthus parviceps",
  "perkinsiella_controller_zelus": "perkinsiella - контролеры - zelus",
  "perkinsiella_death_adult": "perkinsiella - мертвые взрослые особи",
  "perkinsiella_death_nymph": "perkinsiella - мертвые нимфы",
  "petiole_sampling": "i. Отбор черешков",
  "ph": "Ph",
  "pheromone_trap_diatraea_adults_number": "феромонная ловушка - взрослые особи diatraea",
  "pheromone_trap_installation_date": "феромонная ловушка - дата установки",
  "pheromone_trap_number": "феромонная ловушка - количество ловушек",
  "pheromone_trap_other_butterfly": "феромонная ловушка - другие бабочки",
  "pheromone_trap_pheromone_change": "феромонная ловушка - смена феромона",
  "phosphorus": "Фосфор",
  "PIMIENTO": "ПЕРЕЦ",
  "PINO": "СОСНА",
  "PINOT NOIR": "ПИНО НУАР",
  "PISTACHO": "ФИСТАШКА",
  "Pivot": "Поворотный",
  "Plague": "Чума",
  "plague_level": "09. Уровень чумы",
  "Plant": "Растение",
  "Plant Density": "Плотность растений",
  "plantation_date": "Дата посадки",
  "plants_per_surface_unit": "Растений на единицу площади",
  "poda": "Обрезка",
  "pol": "Пол",
  "POMELO": "ГРЕЙПФРУТ",
  "POMELOS": "ГРЕЙПФРУТЫ",
  "potassium": "Калий",
  "potassium_saturation": "Насыщение калием",
  "powdery_mildew_afa_perc": "Мучнистая роса - AFA(%)",
  "powdery_mildew_perc_of_affected_plants": "Мучнистая роса - % пораженных растений (ЧАСТОТА)",
  "powdery_mildew_treatment": "Мучнистая роса - Лечение",
  "Production": "Производство",
  "pseudomonas_rubrilineans_stem_number": "красная полоска - всего стеблей",
  "pseudomonas_rubrilineans_stem_number_affected": "красная полоска - пораженные стебли",
  "pseudomonas_rubrilineans_stem_number_with_symptoms": "красная полоска - стебли с симптомами",
  "puccinia_melanocephala_accumulated_scale": "ржавчина - накопленная шкала",
  "puccinia_melanocephala_infestation": "ржавчина - заражение",
  "puccinia_melanocephala_infested_leafs": "ржавчина - зараженные листья",
  "puccinia_melanocephala_points": "ржавчина - точки",
  "puccinia_melanocephala_total_leafs": "ржавчина - всего листьев",
  "puccinia_sp_incidence": "puccinia sp \"roya\" - частота",
  "puccinia_sp_severity": "puccinia sp \"roya\" - тяжесть",
  "puccinia_sp_total_infected_leafs": "puccinia sp \"roya\" - всего зараженных листьев",
  "PUERRO": "ПОРЕЙ",
  "Quality": "Качество",
  "QUINOA": "КИНОА",
  "RAIGRAS": "РАЙГРАС",
  "real_leaves": "реальные листья",
  "reform": "Реформа",
  "REMOLACHA": "САХАРНАЯ СВЕКЛА",
  "REMOLACHA AZUCARERA": "САХАРНАЯ СВЕКЛА",
  "resowing_surface": "Площадь пересева",
  "result": "результат",
  "riego": "Орошение",
  "rodents_infestation_percent": "Грызуны - заражение",
  "rot10roots_complete": "03. Гниение 10 корней - Полное",
  "rot10roots_nothing": "01. Гниение 10 корней - Ничего",
  "rot10roots_partial": "02. Гниение 10 корней - Частичное",
  "rust_10_25_afa": "Ржавчина - 10-25 % AFA",
  "rust_100_afa": "Ржавчина -N- 100 % AFA. Оригинальная листва полностью уничтожена",
  "rust_25_35_afa": "Ржавчина -J- 25-35 % AFA. 1/4 -1/3 листвы сильно поражено",
  "rust_3_10_afa": "Ржавчина -I- 3-10 % AFA",
  "rust_35_50_afa": "Ржавчина -K- 35-50 % AFA.  1/3 - 1/2 листвы сильно поражено",
  "rust_50_75_afa": "Ржавчина -L- 50-75 % AFA. 1/2 листвы сильно поражено",
  "rust_75_90_afa": "Ржавчина -M- 75-90 % AFA. Вся листва сильно поражена",
  "rust_healthy_plant": "Ржавчина -A- Здоровое растение",
  "rust_isolated_spots_on_some_leaves_1_9_perc": "Ржавчина -B- Изолированные пятна на некоторых листьях. 1-9 (%SL)",
  "rust_isolated_spots_on_some_leaves_10_19_perc": "Ржавчина -C- Изолированные пятна на некоторых листьях. 10-19 (%SL)",
  "rust_isolated_spots_on_some_leaves_20_29_perc": "Ржавчина -D- Изолированные пятна на некоторых листьях. 20-29 (%SL)",
  "rust_isolated_spots_on_some_leaves_30_50_perc": "Ржавчина -E- Изолированные пятна на некоторых листьях. 30-50 (%SL)",
  "rust_regrowth_restart": "Ржавчина -O- Начало отрастания. Добавьте 0.5 балла за каждую неделю до урожая",
  "rust_spots_leaves_100_300": "Ржавчина -H- Листья с 100-300 пятнами",
  "rust_spots_leaves_20_100": "Ржавчина -G- 20-100 пятен на листе на большинстве листьев",
  "rust_spots_leaves_50": "Ржавчина -F- >50% SL и <20 пятен на листе на большинстве листьев",
  "rust_treatment": "Ржавчина -p- Лечение",
  "sac": "Сах",
  "Safety": "Безопасность",
  "sample_color": "05. Цвет образца",
  "sand": "Песок",
  "SAUVIGNON BLANC": "СОВИНЬОН БЛАН",
  "severity": "Тяжесть",
  "soca_width": "04. Количество корней",
  "sodium": "Натрий",
  "sodium_saturation": "Насыщение натрием",
  "Soil": "Почва",
  "soil_moisture_status": "Статус влажности почвы",
  "SOJA": "СОЯ",
  "sowing_failure": "Неудачный посев",
  "sprout_dead": "мертвый побег",
  "sprout_total": "всего побегов",
  "starch": "Крахмал",
  "stems_evaluated": "оцененные стебли",
  "sulfur": "Сера",
  "Superficial": "Поверхностный",
  "SYRAH": "СИРА",
  "TABACO": "ТАБАК",
  "TE": "ЧАЙ",
  "TEA": "ФАКЕЛ",
  "TEMPRANILLO": "ТЕМПРАНИЛЬО",
  "TOMATE": "ПОМИДОР",
  "tombing": "Полегание растений",
  "total_arsenic": "Общий мышьяк",
  "total_cadmium": "Общий кадмий",
  "TOURIGA NACIONAL": "ТУРИГА НАСЬОНАЛ",
  "TRIGO": "ПШЕНИЦА",
  "TRIGO BLANDO": "МЯГКАЯ ПШЕНИЦА",
  "TRIGO DURO": "TVЁРДАЯ ПШЕНИЦА",
  "TRITICALE": "ТРИТИКАЛЕ",
  "type_disease": "Тип болезни",
  "uneven_budding": "Неравномерное прорастание",
  "ustilago_scitaminea_infection": "ustilago scitaminea \"уголь\" - заражение",
  "ustilago_scitaminea_stem_affected": "ustilago scitaminea \"уголь\" - всего пораженных стеблей",
  "ustilago_scitaminea_stem_symptoms": "ustilago scitaminea \"уголь\" - стебли с симптомами",
  "ustilago_scitaminea_stem_total": "ustilago scitaminea \"уголь\" - всего стеблей",
  "ustilago_scitaminea_stem_whip": "ustilago scitaminea \"уголь\" - стебли с хлыстами",
  "UVA": "ВИНОГРАД",
  "UVA DE MESA": "СТОЛОВЫЙ ВИНОГРАД",
  "Variabilidad": "Изменчивость",
  "Variabilidad (NDVI-LUVI)": "Изменчивость (NDVI-LUVI)",
  "VERDEJO": "ВЕРДЕХО",
  "very high irrigation": "Очень высокий водный стресс",
  "very low irrigation": "Очень низкий водный стресс",
  "VIGNE": "ВИНОГРАДНИК",
  "Vigor Vegetativo (NDVI-Drone)": "Вегетативная сила (NDVI-Дрон)",
  "Vigor Vegetativo (NDVI-LUVI)": "Вегетативная сила (NDVI-LUVI)",
  "vigor vegetativo (ndvi) - vigor alto": "Вегетативная сила (NDVI) - Высокий уровень",
  "VIÑA": "ВИНОГРАДНИК",
  "VIOGNIER": "ВИОНЬЕ",
  "virgin_female_georeferenced_trap_diatraea_adult_total": "ловушка девственных самок - всего взрослых diatraea",
  "virgin_female_georeferenced_trap_female_change": "ловушка девственных самок - смена самок",
  "virgin_female_georeferenced_trap_other_butterfly": "ловушка девственных самок - другие бабочки",
  "virgin_female_georeferenced_trap_point": "ловушка девственных самок - точка ловушки",
  "virgin_female_trap_diatraea_adult_number": "ловушка девственных самок - взрослые diatraea",
  "virgin_female_trap_female_change": "ловушка девственных самок - смена самок",
  "virgin_female_trap_installation_date": "ловушка девственных самок - дата установки",
  "virgin_female_trap_number": "ловушка девственных самок - ловушки",
  "virgin_female_trap_other_butterfly": "ловушка девственных самок - другие бабочки",
  "VIURA": "ВИУРА",
  "weed_unload": "01. Сорняки (разгрузка)",
  "Weeds": "Сорняки",
  "weight_100_beans": "Растения из 100 бобов",
  "weight_kg": "06. Вес образца",
  "whey": "Сыворотка",
  "without_footwear": "01. Без обуви",
  "without_gloves": "04. Без перчаток",
  "without_vest": "02. Без жилета",
  "XARELLO": "ШАРЕЛЛО",
  "yellowness_mild": "Пожелтение - Легкая атака (редкие растения)",
  "yellowness_moderate": "Пожелтение - Умеренная атака (кусты)",
  "yellowness_strong": "Пожелтение - Сильная атака (участок полностью поражен)",
  "yellowness_without_symptoms": "Пожелтение - Без симптомов",
  "zinc": "Цинк"
}
