// prettier-ignore
export default {
  "% Fallas": "% Ausfälle",
  "% Mala Hierba": "% Unkraut",
  "ACELGA": "Mangold",
  "ACELGA VERDE": "Grüner Mangold",
  "action": "Aktion",
  "ag_accesses_the_viewer": "k. AG greift auf den Viewer zu?",
  "AGUACATE": "Avocado",
  "AJO": "Knoblauch",
  "ALBARICOQUE": "Aprikose",
  "ALBARINO": "Albarino",
  "ALFALFA": "Luzerne",
  "ALGODON": "Baumwolle",
  "aluminum": "Aluminium",
  "aluminum_saturation": "Aluminiumsättigung",
  "annotation": "Anmerkung",
  "aphid_controller": "Blattlaus - Bekämpfung",
  "aphid_infested": "Blattlaus - Befall",
  "aphid_infested_leafs": "Blattlaus - befallene Blätter",
  "aphid_mite_total_leafs": "Blattläuse und Milben - Gesamtzahl der Blätter",
  "APIO": "Sellerie",
  "apparent_density": "Scheinbare Dichte",
  "ARANDANOS": "Heidelbeeren",
  "ARBOSANA": "Arbosana",
  "ARROZ": "Reis",
  "assesor_name": "Name des Gutachters",
  "atr": "ATR",
  "AVENA": "Hafer",
  "BARBECHO": "Brache",
  "BATAVIA": "Batavia",
  "beans_10_plants": "10 Bohnenpflanzen",
  "billaea_healthy_pupe": "billaea - gesunde Puppen",
  "billaea_larva": "billaea - Larven",
  "billaea_parasitized_larva": "billaea - parasitierte Larven",
  "billaea_residual_pupe": "billaea - restliche Puppen",
  "bipolaris_sacchari_incidence": "bipolaris sacchari \"Augenfleck\" - Inzidenz",
  "bipolaris_sacchari_severity": "bipolaris sacchari \"Augenfleck\" - Schweregrad",
  "bipolaris_sacchari_total_infected_leafs": "bipolaris sacchari \"Augenfleck\" - Gesamtzahl befallener Blätter",
  "BLANCO": "Weiß",
  "blastobasis_healthy_chrysalis": "blastobasis - gesunde Puppen",
  "blastobasis_larva_number": "blastobasis - Larven",
  "blastobasis_residual_chrysalis": "blastobasis - restliche Puppen",
  "boron": "Bor",
  "bouquet10roots_2_8cm": "02. Bouquet 10 Wurzeln (2-8cm)",
  "bouquet10roots_2cm": "01. Bouquet 10 Wurzeln (0-2cm)",
  "bouquet10roots_8cm": "03. Bouquet 10 Wurzeln (>8cm)",
  "brix": "Brix",
  "BROCOLI": "Brokkoli",
  "bud_blastobasis_drilled": "diatraea blastobasis - durchbohrte Knospen",
  "bud_dehydrated": "Dehydrierte Knospen",
  "bud_diatrea_drilled": "durchbohrte diatraea Knospen",
  "bud_mechanical_damage": "mechanisch beschädigte Knospen",
  "bud_sprout": "Knospen - Lalás",
  "bud_total": "Gesamtzahl der Knospen",
  "bud_turgid": "Perkige Knospen",
  "CABERNET SAUVIGNON": "Cabernet Sauvignon",
  "CALABACIN": "Zucchini",
  "calcium": "Kalzium",
  "calcium_saturation": "Kalziumsättigung",
  "CAÑA DE AZUCAR": "Zuckerrohr",
  "CAQUI": "Khaki",
  "casida_afa_perc": "Cásida - AFA(%)",
  "casida_n_of_adults_in_50_plants": "Cásida - Anzahl Erwachsener in 50 Pflanzen",
  "casida_n_of_eggs_in_50_plants": "Cásida - Anzahl Eier in 50 Pflanzen",
  "casida_n_of_large_larvae_in_50_plants": "Cásida - Anzahl großer Larven in 50 Pflanzen",
  "casida_n_of_small_larvae_in_50_plants": "Cásida - Anzahl kleiner Larven in 50 Pflanzen",
  "CAUCHO": "Kautschuk",
  "CEBADA": "Gerste",
  "CEBADA HIBRIDA": "Hybride Gerste",
  "CEBADA MALTERA": "Malzgerste",
  "CEBOLLA": "Zwiebel",
  "cercospora_20_100_spots_leaf": "Cercospora -F- 20-100 Flecken/Blatt",
  "cercospora_50_afa": "Cercospora -L- 50% AFA",
  "cercospora_50_perc_leaves_ext_with_80_100_severity": "Cercospora -K- 50% Blätter ext mit 80-100% Schweregrad",
  "cercospora_75_afa": "Cercospora -M- 75% AFA",
  "cercospora_90_100_afa": "Cercospora -N- 90-100% AFA",
  "cercospora_healthy_plant": "Cercospora -A- Gesunde Pflanze",
  "cercospora_isolated_spots_on_some_leaves_10_15_sl_100_leaves": "Cercospora -D- Einzelne Flecken auf einigen Blättern (10-15% SL/100 Blätter)",
  "cercospora_isolated_spots_on_some_leaves_5_10_sl_100_leaves": "Cercospora -C- Einzelne Flecken auf einigen Blättern (5-10% SL/100 Blätter)",
  "cercospora_isolated_spots_on_some_leaves_minus_5_sl_100_leaves": "Cercospora -B- Einzelne Flecken auf einigen Blättern (<5% SL/100 Blätter)",
  "cercospora_leaf_necrosis_ext": "Cercospora -H- Blattnekrose EXT.",
  "cercospora_leaves_ext_almost_total_necrosis": "Cercospora -J- Blätter EXT: fast vollständige Nekrose",
  "cercospora_leaves_with_major_100_sports": "Cercospora -G- Blätter mit > 100 Flecken",
  "cercospora_minus_20_spots_leaf": "Cercospora -E- <20 Flecken/Blatt",
  "cercospora_necrosis_on_most_leaves_int": "Cercospora -I- Nekrose auf den meisten Blättern INT.",
  "cercospora_treatment": "Cercospora -o- Behandlung",
  "CEREZA": "Kirsche",
  "CESPED": "Gras",
  "CHARDONNAY": "Chardonnay",
  "chromium": "Chrom",
  "cic": "KAK",
  "CIRUELA": "Pflaume",
  "clay": "Ton",
  "CLEMENTINA": "Clementine",
  "close": "Schließen",
  "COLZA": "Raps",
  "Conteo": "Zählung",
  "copper": "Kupfer",
  "cotesia_cocon": "Cotesia - Kokons",
  "cotesia_parasitized_larva": "Cotesia - parasitierte Larven",
  "cotyledon": "Keimblatt",
  "current_ndre_status": "a. Aktueller NDRE-Status",
  "date_of_first_informative_email_sent": "j. Datum des ersten informativen E-Mail-Versands",
  "date_of_hemav_ndre_layer_screenshot": "h. Datum des Hemav-NDRE-Layer-Screenshots",
  "defoliator_leaf_evaluated": "Entlauber - bewertete Blätter",
  "defoliator_marasmia_sp_larva_alive": "Entlauber - Marasmia sp. - lebende Larven",
  "defoliator_marasmia_sp_larva_death": "Entlauber - Marasmia sp. - tote Larven",
  "defoliator_marasmia_sp_larva_per_stem": "Entlauber - Marasmia sp. - Larven pro Stängel",
  "defoliator_mythimna_sp_larva_alive": "Entlauber - Mythimna sp. - lebende Larven",
  "defoliator_mythimna_sp_larva_death": "Entlauber - Mythimna sp. - tote Larven",
  "defoliator_mythimna_sp_larva_per_stem": "Entlauber - Mythimna sp. - Larven pro Stängel",
  "defoliator_spodoptera_sp_larva_alive": "Entlauber - Spodoptera sp. - lebende Larven",
  "defoliator_spodoptera_sp_larva_death": "Entlauber - Spodoptera sp. - tote Larven",
  "defoliator_spodoptera_sp_larva_per_stem": "Entlauber - Spodoptera sp. - Larven pro Stängel",
  "defoliator_stem_total": "Entlauber - Gesamte Stängel",
  "depth": "Tiefe",
  "desconocido": "Unbekannt",
  "DESCONOCIDO": "UNBEKANNT",
  "diatraea_saccharalis_larva_big": "Diatraea sachharalis - große Larven",
  "diatraea_saccharalis_larva_healthy_chrysalis": "Diatraea sachharalis - gesunde Puppen",
  "diatraea_saccharalis_larva_medium": "Diatraea sachharalis - mittlere Larven",
  "diatraea_saccharalis_larva_residual_chrysalis": "Diatraea sachharalis - verbleibende Puppen",
  "diatraea_saccharalis_larva_small": "Diatraea sachharalis - kleine Larven",
  "diatraea_saccharalis_larva_total": "Diatraea sachharalis - Gesamtlarven",
  "disease": "Krankheit",
  "Disease": "Krankheit",
  "disease_level": "08. Krankheitsstufe",
  "distance_between_rows": "03. Abstand zwischen den Reihen",
  "does_it_follow_the_recommendation": "f. Entspricht es der Empfehlung?",
  "Drip": "Tropfbewässerung",
  "dry_weight": "Trockengewicht",
  "effective_cation_exchange_capacity": "Effektive Kationenaustauschkapazität (ECEC)",
  "ESCAROLA": "Endivie",
  "ESCAROLA LISA": "Glattblättrige Endivie",
  "ESCAROLA RIZADA": "Krausblättrige Endivie",
  "ESPARRAGO": "Spargel",
  "ESPINACA": "Spinat",
  "estrés hídrico": "Wasserstress",
  "Estrés hídrico (LWS)": "Wasserstress (LWS)",
  "evaluator": "10. Name des Gutachters",
  "Ex": "Bsp.",
  "exchangeable_acidity": "Austauschbare Säure",
  "fallas": "Lücken",
  "fire": "Feuer",
  "flowering": "Blüte",
  "forecast_yield_sampling_1": "Ertragsschätzung Probe 1",
  "forecast_yield_sampling_2": "Ertragsschätzung Probe 2",
  "forecast_yield_sampling_3": "Ertragsschätzung Probe 3",
  "forecast_yield_sampling_4": "Ertragsschätzung Probe 4",
  "fresh_weight": "Frischgewicht",
  "FRIJOL": "Bohnen",
  "fruits_count": "Fruchtanzahl",
  "FRUTAL": "Obst",
  "Generic": "Generisch",
  "GEWURZTRAMINNER": "Gewürztraminer",
  "GIRASOL": "Sonnenblume",
  "gm_avg_ndvi": "Vegetatives Vigor (NDVI-Drohne)",
  "gm_luvi_ndvi_mean": "Vegetatives Vigor (NDVI-LUVI)",
  "gm_ndmi_mean": "Vegetative Feuchtigkeit (NDMI)",
  "gm_ndre_mean": "Stickstoff-Chlorophyll (NDRE)",
  "gm_perc_overseeding": "% Lücken",
  "gm_perc_weeds": "% Unkraut",
  "GODELLO": "Godello",
  "GOLDEN DELICIOUS": "Golden Delicious",
  "GOLDEN REINDERS": "Golden Reinders",
  "Grams": "Gramm",
  "GRANADA": "Granatapfel",
  "green_dots": "grüne Punkte",
  "GRENACHE BLANC": "Grenache Blanc",
  "GUISANTE": "Erbse",
  "HABA": "Bohne",
  "hanger_installation": "Installation einer Sitzstange",
  "height": "Höhe",
  "HIBRIDO": "Hybrid",
  "high irrigation": "Hoher Wasserstress",
  "high_n_from_irrigation_water": "d. Hoher N-Wert aus Bewässerungswasser",
  "high_n_from_nitrogen_input": "e. Hoher N-Wert durch Stickstoffeintrag",
  "high_n_from_previous_years": "c. Hoher N-Wert aus den Vorjahren",
  "higher level": "Höheres Niveau",
  "HIGO": "Feige",
  "Humedad Vegetativa (NDMI)": "Vegetative Feuchtigkeit (NDMI)",
  "humidity": "Feuchtigkeit",
  "humidity_100_beans": "Feuchtigkeit in 100 Bohnen",
  "Imperial": "Imperial",
  "inspection": "Inspektion",
  "internodes_blastobasis_damage_longitude": "Blastobasis-Sprosslänge beschädigt",
  "internodes_blastobasis_drilled": "durchbohrte Blastobasis-Sprosse",
  "internodes_diatraea_damage_longitude": "Diatraea-Sprosslänge beschädigt",
  "internodes_diatraea_drilled": "durchbohrte Diatraea-Sprosse",
  "internodes_evaluated": "bewertete Sprosse",
  "internodes_healthy": "gesunde Sprosse",
  "internodes_mechanical_damage": "mechanisch beschädigte Sprosse",
  "internodes_physiological_damage": "physiologisch beschädigte Sprosse",
  "iron": "Eisen",
  "irrigation": "BEWÄSSERUNG",
  "Irrigation": "Bewässerung",
  "irrigation_dosage": "Bewässerungsdosierung",
  "irrigation_uniformity": "Bewässerungsgleichmäßigkeit",
  "KUMQUATS": "Kumquats",
  "language": "Systemsprache",
  "larva_number_big": "Larvenstadium - große Larven",
  "larva_number_medium": "Larvenstadium - mittlere Larven",
  "larva_number_small": "Larvenstadium - kleine Larven",
  "last_fertilizer_app_date": "b. Letztes Düngedatum",
  "last_irrigation": "Letzte Bewässerung",
  "lead": "Blei",
  "Leaf": "Blatt",
  "leaf_moisture_status": "Blattfeuchtigkeitsstatus",
  "LECHUGA": "Salat",
  "LECHUGA ICEBERG": "Eisbergsalat",
  "leptosphaeria_sacchari_incidence": "Leptosphaeria sacchari \"Ringfleck\" - Inzidenz",
  "leptosphaeria_sacchari_severity": "Leptosphaeria sacchari \"Ringfleck\" - Schweregrad",
  "leptosphaeria_sacchari_total_infected_leafs": "Leptosphaeria sacchari \"Ringfleck\" - Gesamtzahl befallener Blätter",
  "LIMON": "Zitrone",
  "linear_meters": "02. Linearmeter",
  "loam": "Lehm",
  "loss_of_vigor": "Verlust der Vitalität",
  "low irrigation": "Niedriger Wasserstress",
  "lower level": "Niedrigeres Niveau",
  "lowered_unload": "03. Abgesenkt beim Entladen",
  "MACABEU": "Macabeu",
  "MACABEU VIURA": "Macabeu Viura",
  "magnesium": "Magnesium",
  "magnesium_saturation": "Magnesiumsättigung",
  "MAIZ": "Mais",
  "maleza (ciclo<60 dias)": "Unkraut (Zyklus<60 Tage)",
  "MALVASIA AROMATICA": "Aromatische Malvasia",
  "MALVASIA DE SITGES": "Malvasia de Sitges",
  "MALVASIA GROSSA": "Große Malvasia",
  "MANDARINA": "Mandarine",
  "manganese": "Mangan",
  "MANZANA": "Apfel",
  "mean level": "Mittleres Niveau",
  "medium irrigation": "Mittlerer Wasserstress",
  "medium_moisture_saturation": "Mittlere Feuchtigkeitssättigung",
  "MELOCOTON": "Pfirsich",
  "MELON": "Melone",
  "mercury": "Quecksilber",
  "MERLOT": "Merlot",
  "Metric": "Metrisch",
  "microorganisms_larva": "Mikroorganismen-Larven",
  "mite_grade": "Milbengrad",
  "mite_infested": "Milbenbefall",
  "mite_infested_leafs": "Milben - befallene Blätter",
  "moisture_status": "07. Feuchtigkeitsstatus",
  "molybdenum": "Molybdän",
  "MONTONEC": "Montonec",
  "MONTONEGA": "Montonega",
  "MOSCATELL DE ALEXANDRIA": "Moscatell aus Alexandria",
  "MOSCATELL ROMA": "Moscatell Rom",
  "NARANJA": "Orange",
  "NDRE": "NDRE",
  "NDVI": "NDVI",
  "NECATRINA": "Nektarine",
  "NECTARINA": "Nektarine",
  "nickel": "Nickel",
  "Nitrogen": "Stickstoff",
  "Nitrógeno Foliar": "Blattstickstoff",
  "nitrógeno-clorofila (ndre)": "Stickstoff-Chlorophyll (NDRE)",
  "Nitrógeno-Clorofila (NDRE)": "Stickstoff-Chlorophyll (NDRE)",
  "No crop or too dry": "Keine Ernte oder zu trocken",
  "NO CULTIVO": "Keine Ernte",
  "No hay datos": "Keine Daten",
  "NOGAL": "Walnuss",
  "notification_to_the_farmer_high_n_index": "g. Benachrichtigung an den Landwirt: Hoher N-Index",
  "Observations": "Beobachtungen",
  "OLIVO": "Olive",
  "opogona_larva_number": "Opogona - Larven",
  "organic_material": "Organisches Material",
  "organic_oxidizable_carbon": "Organischer oxidierbarer Kohlenstoff",
  "PANSA BLANCA": "Weiße Pansa",
  "PANSAL": "Pansal",
  "PARAGUAYO": "Paraguayo",
  "PARELLADA": "Parellada",
  "PATATA": "Kartoffel",
  "pattern": "Muster",
  "PERA": "Birne",
  "percent_disease": "Prozentsatz der Krankheit",
  "perkinsiella_345_instar_nymph": "Perkinsiella - Nymphen 3-4-5 Instar",
  "perkinsiella_adult": "Perkinsiella - Erwachsene",
  "perkinsiella_controller_coccinelidos": "Perkinsiella - Kontrolleure - Marienkäfer",
  "perkinsiella_controller_crysopas": "Perkinsiella - Kontrolleure - Chrysopas",
  "perkinsiella_controller_spiders": "Perkinsiella - Kontrolleure - Spinnen",
  "perkinsiella_controller_tytthus_parviceps": "Perkinsiella - Kontrolleure - Tytthus parviceps",
  "perkinsiella_controller_zelus": "Perkinsiella - Kontrolleure - Zelus",
  "perkinsiella_death_adult": "Perkinsiella - tote Erwachsene",
  "perkinsiella_death_nymph": "Perkinsiella - tote Nymphen",
  "petiole_sampling": "i. Blattstielprobenahme",
  "ph": "pH",
  "pheromone_trap_diatraea_adults_number": "Pheromonfalle - erwachsene Diatraea",
  "pheromone_trap_installation_date": "Pheromonfalle - Installationsdatum",
  "pheromone_trap_number": "Pheromonfalle - Fallen",
  "pheromone_trap_other_butterfly": "Pheromonfalle - andere Schmetterlinge",
  "pheromone_trap_pheromone_change": "Pheromonfalle - Pheromonwechsel",
  "phosphorus": "Phosphor",
  "PIMIENTO": "Paprika",
  "PINO": "Kiefer",
  "PINOT NOIR": "Pinot Noir",
  "PISTACHO": "Pistazie",
  "Pivot": "Schwenk",
  "Plague": "Plage",
  "plague_level": "09. Seuchenstufe",
  "Plant": "Pflanze",
  "Plant Density": "Pflanzendichte",
  "plantation_date": "Pflanzdatum",
  "plants_per_surface_unit": "Pflanzen pro Flächeneinheit",
  "poda": "Schnitt",
  "pol": "Pol",
  "POMELO": "Grapefruit",
  "POMELOS": "Pomele",
  "potassium": "Kalium",
  "potassium_saturation": "Kalium-Sättigung",
  "powdery_mildew_afa_perc": "Echter Mehltau - AFA(%)",
  "powdery_mildew_perc_of_affected_plants": "Echter Mehltau - % betroffener Pflanzen (Inzidenz)",
  "powdery_mildew_treatment": "Echter Mehltau - Behandlung",
  "Production": "Produktion",
  "pseudomonas_rubrilineans_stem_number": "Rotschimmel - Gesamtanzahl der Stängel",
  "pseudomonas_rubrilineans_stem_number_affected": "Rotschimmel - befallene Stängel",
  "pseudomonas_rubrilineans_stem_number_with_symptoms": "Rotschimmel - Stängel mit Symptomen",
  "puccinia_melanocephala_accumulated_scale": "Rost - Kumulierte Skala",
  "puccinia_melanocephala_infestation": "Rost - Befall",
  "puccinia_melanocephala_infested_leafs": "Rost - befallene Blätter",
  "puccinia_melanocephala_points": "Rost - Punkte",
  "puccinia_melanocephala_total_leafs": "Rost - Gesamtanzahl der Blätter",
  "puccinia_sp_incidence": "Puccinia sp \"Roya\" - Inzidenz",
  "puccinia_sp_severity": "Puccinia sp \"Roya\" - Schweregrad",
  "puccinia_sp_total_infected_leafs": "Puccinia sp \"Roya\" - Gesamtzahl befallener Blätter",
  "PUERRO": "Lauch",
  "Quality": "Qualität",
  "QUINOA": "Quinoa",
  "RAIGRAS": "Raigras",
  "real_leaves": "Echte Blätter",
  "reform": "Reform",
  "REMOLACHA": "Zuckerrübe",
  "REMOLACHA AZUCARERA": "Zuckerrübe",
  "resowing_surface": "Wiederanbaufläche",
  "result": "Ergebnis",
  "riego": "Bewässerung",
  "rodents_infestation_percent": "Nagetiere - Befall",
  "rot10roots_complete": "03. Verrottete 10 Wurzeln - Komplett",
  "rot10roots_nothing": "01. Verrottete 10 Wurzeln - Nichts",
  "rot10roots_partial": "02. Verrottete 10 Wurzeln - Teilweise",
  "rust_10_25_afa": "Roya - 10-25 % AFA",
  "rust_100_afa": "Roya - N - 100 % AFA. Originales Laub vollständig zerstört",
  "rust_25_35_afa": "Roya - J - 25-35 % AFA. 1/4 - 1/3 des Laubs stark betroffen",
  "rust_3_10_afa": "Roya - I - 3-10 % AFA",
  "rust_35_50_afa": "Roya - K - 35-50 % AFA. 1/3 - 1/2 des Laubs stark betroffen",
  "rust_50_75_afa": "Roya - L - 50-75 % AFA. Die Hälfte des Laubs ist stark betroffen",
  "rust_75_90_afa": "Roya - M - 75-90 % AFA. Das gesamte Laub ist stark betroffen",
  "rust_healthy_plant": "Roya - A - Gesunde Pflanze",
  "rust_isolated_spots_on_some_leaves_1_9_perc": "Roya - B - Isolierte Flecken auf einigen Blättern. 1-9 (%SL)",
  "rust_isolated_spots_on_some_leaves_10_19_perc": "Roya - C - Isolierte Flecken auf einigen Blättern. 10-19 (%SL)",
  "rust_isolated_spots_on_some_leaves_20_29_perc": "Roya - D - Isolierte Flecken auf einigen Blättern. 20-29 (%SL)",
  "rust_isolated_spots_on_some_leaves_30_50_perc": "Roya - E - Isolierte Flecken auf einigen Blättern. 30-50 (%SL)",
  "rust_regrowth_restart": "Roya - O - Beginn des Wiederaustriebs. Fügen Sie 0,5 Punkte pro Woche bis zur Ernte hinzu",
  "rust_spots_leaves_100_300": "Roya - H - Blätter mit 100-300 Flecken",
  "rust_spots_leaves_20_100": "Roya - G - 20-100 Flecken pro Blatt auf den meisten Blättern",
  "rust_spots_leaves_50": "Roya - F - >50 % SL und <20 Flecken pro Blatt auf den meisten Blättern",
  "rust_treatment": "Rost - p - Behandlung",
  "sac": "SAC",
  "Safety": "Sicherheit",
  "sample_color": "05. Probenfarbe",
  "sand": "Sand",
  "SAUVIGNON BLANC": "Sauvignon Blanc",
  "severity": "Schweregrad",
  "soca_width": "04. Anzahl der Wurzeln",
  "sodium": "Natrium",
  "sodium_saturation": "Natriumsättigung",
  "Soil": "Boden",
  "soil_moisture_status": "Bodenfeuchtigkeitsstatus",
  "SOJA": "Soja",
  "sowing_failure": "Aussaatversagen",
  "sprout_dead": "Toter Trieb",
  "sprout_total": "Gesamtanzahl der Triebe",
  "starch": "Stärke",
  "stems_evaluated": "Bewertete Stängel",
  "sulfur": "Schwefel",
  "Superficial": "Oberflächlich",
  "SYRAH": "Syrah",
  "TABACO": "Tabak",
  "TE": "Tee",
  "TEA": "Fackel",
  "TEMPRANILLO": "Tempranillo",
  "TOMATE": "Tomate",
  "tombing": "Liegende Pflanzen",
  "total_arsenic": "Gesamtarsen",
  "total_cadmium": "Gesamtcadmium",
  "TOURIGA NACIONAL": "Touriga Nacional",
  "TRIGO": "Weizen",
  "TRIGO BLANDO": "Weichweizen",
  "TRIGO DURO": "Hartweizen",
  "TRITICALE": "Triticale",
  "type_disease": "Krankheitstyp",
  "uneven_budding": "Ungleichmäßiges Knospen",
  "ustilago_scitaminea_infection": "Ustilago scitaminea \"Kohle\" - Infektion",
  "ustilago_scitaminea_stem_affected": "Ustilago scitaminea \"Kohle\" - insgesamt kranke Stängel",
  "ustilago_scitaminea_stem_symptoms": "Ustilago scitaminea \"Kohle\" - Stängel mit Symptomen",
  "ustilago_scitaminea_stem_total": "Ustilago scitaminea \"Kohle\" - Gesamte Stängel",
  "ustilago_scitaminea_stem_whip": "Ustilago scitaminea \"Kohle\" - Peitschenstängel",
  "UVA": "Traube",
  "UVA DE MESA": "Tafeltrauben",
  "Variabilidad": "Variabilität",
  "Variabilidad (NDVI-LUVI)": "Variabilität (NDVI-LUVI)",
  "VERDEJO": "Verdejo",
  "very high irrigation": "Sehr hoher Wasserstress",
  "very low irrigation": "Sehr niedriger Wasserstress",
  "VIGNE": "Weinrebe",
  "Vigor Vegetativo (NDVI-Drone)": "Vegetatives Vigor (NDVI-Drohne)",
  "Vigor Vegetativo (NDVI-LUVI)": "Vegetatives Vigor (NDVI-LUVI)",
  "vigor vegetativo (ndvi) - vigor alto": "Vegetatives Vigor (NDVI) - Hohes Vigor",
  "VIÑA": "Weinberg",
  "VIOGNIER": "Viognier",
  "virgin_female_georeferenced_trap_diatraea_adult_total": "Falle für weibliche Jungtiere - Gesamtanzahl der erwachsenen Diatraea",
  "virgin_female_georeferenced_trap_female_change": "Falle für weibliche Jungtiere - Weibchenwechsel",
  "virgin_female_georeferenced_trap_other_butterfly": "Falle für weibliche Jungtiere - andere Schmetterlinge",
  "virgin_female_georeferenced_trap_point": "Falle für weibliche Jungtiere - Fallenpunkt",
  "virgin_female_trap_diatraea_adult_number": "Falle für weibliche Jungtiere - Anzahl der Diatraea-Erwachsenen",
  "virgin_female_trap_female_change": "Falle für weibliche Jungtiere - Weibchenwechsel",
  "virgin_female_trap_installation_date": "Falle für weibliche Jungtiere - Installationsdatum",
  "virgin_female_trap_number": "Falle für weibliche Jungtiere - Fallen",
  "virgin_female_trap_other_butterfly": "Falle für weibliche Jungtiere - andere Schmetterlinge",
  "VIURA": "Viura",
  "weed_unload": "01. Unkräuter (Entladen)",
  "Weeds": "Unkräuter",
  "weight_100_beans": "Pflanzen von 100 Bohnen",
  "weight_kg": "06. Probegewicht",
  "whey": "Molke",
  "without_footwear": "01. Ohne Schuhe",
  "without_gloves": "04. Ohne Handschuhe",
  "without_vest": "02. Ohne Weste",
  "XARELLO": "Xarello",
  "yellowness_mild": "Gelbfärbung - Leichter Befall (lockere Pflanzen)",
  "yellowness_moderate": "Gelbfärbung - Mäßiger Befall (Bestände)",
  "yellowness_strong": "Gelbfärbung - Starker Befall (Feld vollständig betroffen)",
  "yellowness_without_symptoms": "Gelbfärbung - Ohne Symptome",
  "zinc": "Zink"
}
