// prettier-ignore
export default {
  "% Fallas": "% Failures",
  "% Mala Hierba": "% Weed",
  "ACELGA": "CHARD",
  "ACELGA VERDE": "ACCELGA GREEN",
  "action": "action",
  "ag_accesses_the_viewer": "k. AG accesses the viewer?",
  "AGUACATE": "AVOCADO",
  "AJO": "GARLIC",
  "ALBARICOQUE": "APRICOT",
  "ALBARINO": "ALBARINO",
  "ALFALFA": "ALFALFA",
  "ALGODON": "COTTON",
  "aluminum": "Aluminum",
  "aluminum_saturation": "Aluminum Saturation",
  "annotation": "annotation",
  "aphid_controller": "aphid - controller",
  "aphid_infested": "aphid - infestation",
  "aphid_infested_leafs": "aphid - infested leaves",
  "aphid_mite_total_leafs": "aphid and mite - total leaves",
  "APIO": "CELERY",
  "apparent_density": "Apparent density",
  "ARANDANOS": "BLUEBERRIES",
  "ARBOSANA": "ARBOSANA",
  "ARROZ": "RICE",
  "assesor_name": "Assessor name",
  "atr": "Atr",
  "AVENA": "OATS",
  "BARBECHO": "FALLOW",
  "BATAVIA": "BATAVIA",
  "beans_10_plants": "Plants of 10 beans",
  "billaea_healthy_pupe": "billaea - healthy pupae",
  "billaea_larva": "billaea - larvae",
  "billaea_parasitized_larva": "billaea - parasitized larvae",
  "billaea_residual_pupe": "billaea - residual pupae",
  "bipolaris_sacchari_incidence": "bipolaris sacchari \"eye spot\" - incidence",
  "bipolaris_sacchari_severity": "bipolaris sacchari \"eye spot\" - severity",
  "bipolaris_sacchari_total_infected_leafs": "bipolaris sacchari \"eye spot\" - total infected leaves",
  "BLANCO": "WHITE",
  "blastobasis_healthy_chrysalis": "blastobasis - healthy chrysalises",
  "blastobasis_larva_number": "blastobasis - larvae",
  "blastobasis_residual_chrysalis": "blastobasis - residual chrysalis",
  "boron": "Boron",
  "bouquet10roots_2_8cm": "02.Bouquet  10 roots (2-8cm)",
  "bouquet10roots_2cm": "01.Bouquet  10 roots (0-2cm)",
  "bouquet10roots_8cm": "03.Bouquet  10 roots (>8cm)",
  "brix": "Brix",
  "BROCOLI": "BROCCOLI",
  "bud_blastobasis_drilled": "diatraea blastobasis buds perforated",
  "bud_dehydrated": "dehydrated yolks",
  "bud_diatrea_drilled": "perforated diatraea buds",
  "bud_mechanical_damage": "buds mechanical damage",
  "bud_sprout": "buds - lalas",
  "bud_total": "total yolks",
  "bud_turgid": "perky yolks",
  "CABERNET SAUVIGNON": "CABERNET SAUVIGNON",
  "CALABACIN": "ZUCCHINI",
  "calcium": "Calcium",
  "calcium_saturation": "Calcium Saturation",
  "CAÑA DE AZUCAR": "SUGAR CANE",
  "CAQUI": "KHAKI",
  "casida_afa_perc": "Cásida - AFA(%)",
  "casida_n_of_adults_in_50_plants": "Cásida - No. of adults in 50 plants",
  "casida_n_of_eggs_in_50_plants": "Cásida - No. of eggs in 50 plants",
  "casida_n_of_large_larvae_in_50_plants": "Cásida - Nº Large larvae 50 plants",
  "casida_n_of_small_larvae_in_50_plants": "Cásida - Nº Small larvae 50 plants",
  "CAUCHO": "RUBBER",
  "CEBADA": "BARLEY",
  "CEBADA HIBRIDA": "HYBRID BARLEY",
  "CEBADA MALTERA": "BARLEY MALTERA",
  "CEBOLLA": "ONION",
  "cercospora_20_100_spots_leaf": "Cercospora -F- 20-100 spots/leaf",
  "cercospora_50_afa": "Cercospora -L- 50% AFA",
  "cercospora_50_perc_leaves_ext_with_80_100_severity": "Cercospora -K- 50% leaves ext with 80-100% severity",
  "cercospora_75_afa": "Cercospora -M- 75%AFA",
  "cercospora_90_100_afa": "Cercospora -N- 90-100%AFA",
  "cercospora_healthy_plant": "Cercospora -A- Healthy plant",
  "cercospora_isolated_spots_on_some_leaves_10_15_sl_100_leaves": "Cercospora -D- Isolated spots on some leaves (10-50%SL/100 leaves).",
  "cercospora_isolated_spots_on_some_leaves_5_10_sl_100_leaves": "Cercospora -C- Isolated spots on some leaves (5-10%SL/100 leaves)",
  "cercospora_isolated_spots_on_some_leaves_minus_5_sl_100_leaves": "Cercospora -B- Isolated spots on some leaves (<5%SL/100 leaves)",
  "cercospora_leaf_necrosis_ext": "Cercospora -H- Leaf necrosis EXT.",
  "cercospora_leaves_ext_almost_total_necrosis": "Cercospora -J- Leaves EXT: almost total necrosis",
  "cercospora_leaves_with_major_100_sports": "Cercospora -G- Leaves with > 100 spots",
  "cercospora_minus_20_spots_leaf": "Cercospora -E- <20 spots/leaf",
  "cercospora_necrosis_on_most_leaves_int": "Cercospora -I- Necrosis on most leaves INT.",
  "cercospora_treatment": "Cercospora -o- Treatment",
  "CEREZA": "CHERRY",
  "CESPED": "GRASS",
  "CHARDONNAY": "CHARDONNAY",
  "chromium": "Chromium",
  "cic": "Cic",
  "CIRUELA": "PLUM",
  "clay": "Clay",
  "CLEMENTINA": "CLEMENTINE",
  "close": "close",
  "COLZA": "RAPE",
  "Conteo": "count",
  "copper": "Copper",
  "cotesia_cocon": "cotesia - cocones",
  "cotesia_parasitized_larva": "cotesia - parasitized larvae",
  "cotyledon": "cotyledon",
  "current_ndre_status": "a. Current NDRE Status",
  "date_of_first_informative_email_sent": "j. Date of first informative email sent",
  "date_of_hemav_ndre_layer_screenshot": "h. Date of Hemav NDRE layer screenshot",
  "defoliator_leaf_evaluated": "defoliator - evaluated leaves",
  "defoliator_marasmia_sp_larva_alive": "defoliator - marasmia sp. - live larvae",
  "defoliator_marasmia_sp_larva_death": "defoliator - marasmia sp. - dead larvae",
  "defoliator_marasmia_sp_larva_per_stem": "defoliator - marasmia sp. - larvae per stem",
  "defoliator_mythimna_sp_larva_alive": "defoliator - mythimna sp. - live larvae",
  "defoliator_mythimna_sp_larva_death": "defoliator - mythimna sp. - dead larvae",
  "defoliator_mythimna_sp_larva_per_stem": "defoliator - mythimna sp. - larvae per stem",
  "defoliator_spodoptera_sp_larva_alive": "defoliator - spodoptera sp. - live larvae",
  "defoliator_spodoptera_sp_larva_death": "defoliator - spodoptera sp. - dead larvae",
  "defoliator_spodoptera_sp_larva_per_stem": "defoliator - spodoptera sp. - larvae per stem",
  "defoliator_stem_total": "defoliator - total stems",
  "depth": "Depth",
  "desconocido": "Unknown",
  "DESCONOCIDO": "UNKNOWN",
  "diatraea_saccharalis_larva_big": "diatraea sachharalis - large larvae",
  "diatraea_saccharalis_larva_healthy_chrysalis": "diatraea sachharalis - healthy chrysalises",
  "diatraea_saccharalis_larva_medium": "diatraea sachharalis - medium larvae",
  "diatraea_saccharalis_larva_residual_chrysalis": "diatraea sachharalis - residual chrysalis",
  "diatraea_saccharalis_larva_small": "diatraea sachharalis - small larvae",
  "diatraea_saccharalis_larva_total": "diatraea sachharalis - total larvae",
  "disease": "Disease",
  "Disease": "Disease",
  "disease_level": "08.Disease Level",
  "distance_between_rows": "03.Distance between rows",
  "does_it_follow_the_recommendation": "f. Does it follow the recommendation?",
  "Drip": "Drip",
  "dry_weight": "Dry weight",
  "effective_cation_exchange_capacity": "Effective Cation Exchange Capacity (ECEC)",
  "ESCAROLA": "ENDIVE",
  "ESCAROLA LISA": "SMOOTH ESCAROLA",
  "ESCAROLA RIZADA": "CURLY ESCAROLA",
  "ESPARRAGO": "ASPARAGUS",
  "ESPINACA": "SPINACH",
  "estrés hídrico": "Water stress",
  "Estrés hídrico (LWS)": "Water Stress (LWS)",
  "evaluator": "10.Assessor name",
  "Ex": "Ex.",
  "exchangeable_acidity": "Exchangeable Acidity",
  "fallas": "Gaps",
  "fire": "Fire",
  "flowering": "Flowering",
  "forecast_yield_sampling_1": "FORECAST YIELD SAMPLING 1",
  "forecast_yield_sampling_2": "FORECAST YIELD SAMPLING 2",
  "forecast_yield_sampling_3": "FORECAST YIELD SAMPLING 3",
  "forecast_yield_sampling_4": "FORECAST YIELD SAMPLING 4",
  "fresh_weight": "Fresh weight",
  "FRIJOL": "BEANS",
  "fruits_count": "Fruits count",
  "FRUTAL": "FRUIT",
  "Generic": "Generic",
  "GEWURZTRAMINNER": "GEWURZTRAMINNER",
  "GIRASOL": "SUNFLOWER",
  "gm_avg_ndvi": "Vegetative vigour (NDVI-Drone)",
  "gm_luvi_ndvi_mean": "Vegetative vigour (NDVI-LUVI)",
  "gm_ndmi_mean": "Vegetative Moisture (NDMI) ",
  "gm_ndre_mean": "Nitrogen-Chlorophyll (NDRE)",
  "gm_perc_overseeding": "% Gaps",
  "gm_perc_weeds": "% Weed",
  "GODELLO": "GODELLO",
  "GOLDEN DELICIOUS": "GOLDEN DELICIOUS",
  "GOLDEN REINDERS": "GOLDEN REINDERS",
  "Grams": "Grams",
  "GRANADA": "POMEGRANATE",
  "green_dots": "green dots",
  "GRENACHE BLANC": "GRENACHE BLANC",
  "GUISANTE": "PEA",
  "HABA": "BEAN",
  "hanger_installation": "Hanger Installation",
  "height": "Height",
  "HIBRIDO": "HYBRID",
  "high irrigation": "High water stress",
  "high_n_from_irrigation_water": "d. High N from irrigation water",
  "high_n_from_nitrogen_input": "e. High N from nitrogen input",
  "high_n_from_previous_years": "c. High N from previous years",
  "higher level": "Higher Level",
  "HIGO": "FIG",
  "Humedad Vegetativa (NDMI) ": "Vegetative Moisture (NDMI) ",
  "humidity": "Humidity",
  "humidity_100_beans": "Humidity in 100 beans",
  "Imperial": "Imperial",
  "inspection": "inspection",
  "internodes_blastobasis_damage_longitude": "internodes blastobasis length damage",
  "internodes_blastobasis_drilled": "perforated blastobasis internodes",
  "internodes_diatraea_damage_longitude": "internodes diatrea length damage",
  "internodes_diatraea_drilled": "perforated diatrea entrenodes",
  "internodes_evaluated": "total internodes",
  "internodes_healthy": "healthy internodes",
  "internodes_mechanical_damage": "internal mechanical damage",
  "internodes_physiological_damage": "internode physiological damage",
  "iron": "Iron",
  "irrigation": "IRRIGATION",
  "Irrigation": "Irrigation",
  "irrigation_dosage": "Irrigation Dosage",
  "irrigation_uniformity": "Irrigation Uniformity",
  "KUMQUATS": "KUMQUATS",
  "language": "System language",
  "larva_number_big": "larval stadium - large larva",
  "larva_number_medium": "larval stadium - medium larva",
  "larva_number_small": "larval stadium - small larva",
  "last_fertilizer_app_date": "b. Last Fertilizer Application Dat",
  "last_irrigation": "Last Irrigation",
  "lead": "Lead",
  "Leaf": "Leaf",
  "leaf_moisture_status": "Leaf Moisture Status",
  "LECHUGA": "LETTUCE",
  "LECHUGA ICEBERG": "ICEBERG LETTUCE",
  "leptosphaeria_sacchari_incidence": "leptosphaeria sacchari \"ring spot\" - incidence",
  "leptosphaeria_sacchari_severity": "leptosphaeria sacchari \"ringspot\" - severity",
  "leptosphaeria_sacchari_total_infected_leafs": "leptosphaeria sacchari \"annular spot\" - total infected leaves",
  "LIMON": "LEMON",
  "linear_meters": "02.Linear meters",
  "loam": "Loam",
  "loss_of_vigor": "Loss of vigor",
  "low irrigation": "Low water stress",
  "lower level": "Lower Level",
  "lowered_unload": "03.Lowered in unload",
  "MACABEU": "MACABEU",
  "MACABEU VIURA": "MACABEU VIURA",
  "magnesium": "Magnesium",
  "magnesium_saturation": "Magnesium Saturation",
  "MAIZ": "CORN",
  "maleza (ciclo<60 dias)": "Weeds (Cycle<60 days)",
  "MALVASIA AROMATICA": "AROMATIC MALVASIA",
  "MALVASIA DE SITGES": "MALVASIA DE SITGES",
  "MALVASIA GROSSA": "MALVASIA GROSSA",
  "MANDARINA": "TANGERINE",
  "manganese": "Mangese",
  "MANZANA": "APPLE",
  "mean level": "Mean Level",
  "medium irrigation": "Medium water stress",
  "medium_moisture_saturation": "Medium Moisture Saturation",
  "MELOCOTON": "PEACH",
  "MELON": "CANTALOUPE",
  "mercury": "Mercury",
  "MERLOT": "MERLOT",
  "Metric": "Metric",
  "microorganisms_larva": "larvae microorganisms",
  "mite_grade": "mid-grade",
  "mite_infested": "mite-infestation",
  "mite_infested_leafs": "mite - infested leaves",
  "moisture_status": "07.Moisture Status",
  "molybdenum": "Molybdenum",
  "MONTONEC": "MONTONEC",
  "MONTONEGA": "MONTONEGA",
  "MOSCATELL DE ALEXANDRIA": "MOSCATELL OF ALEXANDRIA",
  "MOSCATELL ROMA": "MOSCATELL ROME",
  "NARANJA": "ORANGE",
  "NDRE": "NDRE",
  "NDVI": "NDVI",
  "NECATRINA": "NECATRINA",
  "NECTARINA": "NECTARINE",
  "nickel": "Nickel",
  "Nitrogen": "Nitrogen",
  "Nitrógeno Foliar": "Foliar nitrogen",
  "nitrógeno-clorofila (ndre)": "Nitrogen-Chlorophyll (NDRE)",
  "Nitrógeno-Clorofila (NDRE)": "Nitrogen-Chlorophyll (NDRE)",
  "No crop or too dry": "No crop or too dry",
  "NO CULTIVO": "NO CROP",
  "No hay datos": "No data",
  "NOGAL": "WALNUT",
  "notification_to_the_farmer_high_n_index": "g. Notification to the farmer: High N index",
  "Observations": "Observations",
  "OLIVO": "OLIVE",
  "opogona_larva_number": "opogona - larvae",
  "organic_material": "Organic material",
  "organic_oxidizable_carbon": "Organic Oxidizable Carbon",
  "PANSA BLANCA": "WHITE PANSA",
  "PANSAL": "PANSAL",
  "PARAGUAYO": "PARAGUAYAN",
  "PARELLADA": "PARELLATED",
  "PATATA": "POTATO",
  "pattern": "pattern",
  "PERA": "PEAR",
  "percent_disease": "Percent disease",
  "perkinsiella_345_instar_nymph": "perkinsiella - nymphs 3-4-5 instar",
  "perkinsiella_adult": "perkinsiella - adults",
  "perkinsiella_controller_coccinelidos": "perkinsiella - controllers - coccinellids",
  "perkinsiella_controller_crysopas": "perkinsiella - controllers - crysopas",
  "perkinsiella_controller_spiders": "perkinsiella - controllers - spiders",
  "perkinsiella_controller_tytthus_parviceps": "perkinsiella - drivers - tytthus parviceps",
  "perkinsiella_controller_zelus": "perkinsiella - drivers - zelus",
  "perkinsiella_death_adult": "perkinsiella - adults dead",
  "perkinsiella_death_nymph": "perkinsiella - dead nymphs",
  "petiole_sampling": "i. Petiole sampling",
  "ph": "Ph",
  "pheromone_trap_diatraea_adults_number": "pheromone trap - adult diatraea",
  "pheromone_trap_installation_date": "pheromone trap - installation date",
  "pheromone_trap_number": "pheromone trap - traps",
  "pheromone_trap_other_butterfly": "pheromone trap - other butterflies",
  "pheromone_trap_pheromone_change": "pheromone trap - pheromone change",
  "phosphorus": "Phosphorus",
  "PIMIENTO": "PEPPER",
  "PINO": "PINE TREE",
  "PINOT NOIR": "PINOT NOIR",
  "PISTACHO": "PISTACHIO",
  "Pivot": "Pivot",
  "Plague": "Plague",
  "plague_level": "09.Plague Level",
  "Plant": "Plant",
  "Plant Density": "Plant Density",
  "plantation_date": "Plantation date",
  "plants_per_surface_unit": "Plants per surface unit",
  "poda": "Pruning",
  "pol": "Pol",
  "POMELO": "GRAPEFRUIT",
  "POMELOS": "POMELOS",
  "potassium": "Potassium",
  "potassium_saturation": "Potassium Saturation",
  "powdery_mildew_afa_perc": "Oídio - AFA(%)",
  "powdery_mildew_perc_of_affected_plants": "Oídio - % of affected plants (INCIDENCE)",
  "powdery_mildew_treatment": "Powdery Mildew - Treatment",
  "Production": "Production",
  "pseudomonas_rubrilineans_stem_number": "red stripe - total stems",
  "pseudomonas_rubrilineans_stem_number_affected": "red stripe - stems affected",
  "pseudomonas_rubrilineans_stem_number_with_symptoms": "red stripe - stems with symptoms",
  "puccinia_melanocephala_accumulated_scale": "rust - cumulative scale",
  "puccinia_melanocephala_infestation": "rust-infestation",
  "puccinia_melanocephala_infested_leafs": "rust-infested leaves",
  "puccinia_melanocephala_points": "rust - points",
  "puccinia_melanocephala_total_leafs": "rust - total leaves",
  "puccinia_sp_incidence": "puccinia sp \"roya\" - incidence",
  "puccinia_sp_severity": "puccinia sp \"roya\" - severity",
  "puccinia_sp_total_infected_leafs": "puccinia sp \"roya\" - total infected leaves",
  "PUERRO": "LEEK",
  "Quality": "Quality",
  "QUINOA": "QUINOA",
  "RAIGRAS": "RAIGRAS",
  "real_leaves": "real leaves",
  "reform": "Reform",
  "REMOLACHA": "SUGARBEET",
  "REMOLACHA AZUCARERA": "SUGAR BEET",
  "resowing_surface": "Resowing surface",
  "result": "result",
  "riego": "Irrigation",
  "rodents_infestation_percent": "Rodents - Infestation",
  "rot10roots_complete": "03.Rot 10 roots- Complete",
  "rot10roots_nothing": "01.Rot 10 roots - Nothing ",
  "rot10roots_partial": "02.Rot 10 roots - Partial   ",
  "rust_10_25_afa": "Roya - 10-25 % AFA",
  "rust_100_afa": "Roya -N- 100 % AFA. Original foliage completely destroyed",
  "rust_25_35_afa": "Roya -J- 25-35 % AFA. 1/4 -1/3 of the foliage is severely affected",
  "rust_3_10_afa": "Roya -I- 3-10 % AFA",
  "rust_35_50_afa": "Roya -K- 35-50 % AFA.  1/3 - 1/2 of the foliage is severely affected",
  "rust_50_75_afa": "Roya -L- 50-75 % AFA. 1/2 of the foliage is severely affected",
  "rust_75_90_afa": "Roya -M- 75-90 % AFA. All foliage is severely affected",
  "rust_healthy_plant": "Roya -A- Healthy plant",
  "rust_isolated_spots_on_some_leaves_1_9_perc": "Roya -B- Isolated spots on some leaves. 1-9 (%SL)",
  "rust_isolated_spots_on_some_leaves_10_19_perc": "Roya -C- Isolated spots on some leaves. 10-19 (%SL)",
  "rust_isolated_spots_on_some_leaves_20_29_perc": "Roya -D- Isolated spots on some leaves. 20-29 (%SL)",
  "rust_isolated_spots_on_some_leaves_30_50_perc": "Roya -E- Isolated spots on some leaves. 30-50 (%SL)",
  "rust_regrowth_restart": "Roya -O- Beginning of regrowth. Add 0.5 points for each week until harvest",
  "rust_spots_leaves_100_300": "Roya -H- Leaves with 100-300 spots",
  "rust_spots_leaves_20_100": "Roya -G- 20-100 spots per leaf on most leaves",
  "rust_spots_leaves_50": "Roya -F- >50% SL and <20 spots per leaf on most leaves",
  "rust_treatment": "Rust -p- Treatment",
  "sac": "Sac",
  "Safety": "Safety",
  "sample_color": "05.Sample color",
  "sand": "Sand",
  "SAUVIGNON BLANC": "SAUVIGNON BLANC",
  "severity": "Severity",
  "soca_width": "04.Number of roots",
  "sodium": "Sodium",
  "sodium_saturation": "Sodium Saturation",
  "Soil": "Soil",
  "soil_moisture_status": "Soil Moisture Status",
  "SOJA": "SOY",
  "sowing_failure": "Sowing_failure",
  "sprout_dead": "dead shoot",
  "sprout_total": "total outbreaks",
  "starch": "Starch",
  "stems_evaluated": "assessed stems",
  "sulfur": "Sulfur",
  "Superficial": "Superficial",
  "SYRAH": "SYRAH",
  "TABACO": "TOBACCO",
  "TE": "TEA",
  "TEA": "TORCH",
  "TEMPRANILLO": "TEMPRANILLO",
  "TOMATE": "TOMATO",
  "tombing": "Fallen plants",
  "total_arsenic": "Total Arsenic",
  "total_cadmium": "Total Cadmium",
  "TOURIGA NACIONAL": "NATIONAL TOURIGA",
  "TRIGO": "WHEAT",
  "TRIGO BLANDO": "SOFT WHEAT",
  "TRIGO DURO": "HARD WHEAT",
  "TRITICALE": "TRITICALE",
  "type_disease": "Type disease",
  "uneven_budding": "Uneven budding",
  "ustilago_scitaminea_infection": "ustilago scitaminea \"coal\" - infection",
  "ustilago_scitaminea_stem_affected": "ustilago scitaminea \"charcoal\" - total sick stems",
  "ustilago_scitaminea_stem_symptoms": "ustilago scitaminea \"coal\" - stems with symptoms",
  "ustilago_scitaminea_stem_total": "ustilago scitaminea \"coal\" - whole stems",
  "ustilago_scitaminea_stem_whip": "ustilago scitaminea \"coal\" - whip stems",
  "UVA": "GRAPE",
  "UVA DE MESA": "TABLE GRAPES",
  "Variabilidad": "Variability",
  "Variabilidad (NDVI-LUVI)": "Variability (NDVI-LUVI)",
  "VERDEJO": "GREEN",
  "very high irrigation": "Very high water stress",
  "very low irrigation": "Very low water stress",
  "VIGNE": "VIGNE",
  "Vigor Vegetativo (NDVI-Drone)": "Vegetative vigour (NDVI-Drone)",
  "Vigor Vegetativo (NDVI-LUVI)": "Vegetative vigour (NDVI-LUVI)",
  "vigor vegetativo (ndvi) - vigor alto": "Vegetative vigor (NDVI) - High vigor",
  "VIÑA": "VINEYARD",
  "VIOGNIER": "VIOGNIER",
  "virgin_female_georeferenced_trap_diatraea_adult_total": "trap female virgin - total adults diatraea",
  "virgin_female_georeferenced_trap_female_change": "virgin female trap - female change",
  "virgin_female_georeferenced_trap_other_butterfly": "virgin female trap - other butterflies",
  "virgin_female_georeferenced_trap_point": "virgin female trap - trap point",
  "virgin_female_trap_diatraea_adult_number": "virgin female trap - diatraea adults",
  "virgin_female_trap_female_change": "virgin female trap - female change",
  "virgin_female_trap_installation_date": "virgin female trap - installation date",
  "virgin_female_trap_number": "virgin female trap - traps",
  "virgin_female_trap_other_butterfly": "virgin female trap - other butterflies",
  "VIURA": "VIURA",
  "weed_unload": "01. Weeds (unload)",
  "Weeds": "Weeds",
  "weight_100_beans": "Plants of 100 beans",
  "weight_kg": "06.Sample weight",
  "whey": "Whey",
  "without_footwear": "01.Without Footwear",
  "without_gloves": "04.Without Gloves",
  "without_vest": "02.Without Vest",
  "XARELLO": "XARELLO",
  "yellowness_mild": "Yellowness - Mild attack (loose plants)",
  "yellowness_moderate": "Yellowness - Moderate attack (stands)",
  "yellowness_strong": "Yellow - Strong Attack (plot completely affected)",
  "yellowness_without_symptoms": "Yellowness - Without symptoms",
  "zinc": "Zinc"
}
