// prettier-ignore
export default {
  "% Fallas": "% Fallimenti",
  "% Mala Hierba": "% Erbacce",
  "ACELGA": "BIETOLA",
  "ACELGA VERDE": "BIETOLA VERDE",
  "action": "azione",
  "ag_accesses_the_viewer": "l'agricoltore accede al visualizzatore?",
  "AGUACATE": "AVOCADO",
  "AJO": "AGLIO",
  "ALBARICOQUE": "ALBICOCCA",
  "ALBARINO": "ALBARINO",
  "ALFALFA": "ERBA MEDICA",
  "ALGODON": "COTONE",
  "aluminum": "Alluminio",
  "aluminum_saturation": "Saturazione di Alluminio",
  "annotation": "annotazione",
  "aphid_controller": "controllore di afidi",
  "aphid_infested": "infestato da afidi",
  "aphid_infested_leafs": "foglie infestate da afidi",
  "aphid_mite_total_leafs": "totale foglie con afidi e acari",
  "APIO": "SEDANO",
  "apparent_density": "densità apparente",
  "ARANDANOS": "MIRTILLI",
  "ARBOSANA": "ARBOSANA",
  "ARROZ": "RISO",
  "assesor_name": "nome del valutatore",
  "atr": "atr",
  "AVENA": "AVENA",
  "BARBECHO": "MAGGESE",
  "BATAVIA": "BATAVIA",
  "beans_10_plants": "piante di 10 fagioli",
  "billaea_healthy_pupe": "pupa sana di billaea",
  "billaea_larva": "larva di billaea",
  "billaea_parasitized_larva": "larva parassitizzata di billaea",
  "billaea_residual_pupe": "pupa residua di billaea",
  "bipolaris_sacchari_incidence": "incidenza di bipolaris sacchari \"macchia dell'occhio\"",
  "bipolaris_sacchari_severity": "gravità di bipolaris sacchari \"macchia dell'occhio\"",
  "bipolaris_sacchari_total_infected_leafs": "totale foglie infette da bipolaris sacchari \"macchia dell'occhio\"",
  "BLANCO": "BIANCO",
  "blastobasis_healthy_chrysalis": "crisalidi sane di blastobasis",
  "blastobasis_larva_number": "numero di larve di blastobasis",
  "blastobasis_residual_chrysalis": "crisalidi residue di blastobasis",
  "boron": "Boro",
  "bouquet10roots_2_8cm": "Mazzo 10 radici (2-8cm)",
  "bouquet10roots_2cm": "Mazzo 10 radici (0-2cm)",
  "bouquet10roots_8cm": "Mazzo 10 radici (>8cm)",
  "brix": "Brix",
  "BROCOLI": "BROCCOLO",
  "bud_blastobasis_drilled": "gemme perforate da blastobasis",
  "bud_dehydrated": "gemme disidratate",
  "bud_diatrea_drilled": "gemme perforate da diatrea",
  "bud_mechanical_damage": "danno meccanico alle gemme",
  "bud_sprout": "gemme - germogli",
  "bud_total": "totale gemme",
  "bud_turgid": "gemme turgide",
  "CABERNET SAUVIGNON": "CABERNET SAUVIGNON",
  "CALABACIN": "ZUCCHINA",
  "calcium": "Calcio",
  "calcium_saturation": "Saturazione di Calcio",
  "CAÑA DE AZUCAR": "CANNA DA ZUCCHERO",
  "CAQUI": "CACHI",
  "casida_afa_perc": "Cásida - AFA(%)",
  "casida_n_of_adults_in_50_plants": "Cásida - Numero di adulti in 50 piante",
  "casida_n_of_eggs_in_50_plants": "Cásida - Numero di uova in 50 piante",
  "casida_n_of_large_larvae_in_50_plants": "Cásida - Numero di larve grandi in 50 piante",
  "casida_n_of_small_larvae_in_50_plants": "Cásida - Numero di larve piccole in 50 piante",
  "CAUCHO": "CAUCCIU",
  "CEBADA": "ORZO",
  "CEBADA HIBRIDA": "ORZO IBRIDO",
  "CEBADA MALTERA": "ORZO DA MALTO",
  "CEBOLLA": "CIPOLLA",
  "cercospora_20_100_spots_leaf": "Cercospora -F- 20-100 macchie/foglia",
  "cercospora_50_afa": "Cercospora -L- 50% AFA",
  "cercospora_50_perc_leaves_ext_with_80_100_severity": "Cercospora -K- 50% foglie est con gravità 80-100%",
  "cercospora_75_afa": "Cercospora -M- 75%AFA",
  "cercospora_90_100_afa": "Cercospora -N- 90-100%AFA",
  "cercospora_healthy_plant": "Cercospora -A- Pianta sana",
  "cercospora_isolated_spots_on_some_leaves_10_15_sl_100_leaves": "Cercospora -D- Macchie isolate su alcune foglie (10-50%SL/100 foglie).",
  "cercospora_isolated_spots_on_some_leaves_5_10_sl_100_leaves": "Cercospora -C- Macchie isolate su alcune foglie (5-10%SL/100 foglie)",
  "cercospora_isolated_spots_on_some_leaves_minus_5_sl_100_leaves": "Cercospora -B- Macchie isolate su alcune foglie (<5%SL/100 foglie)",
  "cercospora_leaf_necrosis_ext": "Cercospora -H- Necrosi fogliare EST.",
  "cercospora_leaves_ext_almost_total_necrosis": "Cercospora -J- Foglie EST: quasi necrosi totale",
  "cercospora_leaves_with_major_100_sports": "Cercospora -G- Foglie con > 100 macchie",
  "cercospora_minus_20_spots_leaf": "Cercospora -E- <20 macchie/foglia",
  "cercospora_necrosis_on_most_leaves_int": "Cercospora -I- Necrosi sulla maggior parte delle foglie INT.",
  "cercospora_treatment": "Cercospora -o- Trattamento",
  "CEREZA": "CILIEGIA",
  "CESPED": "PRATO",
  "CHARDONNAY": "CHARDONNAY",
  "chromium": "Cromo",
  "cic": "Cic",
  "CIRUELA": "SUSINA",
  "clay": "Argilla",
  "CLEMENTINA": "CLEMENTINA",
  "close": "chiudi",
  "COLZA": "COLZA",
  "Conteo": "conteggio",
  "copper": "Rame",
  "cotesia_cocon": "cotesia - bozzoli",
  "cotesia_parasitized_larva": "cotesia - larva parassitizzata",
  "cotyledon": "cotiledone",
  "current_ndre_status": "a. Stato attuale NDRE",
  "date_of_first_informative_email_sent": "j. Data del primo email informativo inviato",
  "date_of_hemav_ndre_layer_screenshot": "h. Data dello screenshot del strato NDRE di Hemav",
  "defoliator_leaf_evaluated": "defogliatore - foglie valutate",
  "defoliator_marasmia_sp_larva_alive": "defogliatore - marasmia sp. - larve vive",
  "defoliator_marasmia_sp_larva_death": "defogliatore - marasmia sp. - larve morte",
  "defoliator_marasmia_sp_larva_per_stem": "defogliatore - marasmia sp. - larve per stelo",
  "defoliator_mythimna_sp_larva_alive": "defogliatore - mythimna sp. - larve vive",
  "defoliator_mythimna_sp_larva_death": "defogliatore - mythimna sp. - larve morte",
  "defoliator_mythimna_sp_larva_per_stem": "defogliatore - mythimna sp. - larve per stelo",
  "defoliator_spodoptera_sp_larva_alive": "defogliatore - spodoptera sp. - larve vive",
  "defoliator_spodoptera_sp_larva_death": "defogliatore - spodoptera sp. - larve morte",
  "defoliator_spodoptera_sp_larva_per_stem": "defogliatore - spodoptera sp. - larve per stelo",
  "defoliator_stem_total": "defogliatore - totale steli",
  "depth": "Profondità",
  "desconocido": "Sconosciuto",
  "DESCONOCIDO": "SCONOSCIUTO",
  "diatraea_saccharalis_larva_big": "diatraea sachharalis - larve grandi",
  "diatraea_saccharalis_larva_healthy_chrysalis": "diatraea sachharalis - crisalidi sane",
  "diatraea_saccharalis_larva_medium": "diatraea sachharalis - larve medie",
  "diatraea_saccharalis_larva_residual_chrysalis": "diatraea sachharalis - crisalidi residue",
  "diatraea_saccharalis_larva_small": "diatraea sachharalis - larve piccole",
  "diatraea_saccharalis_larva_total": "diatraea sachharalis - totale larve",
  "disease": "Malattia",
  "Disease": "Malattia",
  "disease_level": "08.Livello di malattia",
  "distance_between_rows": "03.Distanza tra le file",
  "does_it_follow_the_recommendation": "f. Segue la raccomandazione?",
  "Drip": "Gocciolamento",
  "dry_weight": "Peso secco",
  "effective_cation_exchange_capacity": "Capacità Efficace di Scambio Cationico (CESC)",
  "ESCAROLA": "SCAROLA",
  "ESCAROLA LISA": "SCAROLA LISCIA",
  "ESCAROLA RIZADA": "SCAROLA RICCIUTA",
  "ESPARRAGO": "ASPARAGO",
  "ESPINACA": "SPINACIO",
  "estrés hídrico": "Stress idrico",
  "Estrés hídrico (LWS)": "Stress idrico (LWS)",
  "evaluator": "10.Nome del valutatore",
  "Ex": "Es.",
  "exchangeable_acidity": "Acidità Scambiabile",
  "fallas": "Lacune",
  "fire": "Incendio",
  "flowering": "Fioritura",
  "forecast_yield_sampling_1": "PREVISIONE DEL RENDIMENTO CAMPIONAMENTO 1",
  "forecast_yield_sampling_2": "PREVISIONE DEL RENDIMENTO CAMPIONAMENTO 2",
  "forecast_yield_sampling_3": "PREVISIONE DEL RENDIMENTO CAMPIONAMENTO 3",
  "forecast_yield_sampling_4": "PREVISIONE DEL RENDIMENTO CAMPIONAMENTO 4",
  "fresh_weight": "Peso fresco",
  "FRIJOL": "FAGIOLO",
  "fruits_count": "Conteggio dei frutti",
  "FRUTAL": "FRUTTETO",
  "Generic": "Generico",
  "GEWURZTRAMINNER": "GEWURZTRAMINER",
  "GIRASOL": "GIRASOLE",
  "gm_avg_ndvi": "Vigore vegetativo (NDVI-Drone)",
  "gm_luvi_ndvi_mean": "Vigore vegetativo (NDVI-LUVI)",
  "gm_ndmi_mean": "Umidità vegetativa (NDMI)",
  "gm_ndre_mean": "Azoto-Clorofilla (NDRE)",
  "gm_perc_overseeding": "% Vuoti",
  "gm_perc_weeds": "% Erbacce",
  "GODELLO": "GODELLO",
  "GOLDEN DELICIOUS": "GOLDEN DELICIOUS",
  "GOLDEN REINDERS": "GOLDEN REINDERS",
  "Grams": "Grammi",
  "GRANADA": "MELOGRANO",
  "green_dots": "punti verdi",
  "GRENACHE BLANC": "GRENACHE BLANC",
  "GUISANTE": "PISËLL",
  "HABA": "FAVA",
  "hanger_installation": "Installazione di trespolo",
  "height": "Altezza",
  "HIBRIDO": "IBRIDO",
  "high irrigation": "Alto stress idrico",
  "high_n_from_irrigation_water": "d. Alto N dall'acqua di irrigazione",
  "high_n_from_nitrogen_input": "e. Alto N dall'input di azoto",
  "high_n_from_previous_years": "c. Alto N dagli anni precedenti",
  "higher level": "Livello più alto",
  "HIGO": "FICO",
  "Humedad Vegetativa (NDMI)": "Umidità vegetativa (NDMI)",
  "humidity": "Umidità",
  "humidity_100_beans": "Umidità in 100 fagioli",
  "Imperial": "Imperiale",
  "inspection": "ispezione",
  "internodes_blastobasis_damage_longitude": "danni alla lunghezza degli internodi di blastobasis",
  "internodes_blastobasis_drilled": "internodi di blastobasis perforati",
  "internodes_diatraea_damage_longitude": "danni alla lunghezza degli internodi di diatrea",
  "internodes_diatraea_drilled": "internodi di diatrea perforati",
  "internodes_evaluated": "internodi totali",
  "internodes_healthy": "internodi sani",
  "internodes_mechanical_damage": "danno meccanico interno",
  "internodes_physiological_damage": "danno fisiologico agli internodi",
  "iron": "Ferro",
  "irrigation": "IRRIGAZIONE",
  "Irrigation": "Irrigazione",
  "irrigation_dosage": "Dosaggio dell'irrigazione",
  "irrigation_uniformity": "Uniformità dell'irrigazione",
  "KUMQUATS": "KUMQUATS",
  "language": "Lingua del sistema",
  "larva_number_big": "stadio larvale - larva grande",
  "larva_number_medium": "stadio larvale - larva media",
  "larva_number_small": "stadio larvale - larva piccola",
  "last_fertilizer_app_date": "b. Data dell'ultima applicazione di fertilizzante",
  "last_irrigation": "Ultima irrigazione",
  "lead": "Piombo",
  "Leaf": "Foglia",
  "leaf_moisture_status": "Stato di umidità della foglia",
  "LECHUGA": "LATTUGA",
  "LECHUGA ICEBERG": "LATTUGA ICEBERG",
  "leptosphaeria_sacchari_incidence": "incidenza di leptosphaeria sacchari \"macchia anulare\"",
  "leptosphaeria_sacchari_severity": "gravità di leptosphaeria sacchari \"macchia anulare\"",
  "leptosphaeria_sacchari_total_infected_leafs": "totale foglie infette da leptosphaeria sacchari \"macchia anulare\"",
  "LIMON": "LIMONE",
  "linear_meters": "02.Metri lineari",
  "loam": "Limo",
  "loss_of_vigor": "Perdita di vigore",
  "low irrigation": "Basso stress idrico",
  "lower level": "Livello inferiore",
  "lowered_unload": "03.Abbassato in scarico",
  "MACABEU": "MACABEU",
  "MACABEU VIURA": "MACABEU VIURA",
  "magnesium": "Magnesio",
  "magnesium_saturation": "Saturazione di Magnesio",
  "MAIZ": "MAIS",
  "maleza (ciclo<60 dias)": "Erbacce (Ciclo<60 giorni)",
  "MALVASIA AROMATICA": "MALVASIA AROMATICA",
  "MALVASIA DE SITGES": "MALVASIA DI SITGES",
  "MALVASIA GROSSA": "MALVASIA GROSSA",
  "MANDARINA": "MANDARINO",
  "manganese": "Manganese",
  "MANZANA": "MELA",
  "mean level": "Livello medio",
  "medium irrigation": "Stress idrico medio",
  "medium_moisture_saturation": "Saturazione di Umidità Media",
  "MELOCOTON": "PESCO",
  "MELON": "MELONE",
  "mercury": "Mercurio",
  "MERLOT": "MERLOT",
  "Metric": "Metrico",
  "microorganisms_larva": "larve di microorganismi",
  "mite_grade": "grado di acaro",
  "mite_infested": "infestato da acari",
  "mite_infested_leafs": "foglie infestate da acari",
  "moisture_status": "07.Stato di umidità",
  "molybdenum": "Molibdeno",
  "MONTONEC": "MONTONEC",
  "MONTONEGA": "MONTONEGA",
  "MOSCATELL DE ALEXANDRIA": "MOSCATELLO DI ALESSANDRIA",
  "MOSCATELL ROMA": "MOSCATELLO DI ROMA",
  "NARANJA": "ARANCIA",
  "NDRE": "NDRE",
  "NDVI": "NDVI",
  "NECATRINA": "NECATRINA",
  "NECTARINA": "NETTARINA",
  "nickel": "Nichel",
  "Nitrogen": "Azoto",
  "Nitrógeno Foliar": "Azoto Foliare",
  "nitrógeno-clorofila (ndre)": "Azoto-Clorofilla (NDRE)",
  "Nitrógeno-Clorofila (NDRE)": "Azoto-Clorofilla (NDRE)",
  "No crop or too dry": "Nessuna coltura o troppo secco",
  "NO CULTIVO": "NESSUNA COLTURA",
  "No hay datos": "Nessun dato",
  "NOGAL": "NOCE",
  "notification_to_the_farmer_high_n_index": "g. Notifica all'agricoltore: Indice alto di N",
  "Observations": "Osservazioni",
  "OLIVO": "OLIVO",
  "opogona_larva_number": "opogona - larve",
  "organic_material": "Materiale organico",
  "organic_oxidizable_carbon": "Carbonio Organico Ossidabile",
  "PANSA BLANCA": "PANSA BIANCA",
  "PANSAL": "PANSAL",
  "PARAGUAYO": "PARAGUAIANO",
  "PARELLADA": "PARELLADA",
  "PATATA": "PATATA",
  "pattern": "modello",
  "PERA": "PERA",
  "percent_disease": "Percentuale di malattia",
  "perkinsiella_345_instar_nymph": "perkinsiella - ninfe 3-4-5 stadio",
  "perkinsiella_adult": "perkinsiella - adulti",
  "perkinsiella_controller_coccinelidos": "perkinsiella - controller - coccinelle",
  "perkinsiella_controller_crysopas": "perkinsiella - controller - crisope",
  "perkinsiella_controller_spiders": "perkinsiella - controller - ragni",
  "perkinsiella_controller_tytthus_parviceps": "perkinsiella - piloti - tytthus parviceps",
  "perkinsiella_controller_zelus": "perkinsiella - piloti - zelus",
  "perkinsiella_death_adult": "perkinsiella - adulti morti",
  "perkinsiella_death_nymph": "perkinsiella - ninfe morte",
  "petiole_sampling": "i. Campionamento del picciolo",
  "ph": "Ph",
  "pheromone_trap_diatraea_adults_number": "trappola feromonica - numero adulti diatraea",
  "pheromone_trap_installation_date": "trappola feromonica - data di installazione",
  "pheromone_trap_number": "trappola feromonica - trappole",
  "pheromone_trap_other_butterfly": "trappola feromonica - altre farfalle",
  "pheromone_trap_pheromone_change": "trappola feromonica - cambio feromone",
  "phosphorus": "Fosforo",
  "PIMIENTO": "PEPERONE",
  "PINO": "PINO",
  "PINOT NOIR": "PINOT NERO",
  "PISTACHO": "PISTACCHIO",
  "Pivot": "Pivot",
  "Plague": "Peste",
  "plague_level": "09.Livello di peste",
  "Plant": "Pianta",
  "Plant Density": "Densità della pianta",
  "plantation_date": "Data di piantagione",
  "plants_per_surface_unit": "Piante per unità di superficie",
  "poda": "Potatura",
  "pol": "Pol",
  "POMELO": "POMELO",
  "POMELOS": "POMELOS",
  "potassium": "Potassio",
  "potassium_saturation": "Saturazione di Potassio",
  "powdery_mildew_afa_perc": "Oidio - AFA(%)",
  "powdery_mildew_perc_of_affected_plants": "Oidio - % di piante colpite (INCIDENZA)",
  "powdery_mildew_treatment": "Oidio - Trattamento",
  "Production": "Produzione",
  "pseudomonas_rubrilineans_stem_number": "striscia rossa - totale steli",
  "pseudomonas_rubrilineans_stem_number_affected": "striscia rossa - steli colpiti",
  "pseudomonas_rubrilineans_stem_number_with_symptoms": "striscia rossa - steli con sintomi",
  "puccinia_melanocephala_accumulated_scale": "ruggine - scala cumulativa",
  "puccinia_melanocephala_infestation": "infestazione di ruggine",
  "puccinia_melanocephala_infested_leafs": "foglie infestate dalla ruggine",
  "puccinia_melanocephala_points": "ruggine - punti",
  "puccinia_melanocephala_total_leafs": "ruggine - totale foglie",
  "puccinia_sp_incidence": "puccinia sp \"roya\" - incidenza",
  "puccinia_sp_severity": "puccinia sp \"roya\" - gravità",
  "puccinia_sp_total_infected_leafs": "puccinia sp \"roya\" - totale foglie infette",
  "PUERRO": "PORRO",
  "Quality": "Qualità",
  "QUINOA": "QUINOA",
  "RAIGRAS": "LOLIO",
  "real_leaves": "Foglie reali",
  "reform": "Riforma",
  "REMOLACHA": "BARBABIETOLA",
  "REMOLACHA AZUCARERA": "BARBABIETOLA DA ZUCCHERO",
  "resowing_surface": "Superficie di risemina",
  "result": "risultato",
  "riego": "Irrigazione",
  "rodents_infestation_percent": "Roditori - infestazione",
  "rot10roots_complete": "03.Radici marcite 10 - Complete",
  "rot10roots_nothing": "01.Radici marcite 10 - Niente",
  "rot10roots_partial": "02.Radici marcite 10 - Parziale",
  "rust_10_25_afa": "Ruggine - 10-25 % AFA",
  "rust_100_afa": "Ruggine -N- 100 % AFA. Fogliame originale completamente distrutto",
  "rust_25_35_afa": "Ruggine -J- 25-35 % AFA. 1/4 -1/3 del fogliame è gravemente colpito",
  "rust_3_10_afa": "Ruggine -I- 3-10 % AFA",
  "rust_35_50_afa": "Ruggine -K- 35-50 % AFA.  1/3 - 1/2 del fogliame è gravemente colpito",
  "rust_50_75_afa": "Ruggine -L- 50-75 % AFA. 1/2 del fogliame è gravemente colpito",
  "rust_75_90_afa": "Ruggine -M- 75-90 % AFA. Tutto il fogliame è gravemente colpito",
  "rust_healthy_plant": "Ruggine -A- Pianta sana",
  "rust_isolated_spots_on_some_leaves_1_9_perc": "Ruggine -B- Macchie isolate su alcune foglie. 1-9 (%SL)",
  "rust_isolated_spots_on_some_leaves_10_19_perc": "Ruggine -C- Macchie isolate su alcune foglie. 10-19 (%SL)",
  "rust_isolated_spots_on_some_leaves_20_29_perc": "Ruggine -D- Macchie isolate su alcune foglie. 20-29 (%SL)",
  "rust_isolated_spots_on_some_leaves_30_50_perc": "Ruggine -E- Macchie isolate su alcune foglie. 30-50 (%SL)",
  "rust_regrowth_restart": "Ruggine -O- Inizio della ricrescita. Aggiungi 0,5 punti per ogni settimana fino al raccolto",
  "rust_spots_leaves_100_300": "Ruggine -H- Foglie con 100-300 macchie",
  "rust_spots_leaves_20_100": "Ruggine -G- 20-100 macchie per foglia sulla maggior parte delle foglie",
  "rust_spots_leaves_50": "Ruggine -F- >50% SL e <20 macchie per foglia sulla maggior parte delle foglie",
  "rust_treatment": "Ruggine -p- Trattamento",
  "sac": "Sac",
  "Safety": "Sicurezza",
  "sample_color": "05.Colore del campione",
  "sand": "Sabbia",
  "SAUVIGNON BLANC": "SAUVIGNON BLANC",
  "severity": "Gravità",
  "soca_width": "04.Numero di radici",
  "sodium": "Sodio",
  "sodium_saturation": "Saturazione di Sodio",
  "Soil": "Suolo",
  "soil_moisture_status": "Stato di umidità del suolo",
  "SOJA": "SOIA",
  "sowing_failure": "Fallimento della semina",
  "sprout_dead": "germoglio morto",
  "sprout_total": "totale germogli",
  "starch": "Amido",
  "stems_evaluated": "steli valutati",
  "sulfur": "Zolfo",
  "Superficial": "Superficiale",
  "SYRAH": "SYRAH",
  "TABACO": "TABACCO",
  "TE": "TE",
  "TEA": "TORCIA",
  "TEMPRANILLO": "TEMPRANILLO",
  "TOMATE": "POMODORO",
  "tombing": "Piante sdraiate",
  "total_arsenic": "Arsenico Totale",
  "total_cadmium": "Cadmio Totale",
  "TOURIGA NACIONAL": "TOURIGA NAZIONALE",
  "TRIGO": "FRUMENTO",
  "TRIGO BLANDO": "FRUMENTO MORBIDO",
  "TRIGO DURO": "FRUMENTO DURO",
  "TRITICALE": "TRITICALE",
  "type_disease": "Tipo di malattia",
  "uneven_budding": "Germogliatura irregolare",
  "ustilago_scitaminea_infection": "ustilago scitaminea \"carbone\" - infezione",
  "ustilago_scitaminea_stem_affected": "ustilago scitaminea \"carbone\" - steli malati totali",
  "ustilago_scitaminea_stem_symptoms": "ustilago scitaminea \"carbone\" - steli con sintomi",
  "ustilago_scitaminea_stem_total": "ustilago scitaminea \"carbone\" - steli totali",
  "ustilago_scitaminea_stem_whip": "ustilago scitaminea \"carbone\" - steli flagellati",
  "UVA": "UVA",
  "UVA DE MESA": "UVA DA TAVOLA",
  "Variabilidad": "Variabilità",
  "Variabilidad (NDVI-LUVI)": "Variabilità (NDVI-LUVI)",
  "VERDEJO": "VERDEJO",
  "very high irrigation": "Altissimo stress idrico",
  "very low irrigation": "Bassissimo stress idrico",
  "VIGNE": "VIGNE",
  "Vigor Vegetativo (NDVI-Drone)": "Vigore Vegetativo (NDVI-Drone)",
  "Vigor Vegetativo (NDVI-LUVI)": "Vigore Vegetativo (NDVI-LUVI)",
  "vigor vegetativo (ndvi) - vigor alto": "Vigore vegetativo (NDVI) - Alto vigore",
  "VIÑA": "VIGNETO",
  "VIOGNIER": "VIOGNIER",
  "virgin_female_georeferenced_trap_diatraea_adult_total": "trappola femmina vergine - adulti totali diatraea",
  "virgin_female_georeferenced_trap_female_change": "trappola femmina vergine - cambio femmina",
  "virgin_female_georeferenced_trap_other_butterfly": "trappola femmina vergine - altre farfalle",
  "virgin_female_georeferenced_trap_point": "trappola femmina vergine - punto trappola",
  "virgin_female_trap_diatraea_adult_number": "trappola femmina vergine - numero adulti diatraea",
  "virgin_female_trap_female_change": "trappola femmina vergine - cambio femmina",
  "virgin_female_trap_installation_date": "trappola femmina vergine - data di installazione",
  "virgin_female_trap_number": "trappola femmina vergine - trappole",
  "virgin_female_trap_other_butterfly": "trappola femmina vergine - altre farfalle",
  "VIURA": "VIURA",
  "weed_unload": "01. Erbacce (scarico)",
  "Weeds": "Erbacce",
  "weight_100_beans": "Pesi di 100 fagioli",
  "weight_kg": "06.Peso del campione",
  "whey": "Siero",
  "without_footwear": "01.Senza Calzature",
  "without_gloves": "04.Senza Guanti",
  "without_vest": "02.Senza Gilet",
  "XARELLO": "XARELLO",
  "yellowness_mild": "Giallo - Attacco lieve (piante sciolti)",
  "yellowness_moderate": "Giallo - Attacco moderato (fila)",
  "yellowness_strong": "Giallo - Attacco forte (intera parcella)",
  "yellowness_without_symptoms": "Ingiallimento - Senza sintomi",
  "zinc": "Zinco"
}
