// prettier-ignore
export default {
  "-0.10 Very Low": "-0.10 Nagyon alacsony",
  "% Mapa alertas": "% Figyelmeztető térkép",
  "% Media Fosforo": "% Átlagos foszfor",
  "% Media Nitrogeno": "% Átlagos nitrogén",
  "% Media Potasio": "% Átlagos kálium",
  "% resiembra": "% Újraültetés",
  "0.10 Low": "0.10 Alacsony",
  "0.20 Very Low": "0.20 Nagyon alacsony",
  "0.25 Very Low": "0.25 Nagyon alacsony",
  "0.30 Low": "0.30 Alacsony",
  "0.30 Medium": "0.30 Közepes",
  "0.40  Low": "0.40  Alacsony",
  "0.40 Medium": "0.40 Közepes",
  "0.45 High": "0.45 Magas",
  "0.50 High": "0.50 Magas",
  "0.55 Medium": "0.55 Közepes",
  "0.65 Very High": "0.65 Nagyon magas",
  "0.70 High": "0.70 Magas",
  "0.85 Very High": "0.85 Nagyon magas",
  "abonado de cobertera": "Takarótrágyázás",
  "abonado de fondo": "Alaptrágyázás",
  "abonado de fondo ": "Alaptrágyázás",
  "abonado dosificado": "Adagolt trágyázás",
  "abonado tasa fija": "Fix díjas trágyázás",
  "abonado zonificado": "Zónázott trágyázás",
  "accumulated vegetative growth": "Felhalmozódott vegetatív növekedés",
  "Acre": "Acre",
  "active": "aktív",
  "Add at least one recipient (+)": "Adj hozzá legalább egy címzettet (+)",
  "Add Field Button": "Mező hozzáadása gomb",
  "Add Field Tutorial": "Mező hozzáadása útmutató",
  "add location": "hely hozzáadása",
  "Add new sample": "Új minta hozzáadása",
  "add photo": "fénykép hozzáadása",
  "added to pending": "hozzáadva a függőben lévőhöz",
  "Advanced": "Haladó",
  "advanced season parameters": "haladó szezonparaméterek",
  "afección": "Betegség",
  "afección extensivo": "Kiterjedt betegség",
  "Affected Surface": "Érintett felület",
  "After filling the form, you can draw the field": "Az űrlap kitöltése után rajzolhatja a mezőt",
  "agrodelivery": "Agrodelivery",
  "all": "mind",
  "ALMENDRA": "MANDULA",
  "alto estrés hídrico": "Magas vízstressz",
  "alto pot prod": "Magas termelési potenciál",
  "ambiente": "Környezet",
  "An error occured": "Hiba történt",
  "An error occurred from the server. Please try again": "Hiba történt a szervertől. Kérjük, próbálja újra",
  "And that's it!": "És ennyi!",
  "anexo información": "Információ melléklete",
  "Applied filters": "Alkalmazott szűrők",
  "Apply": "Alkalmaz",
  "apply date filter": "dátumszűrő alkalmazása",
  "Apply selection": "Választás alkalmazása",
  "Are you sure to close the editor?": "Biztosan bezárja a szerkesztőt?",
  "Are You sure?": "Biztos vagy benne?",
  "area": "terület",
  "Area": "Terület",
  "ATR": "Teljes visszanyerhető cukor",
  "author": "Szerző",
  "Automatic harvest": "Automatikus betakarítás",
  "auxiliary files": "Segédállományok",
  "back": "vissza",
  "bajo estrés hídrico": "Alacsony vízstressz",
  "bajo pot prod": "Alacsony termelési potenciál",
  "Base temperature": "Alap hőmérséklet",
  "baseLayer": "Alapréteg",
  "Basic": "Alap",
  "basic season parameters": "alap szezonparaméterek",
  "bipolaris_sacchari_g1": "bipolaris sacchari \"szemfolt\" - g1",
  "bipolaris_sacchari_g2": "bipolaris sacchari \"szemfolt\" - g2",
  "bipolaris_sacchari_g3": "bipolaris sacchari \"szemfolt\" - g3",
  "bipolaris_sacchari_g4": "bipolaris sacchari \"szemfolt\" - g4",
  "bipolaris_sacchari_g5": "bipolaris sacchari \"szemfolt\" - g5",
  "Both first and last points of the slice line must be outside of the polygon/circle": "A szeletelő vonal első és utolsó pontjának a poligon/körön kívül kell lennie",
  "Buried": "Eltemetett",
  "calendar": "Naptár",
  "calibre": "Kaliber",
  "camera error message": "Úgy tűnik, hogy engedélyezési probléma van a kamerával",
  "camera_unavailable": "kamera nem elérhető",
  "Can't modify field with active planet subscription": "Nem lehet módosítani a mezőt aktív bolygó előfizetéssel",
  "cancel": "mégse",
  "cane_num": "Nádszám",
  "cantidad": "Mennyiség",
  "capa base": "Alapréteg",
  "Capture screenshot": "Képernyőkép rögzítése",
  "Change opacity": "Átlátszóság megváltoztatása",
  "Choose your layer": "Válassza ki a rétegét",
  "Choose your tool": "Válassza ki az eszközét",
  "Circle Drawing": "Kör rajzolása",
  "class": "Osztály",
  "Class values must be numerical": "Az osztályértékeknek numerikusnak kell lenniük",
  "classes": "Osztályok",
  "classes number": " Osztályok száma",
  "clear": "Töröl",
  "Clear all": "Mindent töröl",
  "clear form": "űrlap törlése",
  "clear selected fields": "Kijelölt mezők törlése",
  "Clicking this button, you can toggle the visibility of the selected layer.": "Ezen gombra kattintva váltogathatja a kiválasztott réteg láthatóságát.",
  "clientDeviation": "Ügyfél eltérése",
  "Close": "Bezár",
  "Close editor": "Szerkesztő bezárása",
  "Close selection": "Választás bezárása",
  "Close the temporal Comparison": "Időbeli összehasonlítás bezárása",
  "close_selected": "Kiválasztott bezárása",
  "closed": "zárva",
  "cloud": "Felhő",
  "Cloud Coverage": "Felhőfedettség",
  "comment": "megjegyzés",
  "comp. temporal target": "Időbeli cél összehasonlítása",
  "comp. temporal variabilidad": "Időbeli változékonyság összehasonlítása",
  "comparación de conteo": "Számlálás összehasonlítása (%)",
  "comparación de estado hídrico": "Vízállapot összehasonlítása (%)",
  "comparación de estado hídrico drone": "Vízállapot összehasonlítása - Drón (%)",
  "comparación de estado hídrico sat": "Vízállapot összehasonlítása - MŰHOLD (%)",
  "comparación de estimación de producción": "Termelésbecslés összehasonlítása (%)",
  "comparación de fósforo": "Foszfor összehasonlítása (%)",
  "comparación de mapa de alertas": "Figyelmeztető térkép összehasonlítása (%)",
  "comparación de mapa de faltas": "Hiánytérkép összehasonlítása (%)",
  "comparación de media de conteo": "Számlálás átlagos összehasonlítása (%)",
  "comparación de media de estado hídrico": "Vízállapot átlagos összehasonlítása (%)",
  "comparación de media de fósforo": "Foszfor átlagos összehasonlítása (%)",
  "comparación de media de mapa de alertas": "Figyelmeztető térkép átlagos összehasonlítása (%)",
  "comparación de media de mapa de faltas": "Hiánytérkép átlagos összehasonlítása (%)",
  "comparación de media de nitrógeno": "Nitrogén átlagos összehasonlítása (%)",
  "comparación de media de potasio": "Kálium átlagos összehasonlítása (%)",
  "comparación de media de producción total": "Teljes termelés átlagos összehasonlítása (%)",
  "comparación de media de rendimiento graso": "Zsírteljesítmény átlagos összehasonlítása (%)",
  "comparación de medias de estimación de producción": "Termelésbecslés átlagos összehasonlítása (%)",
  "comparación de medias de ndvi": "NDVI átlagos összehasonlítása (%)",
  "comparación de medias de proteína": "Fehérje átlagos összehasonlítása (%)",
  "comparación de medias de variabilidad": "Változékonyság átlagos összehasonlítása (%)",
  "comparación de ndvi": "NDVI összehasonlítása (%)",
  "comparación de nitrógeno": "Nitrogén összehasonlítása (%)",
  "comparación de nitrógeno relativo": "Relatív nitrogén összehasonlítása(%)",
  "comparación de potasio": "Kálium összehasonlítása (%)",
  "comparación de producción total": "Teljes termelés összehasonlítása (%)",
  "comparación de proteína": "Fehérje összehasonlítása (%)",
  "comparación de rendimiento graso": "Zsírteljesítmény összehasonlítása (%)",
  "comparación de sacarosa": "Szacharóz összehasonlítása",
  "comparación de variabilidad": "Változékonyság összehasonlítása (%)",
  "confirm": "Megerősít",
  "Congratulations! Your field has been created.": "Gratulálunk! A meződ létrejött.",
  "Congratulations! Your field has been edited.": "Gratulálunk! A meződ módosítva lett.",
  "Contact": "Kapcsolat",
  "Contenido de sacarosa ( % )": "Szacharóztartalom ( % )",
  "conteo - pdf rgb": "számlálás - pdf rgb",
  "conteo - shps": "számlálás - shps",
  "conteo de individuos": "Egyének számlálása",
  "conteo lechugas": "Saláta számlálás",
  "continue": "folytatás",
  "CORN": "KUKORICA",
  "correction": "Korrekció",
  "Corte nitrogeno": "Nitrogén vágás",
  "cost": "Költség",
  "count (units)": "Számlálás (Egységek)",
  "Counting": "Számlálás",
  "create": "létrehoz",
  "Create New Field": "Új mező létrehozása",
  "CREATE_RANGES": "A kép hisztogramja alapján hozzon létre tartományokat, minden tartományban hasonló adatok százalékával.",
  "created": "Létrehozva",
  "crecimiento cultivo": "Növényi fejlettség",
  "Crop": "Növény",
  "Crop type": "Növénytípus",
  "CROP_INSPECTION": "Növényi ellenőrzés",
  "cultive": "Termesztés",
  "Cultivo": "Növény",
  "current season": "jelenlegi szezon",
  "current selection could take a moment to process": "A jelenlegi dátumtartomány növekedett, a kérés feldolgozása egy pillanatig tarthat",
  "Customer": "Ügyfél",
  "customerProductionEstimation": "Ügyfél termelésbecslése",
  "customers": "Ügyfél",
  "cut": "Vágás",
  "Cutter": "Vágó",
  "dashboard": "Irányítópult",
  "date": "Dátum",
  "DD/MM/YYYY": "NN/HH/ÉÉÉÉ",
  "delete": "töröl",
  "Delete": "Töröl",
  "Delete downloaded report": "Letöltött jelentés törlése",
  "delete downloaded sample": "letöltött minta törlése",
  "Delete field": "Mező törlése",
  "delete layers?": "rétegek törlése?",
  "delete sample": "minta törlése",
  "delete sample edition": "minta kiadás törlése",
  "DeleteField": "A mező törlődött",
  "Deliverables tutorial": "Kézbesítések útmutató",
  "Description": "Leírás",
  "Deselect": "Kijelölés megszüntetése",
  "deselect all": "Mindet kijelöli",
  "deviation": "Eltérés",
  "Did you forget your password ?": "Elfelejtette a jelszavát?",
  "disponible en visor": "Elérhető a nézőben",
  "do you want to delete": "szeretnéd törölni",
  "documentation": "Dokumentáció",
  "don't show again": "ne mutassa újra",
  "Done": "Kész",
  "dosificación genérica": "Általános adagolás",
  "Dosis Media Cliente": "Ügyfél átlagos adagja",
  "Dosis media Propuesta": "Javasolt átlagos adag",
  "dosis riego": "Öntözési adag",
  "Download": "Letöltés",
  "download failed": "A következő letöltések nem sikerültek",
  "Download list": "Letöltési lista",
  "Download selected fields samples": "Kiválasztott mezők mintáinak letöltése",
  "download selected geometries": "Kiválasztott geometriák letöltése",
  "download values": "Értékek letöltése",
  "downloaded": "letöltve",
  "downloading layers": "rétegek letöltése",
  "downloads": "Letöltések",
  "Drag and drop your layers to arrange them in the list.": "Húzza és dobja a rétegeket a listában való elrendezéshez",
  "Drag Fields": "Mezők húzása",
  "Drag your layers": "Húzza a rétegeket",
  "Draw Field": "Mező rajzolása",
  "dron": "Drón",
  "Dron sectores": "Drón szektorok",
  "drop here": "ejtsd ide",
  "Dry": "Száraz",
  "dry matter": "Szárazanyag",
  "dryMatterField": "Szárazanyag mező",
  "dryMatterLaboratory": "Szárazanyag laboratórium",
  "dsm": "DSM",
  "earliest harvest date": "Legkorábbi betakarítási dátum",
  "edit": "szerkesztés",
  "Edit Field": "Mező szerkesztése",
  "Edit geometry": "Geometria szerkesztése",
  "edit range": "Tartomány szerkesztése",
  "Edit values": "Értékek szerkesztése",
  "ej 100": "példa 100",
  "End Date": "Befejezés dátuma",
  "Equal Interval": "Egyenlő Intervallum",
  "Equidistant ranges": "Egyenlő távolságra lévő tartományok",
  "error fetching classes": "Az ég túl felhős a kiválasztott napon. Állítsd be a naptár felhőszűrőjét, hogy találj egy derűsebb napot.",
  "estado hídrico": "Vízállapot",
  "estado hídrico (ndmi)": "Hidrikus állapot (NDMI)",
  "estado hidrico sat": "NDRE",
  "estimación": "Becslés",
  "estimación producción": "Termelésbecslés",
  "Estimacion produccion ( Tn / Ha )": "Termelésbecslés ( Tn / Ha )",
  "estimación producción cualitativa": "Kvalitatív termelésbecslés",
  "estimación proteína": "Fehérje becslés",
  "Estimacion proteina ( % )": "Fehérje becslés ( % )",
  "estimación sacarosa": "Szacharóz becslés",
  "estimación sacarosa ": "Szacharóz becslés",
  "estimación sacarosa cualitativa": "Kvalitatív szacharóz becslés",
  "Estimacion Total ( Kg )": "Teljes becslés ( Kg )",
  "estimation gauging": "Becslés mérés",
  "estrategia de dosificacion": "Adagolási stratégia",
  "estrés hídrico (lws)": "Vízstressz (LWS)",
  "evolución de NDVI": "NDVI fejlődés",
  "evolution": "Evolúció",
  "Ex : comment": "Példa: megjegyzés",
  "Ex : field R02": "Példa: mező R02",
  "Ex : Tresbolillos, row, tree": "Példa: Tresbolillos, sor, fa",
  "expected_final_weight": "Várható végső súly",
  "external_id": "Külső azonosító",
  "extra parameters": "extra paraméterek",
  "falhas carreadores": "Hordozók hibái",
  "falhas internas": "Belső hibák",
  "falhas terraços": "Teraszok hibái",
  "falla": "Hézag",
  "fat performance": "Zsírteljesítmény",
  "faults map": "Hibatérkép",
  "fdn": "Fdn",
  "Fecha de vuelo": "Repülés dátuma",
  "Fecha Plantacion": "Ültetés dátuma",
  "Feddan": "Feddan",
  "fertilization strategy": "Trágyázási stratégia",
  "Fertilizer recommendation": "Trágyázási ajánlás",
  "field": "mező",
  "Field Name": "Mező neve",
  "Field without geometry": "Geometria nélküli mező",
  "FIELD_FILTER": "Mezőszűrők",
  "field_name": "Mező neve",
  "FIELD_TEMPORAL_EVOLUTION": "Időbeli fejlődés",
  "fields": "Mezők",
  "Fields without geometries": "Geometriák nélküli mezők",
  "File cannot be generated due to high amounts of clouds. Please select another date": "A fájl nem hozható létre a nagy mennyiségű felhő miatt. Kérjük, válasszon másik dátumot",
  "File generator": "Fájlgenerátor",
  "File Generator": "Fájlgenerátor",
  "File name": "Fájlnév",
  "file type": "Fájltípus",
  "Fill plantation date of current field, showing one year of historic data": "Töltse ki a jelenlegi mező ültetési dátumát, egy évnyi történelmi adatot megjelenítve",
  "Fill the field form": "Töltse ki a mező űrlapot",
  "Fill the form to apply for all our services": "Töltse ki az űrlapot, hogy igénybe vehesse az összes szolgáltatásunkat",
  "filtered field": "Szűrt mező",
  "filtered fields": "Szűrt mezők",
  "Filters": "Szűrők",
  "final_weight": "Végső súly",
  "fitopatógenos": "Fitopatogének",
  "Flight Slider": "Repülés csúszka",
  "FLIGHT_FILTER": "Időbeli összehasonlítás",
  "flights type": "Repülések típusa",
  "fmsavi": "FMSAVI",
  "for everyone": "mindenkinek",
  "for me": "nekem",
  "forecasts": "Előrejelzések",
  "formulario de abonado": "Trágyázási űrlap",
  "formulario de dosis": "Adagolási űrlap",
  "fósforo foliar": "Levél foszfor",
  "fr": "Fr",
  "Fraction": "Tört",
  "free": "ingyenes",
  "from": "Tól től",
  "fruits_100_weight": "100 gyümölcs súlya",
  "fruits_actual1_weight": "Tényleges gyümölcssúly",
  "Fuente de informacion": "Információ forrása",
  "gallery": "Galéria",
  "gauging date": "Mintavételi dátum",
  "generation error": "Generálási hiba",
  "geometry": "Geometria",
  "get a free trial": "Szerezzen ingyenes próbát",
  "Get back to editor": "Vissza a szerkesztőhöz",
  "global files": "Globális fájlok letöltése",
  "global layer": "Globális réteg",
  "gm_cut_number": "Vágásszám",
  "gm_data_generation": "Műholdas dátum (hetek)",
  "gm_day_cycle": "Termesztési napok",
  "gm_harvest_estimated_date": "Becsült betakarítási dátum (hetek)",
  "gm_hemav_atr_estimation": "TRS előrejelzés",
  "gm_hemav_pol_estimation": "Polarizáció előrejelzés",
  "gm_hemav_prodph_estimation": "Hozam előrejelzés",
  "gm_hemav_realprodph_lastseason": "Az előző szezon tényleges hozama",
  "gm_hemav_sac_estimation": "Szacharóz előrejelzés",
  "gm_luvi_date_generation": "Műholdas dátum - LUVI (hetek)",
  "gm_luvi_day_cycle": "Termesztési napok (NDVI-LUVI)",
  "gm_luvi_mean": "Vegetatív életerő (NDVI-LUVI)",
  "gm_luvi_ndvi_std": "Változékonyság (NDVI-LUVI)",
  "gm_lws_mean": "Vízstressz (LWS)",
  "gm_ndmi_date_generation": "Műholdas dátum - NDMI (hetek)",
  "gm_ndmi_day_cycle": "Termesztési napok (NDMI)",
  "gm_ndre_date_generation": "Műholdas dátum - NDRE (hetek)",
  "gm_ndre_day_cycle": "Termesztési napok (NDRE)",
  "gm_season_label": "Szezon",
  "gm_sub_type_name": "Fajta",
  "gm_type_name": "Növény",
  "go to field": "Menj a mezőre",
  "grid": "Rács",
  "harvest date": "Betakarítási dátum",
  "harvest planning": "Betakarítási tervezés",
  "HARVEST_PLANNING_TOOL": "Betakarítási tervezési eszköz",
  "HARVEST_PREDICTION": "Betakarítási előrejelzés",
  "Hectare": "Hektár",
  "Hemav Default": "Alapértelmezett Hemav",
  "HEMAV Default": "HEMAV Alapértelmezett",
  "Here, you can play with the preview of your different layers.": "Itt játszhat a különböző rétegek előnézetével.",
  "hide": "elrejt",
  "Hide All Layers": "Összes réteg elrejtése",
  "hide filter list": "Szűrőlista elrejtése",
  "Hide Global Layer": "Globális réteg elrejtése",
  "Hide Measure Tools": "Mérőeszközök elrejtése",
  "high": "Magas",
  "high level": "Magas szint",
  "high need": "Nagy szükség",
  "higher clay content": "Magasabb agyagtartalom",
  "humedad de suelo - beta (%)": "Talajnedvesség - béta (%)",
  "humedad vegetativa (ndmi)": "Vegetatív nedvesség (NDMI)",
  "Hybrid View": "Hibrid nézet",
  "hydric status": "Hidrikus állapot - NDMI",
  "hydric status drone": "Hidrikus állapot - Drón",
  "If foliar sampler haven't been made, the date you must enter is the current": "Ha a levélmintavétel nem történt meg, a megadandó dátum a jelenlegi",
  "If you have two or more flights, you can compare them temporally using this tool.": "Ha két vagy több repülése van, időben összehasonlíthatja őket ezzel az eszközzel.",
  "If you have undone an action, you can press this button to redo it.": "Ha visszavont egy műveletet, megnyomhatja ezt a gombot az újra elvégzéséhez.",
  "image": "kép",
  "Impacto estimado": "Becsült hatás",
  "In this tab, you can download the generated PDFs.": "Ezen a lapon letöltheti a generált PDF-eket.",
  "individuals_num": "Egyének száma",
  "infection": "Fertőzés",
  "info": "Info",
  "Information": "Információ",
  "informe": "Jelentés",
  "integración con maquinaria": "Integráció a gépekkel",
  "Invalid credentials": "Érvénytelen hitelesítő adatok",
  "invalid date": "érvénytelen dátum",
  "Invalid email": "Érvénytelen e-mail",
  "invest": "Befektet",
  "Irrigation recommendation": "Öntözési ajánlás",
  "Irrigation type": "Öntözés típusa",
  "It is commonly adjusted to establish the amount of fertilizer or seeds to be applied per unit area": "Gyakran módosítják, hogy meghatározzák a műtrágya vagy a vetőmag mennyiségét, amelyet egységnyi területre kell kijuttatni.",
  "item_num": "Tételszám",
  "Keep selection": "Választás megtartása",
  "label": "01.Címke",
  "Label": "Címke",
  "languaje": "Nyelv",
  "last sample": "Utolsó minta",
  "lat": "Szélesség",
  "layers": "Rétegek",
  "layers estrés hídrico (lws)": "Rétegek vízstressz (LWS)",
  "layers status": "Rétegek állapota",
  "Legal limitation": "Jogi korlátozás",
  "leptosphaeria_sacchari_g1": "leptosphaeria sacchari \"gyűrűfolt\" - g1",
  "leptosphaeria_sacchari_g2": "leptosphaeria sacchari \"gyűrűfolt\" - g2",
  "leptosphaeria_sacchari_g3": "leptosphaeria sacchari \"gyűrűfolt\" - g3",
  "leptosphaeria_sacchari_g4": "leptosphaeria sacchari \"gyűrűfolt\" - g4",
  "leptosphaeria_sacchari_g5": "leptosphaeria sacchari \"gyűrűfolt\" - g5",
  "letter only": "Csak betű",
  "linear meters": "Lineáris méterek",
  "líneas de cultivo": "Termesztési sorok",
  "list": "Lista",
  "loading": "Betöltés",
  "location": "Helyszín",
  "Log in": "Bejelentkezés",
  "logout": "Kijelentkezés",
  "lon": "Hosszúság",
  "looks like its taking too long": "úgy tűnik, túl sokáig tart",
  "low": "Alacsony",
  "low level": "Alacsony szint",
  "low need": "Alacsony szükséglet",
  "lower clay content": "Alacsonyabb agyagtartalom",
  "LUVI variability": "LUVI változékonyság",
  "Machines Type": "Géptípusok",
  "main_macronutrient": "Fő makrotápanyag",
  "malas hierbas": "Gyomok",
  "malas hierbas - hoja estrecha": "Gyomok - Keskenylevelű",
  "malas hierbas - hoja larga": "Gyomok - Széleslevelű",
  "malas hierbas - zonificado": "Gyomok - Zónázott",
  "malas hierbas shps": "Gyomok SHP-k",
  "malas hierbas shps - hoja estrecha": "Gyomok SHP-k - Keskenylevelű",
  "malas hierbas shps - hoja larga": "Gyomok SHP-k - Széleslevelű",
  "malas hierbas shps - zonificado": "Gyomok SHP-k - Zónázott",
  "maleza": "Gyomok",
  "Manage report": "Jelentés kezelése",
  "max 2 years": "Max 2 év",
  "max comparable fields": "Maximálisan összehasonlítható mezők",
  "maximo de variacion": "Maximális változás",
  "máximo vigor dos meses (ndvi)": "Maximum vigor 2 hónap (NDVI)",
  "máximo vigor un año (ndvi)": "Maximum vigor 1 év (NDVI)",
  "Maximun cloud coverage": "Maximális felhőfedettség",
  "mean": "Átlag",
  "Measures tool tutorial": "Mérőeszközök útmutató",
  "Measures tools": "Mérőeszközök",
  "media de faltas": "Hiányok átlaga",
  "medias": "Média",
  "medio pot prod": "Közepes termelési potenciál",
  "medium": "Közepes",
  "medium clay content": "Közepes agyagtartalom",
  "medium level": "Közepes szint",
  "medium_weight_bigSize": "Nagy gyümölcsök átlagos súlya",
  "medium_weight_mediumSize": "Közepes gyümölcsök átlagos súlya",
  "medium_weight_smallSize": "Kis gyümölcsök átlagos súlya",
  "Mensual limit": "Havi limit",
  "Message": "Üzenet",
  "METEOROLOGICAL_STATISTICS": "Meteorológiai statisztikák",
  "mode": "Mód",
  "model type": "Modelltípus",
  "Move the slider": "Mozgassa a csúszkát",
  "msavi": "MSAVI",
  "N_BUNCHES": "Fürtök száma",
  "Name": "Név",
  "nameless field": "Névtelen mező",
  "Natural Breaks (Jenks)": "Természetes Szakadékok (Jenks)",
  "ndre": "NDRE",
  "ndvi": "NDVI",
  "ndvi real": "NDVI valós",
  "ndvi-curvas de nivel": "NDVI-Szintvonalak",
  "Network request failed": "Hálózati kérés sikertelen",
  "new sample": "új minta",
  "New sample created.": "Új minta létrehozva.",
  "NITRO": "Nitrogén",
  "nitrogen chlorophyll (ndre)": "Nitrogén-klorofill (NDRE)",
  "nitrogen_assimilable": "Asszimilálható nitrogén",
  "nitrógeno foliar": "Levél nitrogén",
  "Nivel de estres": "Stressz szint",
  "no data": "Nincs adat",
  "no data available": "Nincs elérhető adat",
  "no data with current selection": "Nincs adat a jelenlegi kiválasztással",
  "No fields selected": "Nincsenek kiválasztott mezők",
  "no fields to clear": "Nincsenek törölendő mezők",
  "NO_DELIVERABLES_IMAGES_MESSAGE": "Nincsenek képek erre a napra.",
  "Not a user yet ?": "Még nem felhasználó?",
  "not enough data to display this chart": "Nincs elég adat a diagram megjelenítéséhez",
  "Not supported by browser": "A böngésző nem támogatja",
  "notes": "Jegyzetek",
  "number only": "Csak szám",
  "Numero vuelo": "Repülésszám",
  "observations": "Megfigyelések",
  "offline access": "Offline hozzáférés",
  "offline mode": "Offline mód",
  "oil_yield": "Olajhozam",
  "ok": "Rendben",
  "Once you are done, click this button to save the changes.": "Ha befejezte, kattintson erre a gombra a változtatások mentéséhez",
  "open": "nyitott",
  "Open": "Nyit",
  "Open selection": "Nyitott választás",
  "open temporal comparison": "Időbeli összehasonlítás megnyitása",
  "open_selected": "Kiválasztott megnyitása",
  "optimize": "Optimalizál",
  "or": "vagy",
  "Order by a - z": "Rendezés a - z szerint",
  "Order by distance to the field": "Rendezés a mező távolsága szerint",
  "Order by z - a": "Rendezés z - a szerint",
  "ourDeviation": "Hemav eltérése",
  "ourProductionEstimation": "Hemav termelésbecslése",
  "Parcela": "Parcella",
  "Password": "Jelszó",
  "pb": "Pb",
  "pcd": "PCD",
  "pcd arbol": "PCD fa",
  "pcd interpolado": "Interpolált PCD",
  "pcd row": "PCD sor",
  "pdf": "PDF",
  "pending": "függőben",
  "phospohorus": "Foszfor",
  "phosporus": "Foszfor (ppm)",
  "photo": "fénykép",
  "photoid": "Fénykép azonosító",
  "photoId": "Fénykép",
  "picture_path": "Képútvonal",
  "plague": "Fertőzés",
  "planned dosage": "Tervezett adagolás",
  "Plant emitters": "Növényi kibocsátók",
  "plantation date": "Ültetési dátum",
  "Plantation date": "Ültetési dátum",
  "plantation_end_date_range": "Ültetési dátumtartomány vég",
  "plantation_start_date_range": "Ültetési dátumtartomány kezdet",
  "Plantations": "Ültetvények",
  "plants": "Növények",
  "Plants distance": "Növények távolsága",
  "plants_density_range": "Növényi sűrűségtartomány",
  "Please wait or order one to have data to visualized.": "Programozzon egy repülést, hogy kézbesíthető legyen az adott mezőhöz",
  "Please, choose a field variety": "Kérjük, válasszon egy mezőfajtát",
  "Please, choose the field owner": "Kérjük, válassza ki a mező tulajdonosát",
  "plural selected": "kiválasztott",
  "POL": "Polarizáció",
  "Polygon drawing": "Poligon rajzolása",
  "posible falla": "Potenciális hézag",
  "posible maleza": "Potenciális gyomok",
  "positive number only": "Csak pozitív szám",
  "positive number only and minor 100": "Csak pozitív szám és kisebb mint 100",
  "pot. horticolar 90": "pot. horticolar 90",
  "pot. otros cultivos 150": "pot. otros cultivos 150",
  "pot. otros cultivos 90": "pot. otros cultivos 90",
  "potasio foliar": "Levél kálium",
  "potencial productivo histórico (ndvi)": "Hisztórikus termés potenciál",
  "precio": "Ár",
  "PRED_TECH": "PredTech",
  "Predefined ranges by HEMAV which are generally appropriate for the majority of fields": "A HEMAV által előre meghatározott tartományok általában megfelelőek a mezők többségére.",
  "prediction": "Előrejelzés",
  "preprocessing": "Előfeldolgozás",
  "Press this button to add a new field.": "Nyomja meg ezt a gombot, hogy új mezőt adjon hozzá.",
  "Pressing this button lets you go back to the field view. ": "Ezen gomb megnyomásával visszatérhet a mező nézetéhez.",
  "Preview": "Előnézet",
  "price Kg": "Ár kg-onként",
  "primer vuelo": "Első repülés",
  "PROD": "Hozam felületi egységenként",
  "producción esperada": "Várható termelés",
  "product Kg": "Termék kg",
  "product Kg is mandatory": "A termék kg kötelező",
  "Production estimate": "Termelésbecslés",
  "production estimate (units)": "Termelésbecslés (Kg/ha)",
  "profile": "Profil",
  "protein": "Fehérje",
  "puccinia_sp_g1": "puccinia sp \"rozsda\" - g1",
  "puccinia_sp_g2": "puccinia sp \"rozsda\" - g2",
  "puccinia_sp_g3": "puccinia sp \"rozsda\" - g3",
  "puccinia_sp_g4": "puccinia sp \"rozsda\" - g4",
  "puccinia_sp_g5": "puccinia sp \"rozsda\" - g5",
  "Quick start with Azure": "Gyors kezdés az Azure-val",
  "Random fertilization": "Véletlenszerű trágyázás",
  "Random seedling": "Véletlenszerű vetés",
  "Range": "Tartomány",
  "Ranges adjusted to the index": "Indexhez igazított tartományok",
  "Ranges of the same size will be generated according to the minimum and maximum values of the image. In cases where the image is very homogeneous, it is likely that no differentiation will be seen.": "Azonos méretű tartományok fognak létrejönni a kép minimális és maximális értékei alapján. Azokban az esetekben, amikor a kép nagyon homogén, valószínű, hogy nem lesz látható megkülönböztetés.",
  "realProduction": "Valós termelés",
  "Recipients": "Címzettek",
  "recomendación abonado dosificado": "Adagolt trágyázási javaslat",
  "recomendación abonado zonificado": "Zónázott trágyázási ajánlás",
  "Recomendacion de riego": "Öntözési tanács",
  "recomendación riego": "Öntözési ajánlás",
  "Redo Action": "Művelet újra",
  "Related date": "Kapcsolódó dátum",
  "relative nitrogen": "Relatív nitrogén",
  "Rendimiento graso ( % )": "Zsírteljesítmény ( % )",
  "report": "Jelentés",
  "Report sent correctly": "A jelentés helyesen elküldve",
  "report type": "Típus",
  "Report type": "Jelentéstípus",
  "report_type_action_description": "Javaslatok vagy alkalmazandó intézkedések",
  "report_type_annotation_description": "Diagnosztika: Elemzés a mezőben",
  "report_type_inspection_description": "Elemzés a RÉTEGEKBŐL (lezárva az elemzés után)",
  "report_type_result_description": "A megoldás vagy kezelés alkalmazásának eredményeinek elemzése.",
  "reporte agronómico": "Agronómiai jelentés",
  "Reported by": "Jelentette",
  "Reported fields": "Jelentett mezők",
  "reports manager": "Jelentések",
  "required": "kötelező",
  "Required": "Kötelező",
  "Requires 3 characters minimun": "Minimum 3 karakter szükséges",
  "Reset": "Visszaállítás",
  "resiembra": "Újraültetés",
  "resolution": "SHP felbontás (m)",
  "resolution (only SHP)": "Felbontás (csak SHP)",
  "retención de agua": "Vízvisszatartás",
  "retry": "Újrapróbál",
  "rgb": "RGB",
  "richness": "Gazdagság",
  "riego zonificado": "Zónázott öntözés",
  "riesgo malherbología": "Gyomkockázat",
  "Ruler": "Vonalzó",
  "SAC": "Szacharóz felületi egységenként",
  "saccharose estimate": "Szacharóz becslés",
  "sample": "Minta",
  "sample type": "típus",
  "Sample was deleted.": "A minta törölve lett.",
  "Sample was successfully modified.": "A minta sikeresen módosítva lett.",
  "sample_creation_date": "Létrehozás dátuma",
  "samples": "Minták",
  "satélite": "Műhold",
  "Satellite button tutorial": "Műholdas gomb útmutató",
  "Satellite View": "Műholdas nézet",
  "save": "Mentés",
  "Save field": "Mező mentése",
  "save_": "mentés",
  "search": "Keresés",
  "Search": "Keresés",
  "season": "Évszak",
  "Season label": "Szezon címke",
  "season name": "szezon neve",
  "SEASON_EVOLUTION": "Szezon fejlődése",
  "Sector": "Szektor",
  "sectores target": "Célszektorok",
  "See? Now, clicking the button again lets you go back to the satellite view.": "Látja? Most, ha újra rákattint a gombra, visszatérhet a műholdas nézethez.",
  "seguimiento_cultivo.xlsx": "seguimiento_cultivo.xlsx",
  "segundo vuelo": "Második repülés",
  "Select": "Kiválaszt",
  "select a crop and sample": "Válasszon egy növényt és mintát",
  "select all": "Mindet kiválaszt",
  "Select all": "Mindet kiválaszt",
  "select at least one field": "Válasszon ki legalább egy mezőt",
  "select fields by": "Mezők kiválasztása",
  "select fields by variability range": "mezők kiválasztása változékonysági tartomány alapján",
  "select fields to open temporal comparison": "Válassza ki a mezőket az időbeli összehasonlítás megnyitásához",
  "select one": "Válasszon egyet",
  "select one customer please": "Kérjük, válasszon egy ügyfelet",
  "select one subtype please": "Kérjük, válasszon egy altípust",
  "select one type please": "Kérjük, válasszon egy növénytípust",
  "select sample base layer and date": "Válassza ki a minta alaprétegét és dátumát",
  "select sample location": "Válassza ki a mintavételi helyet",
  "select season": "Válassza ki az évszakot",
  "selected": "kiválasztott",
  "Selected fields names": "Kiválasztott mezők",
  "Selected fields samples": "Kiválasztott mezők mintái",
  "Selection includes too much information, try selecting a shorter date range": "A kiválasztás túl sok információt tartalmaz, próbáljon meg rövidebb dátumtartományt választani",
  "Send": "Küld",
  "Send report": "Jelentés küldése",
  "settings": "Beállítások",
  "Share fields report": "Mezők jelentésének megosztása",
  "show all": "mindet mutat",
  "Show All Layers": "Összes réteg megjelenítése",
  "Show current season only": "Csak a jelenlegi szezon megjelenítése",
  "show fields with no data": "Adatok nélküli mezők megjelenítése",
  "show filter list": "Szűrőlista megjelenítése",
  "Show Global Layer": "Globális réteg megjelenítése",
  "Show Measure Tools": "Mérőeszközök megjelenítése",
  "shp-tooltip": "Ezeket a számokat fel lehet módosítani az használati igényeknek megfelelően, és minden geometriai osztályhoz társítva vannak az exportált SHP attribútumtáblában. Általában úgy állítják be, hogy meghatározzák a műtrágya vagy mag mennyiségét, amelyet egy egységnyi területre kell alkalmazni.",
  "singular selected": "kiválasztott",
  "Slice tool only works on Polygons and Circle": "A szeletelő eszköz csak poligonokon és körön működik",
  "Slider Tool": "Csúszka eszköz",
  "soil": "Talaj",
  "soil_type": "Talajtípus",
  "sólo para la generación de SHP": "Csak SHP generáláshoz",
  "Sort": "Rendezés",
  "sort by date": "rendezés dátum szerint",
  "Start date": "Kezdés dátuma",
  "static layers": "Statikus rétegek",
  "Statistics": "Statisztikák",
  "status": "Állapot",
  "stem_num": "Szár száma",
  "Streets distance": "Utcák távolsága",
  "Subtype": "Altípus",
  "Suc/Ha": "Suc/Ha",
  "sum_of_bases": "Bázisok összege",
  "Superficie ( Ha )": "Felszín ( Ha )",
  "Superficie Sector ( Ha )": "Szektor területe ( Ha )",
  "surfaceUnit": "Felületi egység",
  "switch between all samples and current season samples": "váltás az összes minta és a jelenlegi szezon mintái között",
  "Switch to hybrid map": "Váltás a hibrid térképre",
  "Switch to Mapbox": "Váltás a műholdas2 térképre",
  "Switch to satellite map": "Váltás a műholdas térképre",
  "Switch to Terrain map": "Váltás a tereptérképre",
  "target date": "Céldátum",
  "target multiparcela": "Többmezős cél",
  "tell us": "Mondja el nekünk",
  "Temporal Comparison tutorial": "Időbeli összehasonlítás útmutató",
  "textura del suelo": "Talaj textúrája",
  "The date should be in DD/MM/YYYY format": "A hivatkozási dátumnak érvényesnek kell lennie a NN/HH/ÉÉÉÉ formátumban",
  "The distance must be in meters": "A távolságnak méterben kell lennie",
  "the extra parameters will be removed": "az extra paraméterek eltávolításra kerülnek",
  "the following layers are no longer available in offline mode, download again if necessary": "a következő rétegek már nem érhetők el offline módban, szükség esetén töltse le újra",
  "The production must be numeric/decimal": "A termelésnek számszerű/tizedesnek kell lennie",
  "The right column shows the total amount to be applied according to the value entered per class, and the total per plot at the bottom": "A jobb oldali oszlop az osztályonként megadott érték szerint alkalmazandó teljes mennyiséget, és az alsó részen a parcella szerinti összmennyiséget mutatja.",
  "The sample location must be inside one of the fields.": "A mintavételi helynek az egyik mezőn belül kell lennie.",
  "There are no samples to download": "Nincsenek letölthető minták",
  "There is no operated flight on this field.": "Ezen a mezőn nincs működtetett repülés",
  "Thermal Accumulation": "Hőfelhalmozódás",
  "These numbers can be modified according to application needs and are associated with each geometry in the attribute table of the exported SHP": "Ezek a számok az alkalmazási igények szerint módosíthatók, és az exportált SHP attribútumtáblázatában minden geometriahoz társítva vannak.",
  "This action will delete the field and all its related data": "Ez a művelet törli a mezőt és az összes kapcsolódó adatot",
  "this action will modify your field selection": "ez a művelet módosítja a mezőkiválasztását",
  "this action will open field view and lose current field selection": "ez a művelet megnyitja a mező nézetet és elveszíti a jelenlegi mezőkiválasztást",
  "This button allows you to draw lines or polygons to measure a distance or area.": "Ez a gomb lehetővé teszi vonalak vagy poligonok rajzolását egy távolság vagy terület méréséhez.",
  "This button allows you to switch to the hybrid view.": "Ez a gomb lehetővé teszi a hibrid nézetre való váltást",
  "This can take a long time": "Ez sokáig tarthat",
  "This field is not downloaded.": "Ez a mező nincs letöltve",
  "This tool lets you cut polygons. Use single clicks to draw the line and a double click to finish drawing.": "Ez az eszköz lehetővé teszi a poligonok vágását. Egyetlen kattintásokkal rajzolja meg a vonalat, és dupla kattintással fejezze be a rajzolást.",
  "This tool lets you draw polygons. Try to draw a simple one. Make sure you close it!": "Ez az eszköz lehetővé teszi poligonok rajzolását. Próbáljon meg rajzolni egy egyszerűt. Győződjön meg róla, hogy lezárja!",
  "This tool lets you measure the distance between a set of points. Use single clicks to set the points and a double click to finish drawing.": "Ez az eszköz lehetővé teszi egy pontkészlet közötti távolság mérését. Egyetlen kattintásokkal állítsa be a pontokat, és dupla kattintással fejezze be a rajzolást.",
  "Those fields are mandatory": "Ezek a mezők kötelezőek",
  "TIME_COMPARISON_DROP_ZONE": "Húzd ide",
  "TINTA BARROCA": "BAROKK TINTA",
  "TINTA FRANCISCA": "FRANCISCA TINTA",
  "TINTO": "VÖRÖS",
  "TINTO CÃO": "VÖRÖS CÃO",
  "tipo de dosificacion": "Adagolás típusa",
  "to": "Nak nek",
  "today": "ma",
  "todos": "Mindenki",
  "tons of cane - annual vision": "Cukornád tonnái - Éves látás",
  "Total": "Összesen",
  "total production": "Teljes termelés (Kg)",
  "total_fruits_bigSize": "Összes nagy gyümölcs",
  "total_fruits_mediumSize": "Összes közepes gyümölcs",
  "total_fruits_smallSize": "Összes kis gyümölcs",
  "total_weight": "Teljes súly",
  "total_weight_bigSize": "Nagy gyümölcsök teljes súlya",
  "total_weight_canes": "Cukornád teljes súlya",
  "total_weight_mediumSize": "Közepes gyümölcsök teljes súlya",
  "total_weight_smallSize": "Kis gyümölcsök teljes súlya",
  "TOURIGA FRANCA": "TOURIGA FRANCA",
  "training": "Kiképzés",
  "TRINCADEIRA": "TRINCADEIRA",
  "Try our mobile app for a better experience": "Próbálja ki mobilalkalmazásunkat a jobb élményért",
  "type": "Növény",
  "Type": "Növénytípus",
  "type here your notes": "írja ide a jegyzeteit",
  "Type your text": "Írja be a szöveget",
  "type_sampler": "Mintavételi típus",
  "Undo Action": "Művelet visszavonása",
  "unidades": "Egységek",
  "unitSystem": "Mértékegység rendszer",
  "Unknown": "Ismeretlen",
  "unnamed field": "Névtelen mező",
  "unsaved changes will be lost": "a nem mentett változtatások elvesznek",
  "update sample": "minta frissítése",
  "updated": "Frissítve",
  "Use this buttons to select which layer to compare.": "Használja ezt a gombot a rétegek kiválasztásához, amelyeket össze szeretne hasonlítani.",
  "Use this tool to compare two flights temporally. To start, drag one of the flights into the one you want to compare it with.": "Használja ezt az eszközt két repülés időbeli összehasonlításához. Az indításhoz húzza az egyik repülést arra, amellyel össze szeretné hasonlítani.",
  "Use this tool to freely move the created polygons.": "Használja ezt az eszközt a létrehozott poligonok szabad mozgatásához.",
  "used": "használt",
  "Username": "Felhasználónév",
  "users": "Felhasználók",
  "Value": "Érték",
  "variabilidad multiparcela": "Többmezős változékonyság",
  "variabilidad parcela (vra)": "Változó sebességű terület (VRA)",
  "variability": "Változékonyság",
  "Variedad": "Alnövény",
  "vegetative growth": "Vegetatív növekedés",
  "vegetative growth per day": "Napi felhalmozódott vegetatív növekedés",
  "vegetative growth variability": "Vegetatív növekedés változékonysága",
  "Ver detalles": "Részletek megtekintése",
  "very high": "Nagyon magas",
  "very high level": "Nagyon magas szint",
  "very low": "Nagyon alacsony",
  "very low level": "Nagyon alacsony szint",
  "view": "nézet",
  "vigor": "Életerő",
  "vigor a nivel árbol": "vigor / növény",
  "vigor acumulado dos meses (ndvi)": "Két hónapra felhalmozódott életerő (NDVI)",
  "vigor acumulado un año (ndvi)": "Egy évre felhalmozódott életerő (NDVI)",
  "vigor vegetativo": "Vegetatív vigor",
  "vigor vegetativo - pcd": "PCD - Vegetatív vigor",
  "vigor vegetativo (ndvi)": "Vegetatív vigor (NDVI)",
  "vigor vegetativo (ndvi) - fases tempranas": "Vegetatív vigor (NDVI) - Korai szakasz",
  "vigor zonificado": "Zónázott életerő",
  "VINHÃO": "VINHÃO",
  "Visibility": "Láthatóság",
  "Vulnerable area": "Sebezhető terület",
  "wait": "várakozás",
  "warning": "Figyelmeztetés",
  "warning map": "Figyelmeztető térkép",
  "Water level": "Vízszint",
  "We are processing the data for your flight. Results will be available soon.": "Feldolgozzuk a repülés adatait. Az eredmények hamarosan elérhetők lesznek.",
  "weeds area": "Gyomterület",
  "week": "Hét",
  "weight_1": "Súly 1",
  "weight_2": "Súly 2",
  "weight_3": "Súly 3",
  "weight_4": "Súly 4",
  "weight_5": "Súly 5",
  "With this tool you can draw circles. Click to place the center, and click again to choose the radius.": "Ezzel az eszközzel köröket rajzolhat. Kattintson a középpont elhelyezéséhez, majd kattintson újra a sugár kiválasztásához.",
  "With this tool, you can draw a polygon with 3 or more points to measure its area.": "Ezzel az eszközzel három vagy több pontból álló poligont rajzolhat a területének méréséhez",
  "without warning": "Figyelmeztetés nélkül",
  "Wrong credentials": "Hibás hitelesítő adatok",
  "year": "Év",
  "years": "Év",
  "You can change here the information of the new field.": "Itt megváltoztathatja az új mező információit",
  "You can move this bar to compare the layers temorally.": "Ezzel az eszközzel összehasonlíthatja a rétegeket időben.",
  "You can only compare dates from the same sources": "Csak azonos forrásból származó dátumokat hasonlíthat össze",
  "You can use this button at any moment to undo your last actions.": "Ezt a gombot bármikor használhatja az utolsó műveletek visszavonásához.",
  "You can use this slider to change the opacity of the selected layer.": "Ezzel a csúszkával megváltoztathatja a kiválasztott réteg átlátszóságát.",
  "You cannot create field because you don't have any customers. Please ask us for adding ones": "Nem hozhat létre mezőt, mert nincsenek ügyfelei. Kérjük, kérjen tőlünk hozzáadást",
  "You must enter a decimal": "Tizedest kell megadnia",
  "You must enter an integer": "Egész számot kell megadnia",
  "You must select an irrigation type": "Öntözési típust kell választania",
  "You must type a field name": "Meg kell adnia egy mezőnevet",
  "You must type a text": "Szöveget kell írnia",
  "Your field has been successfully deleted.": "A mezőjét sikeresen töröltük",
  "Your LAYERS version has expired. Click to update.": "A LAYERS verziója lejárt. Kattintson a frissítéshez."
}
