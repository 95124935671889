// prettier-ignore
export default {
  "% Fallas": "% Неуспеха",
  "% Mala Hierba": "% Корова",
  "ACELGA": "БЛИТВА",
  "ACELGA VERDE": "ЗЕЛЕНА БЛИТВА",
  "action": "акција",
  "ag_accesses_the_viewer": "AG приступа прегледачу?",
  "AGUACATE": "АВОКАДО",
  "AJO": "БЕЛИ ЛУК",
  "ALBARICOQUE": "КАЈСИЈА",
  "ALBARINO": "АЛБАРИЊО",
  "ALFALFA": "ЛУЦЕРКА",
  "ALGODON": "ПАМУК",
  "aluminum": "Алуминијум",
  "aluminum_saturation": "Zasićenje Aluminijumom",
  "annotation": "анотација",
  "aphid_controller": "бувач - контролор",
  "aphid_infested": "бувач - заражено",
  "aphid_infested_leafs": "бувач - заражени листови",
  "aphid_mite_total_leafs": "бувач и гриња - укупно листова",
  "APIO": "ЦЕЛЕР",
  "apparent_density": "Очигледна густина",
  "ARANDANOS": "БОРОВНИЦЕ",
  "ARBOSANA": "АРБОСАНА",
  "ARROZ": "ПИРИНАЧ",
  "assesor_name": "Име саветника",
  "atr": "Атр",
  "AVENA": "ОВАС",
  "BARBECHO": "ЗАТРАВЉИВАЊЕ",
  "BATAVIA": "БАТАВИА",
  "beans_10_plants": "Биљке са 10 пасуља",
  "billaea_healthy_pupe": "биллаеа - здраве лутке",
  "billaea_larva": "биллаеа - ларве",
  "billaea_parasitized_larva": "биллаеа - паразитиране ларве",
  "billaea_residual_pupe": "биллаеа - остатне лутке",
  "bipolaris_sacchari_incidence": "биполарис саццхари \"пегавост ока\" - инциденца",
  "bipolaris_sacchari_severity": "биполарис саццхари \"пегавост ока\" - озбиљност",
  "bipolaris_sacchari_total_infected_leafs": "биполарис саццхари \"пегавост ока\" - укупно заражених листова",
  "BLANCO": "БЕЛО",
  "blastobasis_healthy_chrysalis": "бластобасис - здраве хризалиде",
  "blastobasis_larva_number": "бластобасис - ларве",
  "blastobasis_residual_chrysalis": "бластобасис - остатне хризалиде",
  "boron": "Бор",
  "bouquet10roots_2_8cm": "Букет 10 корена (2-8cm)",
  "bouquet10roots_2cm": "Букет 10 корена (0-2cm)",
  "bouquet10roots_8cm": "Букет 10 корена (>8cm)",
  "brix": "Брикс",
  "BROCOLI": "БРОКУЛИ",
  "bud_blastobasis_drilled": "пупољци бластобасис пробушени",
  "bud_dehydrated": "дехидрирани пупољци",
  "bud_diatrea_drilled": "пробушени диатреа пупољци",
  "bud_mechanical_damage": "механичка оштећења пупољака",
  "bud_sprout": "пупољци - изданци",
  "bud_total": "укупно пупољака",
  "bud_turgid": "чврсти пупољци",
  "CABERNET SAUVIGNON": "КАБЕРНЕ СОВИЊОН",
  "CALABACIN": "ТИКВИЦЕ",
  "calcium": "Калцијум",
  "calcium_saturation": "Zasićenje Kalcijumom",
  "CAÑA DE AZUCAR": "ШЕЋЕРНА ТРСКА",
  "CAQUI": "КАКИ",
  "casida_afa_perc": "Касида - АФА(%)",
  "casida_n_of_adults_in_50_plants": "Касида - Број одраслих у 50 биљака",
  "casida_n_of_eggs_in_50_plants": "Касида - Број јаја у 50 биљака",
  "casida_n_of_large_larvae_in_50_plants": "Касида - Број великих ларви у 50 биљака",
  "casida_n_of_small_larvae_in_50_plants": "Касида - Број малих ларви у 50 биљака",
  "CAUCHO": "ГУМА",
  "CEBADA": "ЈЕЧАМ",
  "CEBADA HIBRIDA": "ХИБРИДНИ ЈЕЧАМ",
  "CEBADA MALTERA": "МАЛТЕРСКИ ЈЕЧАМ",
  "CEBOLLA": "ЦРНИ ЛУК",
  "cercospora_20_100_spots_leaf": "Церкоспора - 20-100 тачака/лист",
  "cercospora_50_afa": "Церкоспора - 50% АФА",
  "cercospora_50_perc_leaves_ext_with_80_100_severity": "Церкоспора - 50% листова екст са 80-100% озбиљности",
  "cercospora_75_afa": "Церкоспора - 75%АФА",
  "cercospora_90_100_afa": "Церкоспора - 90-100%АФА",
  "cercospora_healthy_plant": "Церкоспора - Здрава биљка",
  "cercospora_isolated_spots_on_some_leaves_10_15_sl_100_leaves": "Церкоспора - Изоловане тачке на неким листовима (10-50%СЛ/100 листова).",
  "cercospora_isolated_spots_on_some_leaves_5_10_sl_100_leaves": "Церкоспора - Изоловане тачке на неким листовима (5-10%СЛ/100 листова)",
  "cercospora_isolated_spots_on_some_leaves_minus_5_sl_100_leaves": "Церкоспора - Изоловане тачке на неким листовима (<5%СЛ/100 листова)",
  "cercospora_leaf_necrosis_ext": "Церкоспора - Некроза листа ЕКСТ.",
  "cercospora_leaves_ext_almost_total_necrosis": "Церкоспора - Листови ЕКСТ: скоро тотална некроза",
  "cercospora_leaves_with_major_100_sports": "Церкоспора - Листови са > 100 тачака",
  "cercospora_minus_20_spots_leaf": "Церкоспора - <20 тачака/лист",
  "cercospora_necrosis_on_most_leaves_int": "Церкоспора - Некроза на већини листова ИНТ.",
  "cercospora_treatment": "Церкоспора - Третман",
  "CEREZA": "ТРЕШЊА",
  "CESPED": "ТРАВА",
  "CHARDONNAY": "ШАРДОНЕ",
  "chromium": "Hrom",
  "cic": "Циц",
  "CIRUELA": "ШЉИВА",
  "clay": "Глина",
  "CLEMENTINA": "КЛЕМЕНТИНА",
  "close": "затвори",
  "COLZA": "РЕПИЦА",
  "Conteo": "Бројање",
  "copper": "Бакар",
  "cotesia_cocon": "котесија - кокони",
  "cotesia_parasitized_larva": "котесија - паразитиране ларве",
  "cotyledon": "котиледон",
  "current_ndre_status": "Тренутни статус НДРЕ",
  "date_of_first_informative_email_sent": "Датум првог информативног е-маила послатог",
  "date_of_hemav_ndre_layer_screenshot": "Датум снимка слоја Хемав НДРЕ",
  "defoliator_leaf_evaluated": "дефолијатор - процењени листови",
  "defoliator_marasmia_sp_larva_alive": "дефолијатор - марасмија сп. - живе ларве",
  "defoliator_marasmia_sp_larva_death": "дефолијатор - марасмија сп. - мртве ларве",
  "defoliator_marasmia_sp_larva_per_stem": "дефолијатор - марасмија сп. - ларве по стабљици",
  "defoliator_mythimna_sp_larva_alive": "дефолијатор - митимна сп. - живе ларве",
  "defoliator_mythimna_sp_larva_death": "дефолијатор - митимна сп. - мртве ларве",
  "defoliator_mythimna_sp_larva_per_stem": "дефолијатор - митимна сп. - ларве по стабљици",
  "defoliator_spodoptera_sp_larva_alive": "дефолијатор - сподоптера сп. - живе ларве",
  "defoliator_spodoptera_sp_larva_death": "дефолијатор - сподоптера сп. - мртве ларве",
  "defoliator_spodoptera_sp_larva_per_stem": "дефолијатор - сподоптера сп. - ларве по стабљици",
  "defoliator_stem_total": "дефолијатор - укупно стабљика",
  "depth": "Дубина",
  "desconocido": "Непознато",
  "DESCONOCIDO": "НЕПОЗНАТО",
  "diatraea_saccharalis_larva_big": "диатраеа саццхаралис - велике ларве",
  "diatraea_saccharalis_larva_healthy_chrysalis": "диатраеа саццхаралис - здраве хризалиде",
  "diatraea_saccharalis_larva_medium": "диатраеа саццхаралис - средње ларве",
  "diatraea_saccharalis_larva_residual_chrysalis": "диатраеа саццхаралис - остатне хризалиде",
  "diatraea_saccharalis_larva_small": "диатраеа саццхаралис - мале ларве",
  "diatraea_saccharalis_larva_total": "диатраеа саццхаралис - укупно ларви",
  "disease": "Болест",
  "Disease": "Болест",
  "disease_level": "Ниво болести",
  "distance_between_rows": "Размак између редова",
  "does_it_follow_the_recommendation": "Да ли прати препоруку?",
  "Drip": "Кап по кап",
  "dry_weight": "Сува тежина",
  "effective_cation_exchange_capacity": "Efektivni Kapacitet Zamene Katjona",
  "ESCAROLA": "ЕНДИВИЈА",
  "ESCAROLA LISA": "ГЛАТКА ЕНДИВИЈА",
  "ESCAROLA RIZADA": "КОВРЏАВА ЕНДИВИЈА",
  "ESPARRAGO": "ШПАРОГЕ",
  "ESPINACA": "СПАНАЋ",
  "estrés hídrico": "Водни стрес",
  "Estrés hídrico (LWS)": "Водни стрес (ЛВС)",
  "evaluator": "Име саветника",
  "Ex": "Пр.",
  "exchangeable_acidity": "Zamenljiva Kiselost",
  "fallas": "Јазови",
  "fire": "Пожар",
  "flowering": "Цветање",
  "forecast_yield_sampling_1": "ПРОГНОЗА ПРИНОСА УЗОРКОВАЊЕ 1",
  "forecast_yield_sampling_2": "ПРОГНОЗА ПРИНОСА УЗОРКОВАЊЕ 2",
  "forecast_yield_sampling_3": "ПРОГНОЗА ПРИНОСА УЗОРКОВАЊЕ 3",
  "forecast_yield_sampling_4": "ПРОГНОЗА ПРИНОСА УЗОРКОВАЊЕ 4",
  "fresh_weight": "Свежа тежина",
  "FRIJOL": "ПАСУЉ",
  "fruits_count": "Број воћа",
  "FRUTAL": "ВОЋЕ",
  "Generic": "Генерички",
  "GEWURZTRAMINNER": "ГЕВЮРЦТРАМИНЕР",
  "GIRASOL": "СУНЦОКРЕТ",
  "gm_avg_ndvi": "Вегетативни вигор (НДВИ-Дрон)",
  "gm_luvi_ndvi_mean": "Вегетативни вигор (НДВИ-ЛУВИ)",
  "gm_ndmi_mean": "Вегетативна влажност (НДМИ)",
  "gm_ndre_mean": "Азот-Хлорофил (НДРЕ)",
  "gm_perc_overseeding": "% Празнина",
  "gm_perc_weeds": "% Корова",
  "GODELLO": "ГОДЕЛО",
  "GOLDEN DELICIOUS": "ГОЛДЕН ДЕЛИЦИОУС",
  "GOLDEN REINDERS": "ГОЛДЕН РЕИНДЕРС",
  "Grams": "Грами",
  "GRANADA": "НАР",
  "green_dots": "зелене тачке",
  "GRENACHE BLANC": "ГРЕНАЧ БЛАНК",
  "GUISANTE": "ГРАШАК",
  "HABA": "БОБ",
  "hanger_installation": "Постављање пречке",
  "height": "Висина",
  "HIBRIDO": "ХИБРИД",
  "high irrigation": "Висок водни стрес",
  "high_n_from_irrigation_water": "Висок N из наводњавања водом",
  "high_n_from_nitrogen_input": "Висок N од азотног уноса",
  "high_n_from_previous_years": "Висок N из претходних година",
  "higher level": "Виши ниво",
  "HIGO": "СМОКВА",
  "Humedad Vegetativa (NDMI) ": "Вегетативна влажност (НДМИ)",
  "humidity": "Влажност",
  "humidity_100_beans": "Влажност у 100 пасуља",
  "Imperial": "Империјал",
  "inspection": "инспекција",
  "internodes_blastobasis_damage_longitude": "дужина оштећења бластобасис интернода",
  "internodes_blastobasis_drilled": "пробушени бластобасис интерноди",
  "internodes_diatraea_damage_longitude": "дужина оштећења диатреа интернода",
  "internodes_diatraea_drilled": "пробушени диатреа интерноди",
  "internodes_evaluated": "укупно интерноди",
  "internodes_healthy": "здрави интерноди",
  "internodes_mechanical_damage": "механичка оштећења интернода",
  "internodes_physiological_damage": "физиолошка оштећења интернода",
  "iron": "Гвожђе",
  "irrigation": "НАВОДЊАВАЊЕ",
  "Irrigation": "Наводњавање",
  "irrigation_dosage": "Дозирање наводњавања",
  "irrigation_uniformity": "Униформност наводњавања",
  "KUMQUATS": "КУМКВАТИ",
  "language": "Језик система",
  "larva_number_big": "ларвална стадијум - велика ларва",
  "larva_number_medium": "ларвална стадијум - средња ларва",
  "larva_number_small": "ларвална стадијум - мала ларва",
  "last_fertilizer_app_date": "Датум последње примене ђубрива",
  "last_irrigation": "Последње наводњавање",
  "lead": "Olovo",
  "Leaf": "Лист",
  "leaf_moisture_status": "Статус влажности листа",
  "LECHUGA": "САЛАТА",
  "LECHUGA ICEBERG": "САЛАТА АЈСБЕРГ",
  "leptosphaeria_sacchari_incidence": "лептосферија саццхари \"пегавост прстена\" - инциденца",
  "leptosphaeria_sacchari_severity": "лептосферија саццхари \"пегавост прстена\" - озбиљност",
  "leptosphaeria_sacchari_total_infected_leafs": "лептосферија саццхари \"пегавост прстена\" - укупно заражених листова",
  "LIMON": "ЛИМУН",
  "linear_meters": "Линеарни метри",
  "loam": "Иловача",
  "loss_of_vigor": "Губитак виталности",
  "low irrigation": "Низак водни стрес",
  "lower level": "Нижи ниво",
  "lowered_unload": "Спуштено приликом пражњења",
  "MACABEU": "МАКАБЕУ",
  "MACABEU VIURA": "МАКАБЕУ ВИУРА",
  "magnesium": "Магнезијум",
  "magnesium_saturation": "Zasićenje Magnezijumom",
  "maleza (ciclo<60 dias)": "Корова (Цикл<60 дана)",
  "MALVASIA AROMATICA": "АРОМАТИЧНА МАЛВАЗИЈА",
  "MALVASIA DE SITGES": "МАЛВАЗИЈА ДЕ СИТГЕС",
  "MALVASIA GROSSA": "МАЛВАЗИЈА ГРОСА",
  "MANDARINA": "МАНДАРИНА",
  "manganese": "Манган",
  "MANZANA": "ЈАБУКА",
  "mean level": "Средњи ниво",
  "medium irrigation": "Средњи водни стрес",
  "medium_moisture_saturation": "Srednja Zasićenost Vlažnosti",
  "MELOCOTON": "БРЕСКВА",
  "MELON": "ДИЊА",
  "mercury": "Živa",
  "MERLOT": "МЕРЛО",
  "Metric": "Метрички",
  "microorganisms_larva": "ларве микроорганизама",
  "mite_grade": "средњи степен гриње",
  "mite_infested": "заражен грињама",
  "mite_infested_leafs": "гриња - заражени листови",
  "moisture_status": "Статус влажности",
  "molybdenum": "Молибден",
  "MONTONEC": "МОНТОНЕК",
  "MONTONEGA": "МОНТОНЕГА",
  "MOSCATELL DE ALEXANDRIA": "МОСКАТЕЛ ДЕ АЛЕКСАНДРИЈА",
  "MOSCATELL ROMA": "МОСКАТЕЛ РИМ",
  "NARANJA": "НАРАНЏА",
  "NDRE": "НДРЕ",
  "NDVI": "НДВИ",
  "NECATRINA": "НЕКАТРИНА",
  "NECTARINA": "НЕКТАРИНА",
  "nickel": "Nikl",
  "Nitrogen": "Азот",
  "Nitrógeno Foliar": "Листни азот",
  "nitrógeno-clorofila (ndre)": "Азот-Хлорофил (НДРЕ)",
  "Nitrógeno-Clorofila (NDRE)": "Азот-Хлорофил (НДРЕ)",
  "No crop or too dry": "Без усева или пресушено",
  "NO CULTIVO": "БЕЗ УСЕВА",
  "No hay datos": "Нема података",
  "NOGAL": "ОРАХ",
  "notification_to_the_farmer_high_n_index": "Обавештење фармеру: Висок N индекс",
  "Observations": "Опсервације",
  "OLIVO": "МАСЛИНА",
  "opogona_larva_number": "опогона - ларве",
  "organic_material": "Органски материјал",
  "organic_oxidizable_carbon": "Organsko Oksidabilni Ugljenik",
  "PANSA BLANCA": "БЕЛА ПАНСА",
  "PANSAL": "ПАНСАЛ",
  "PARAGUAYO": "ПАРАГВАЈАЦ",
  "PARELLADA": "ПАРЕЉАДА",
  "PATATA": "КРОМПИР",
  "pattern": "узорак",
  "PERA": "КРУШКА",
  "percent_disease": "Проценат болести",
  "perkinsiella_345_instar_nymph": "перкинсиела - нимфе 3-4-5 инстара",
  "perkinsiella_adult": "перкинсиела - одрасли",
  "perkinsiella_controller_coccinelidos": "перкинсиела - контролери - кокцинелиди",
  "perkinsiella_controller_crysopas": "перкинсиела - контролери - крисопе",
  "perkinsiella_controller_spiders": "перкинсиела - контролери - пауци",
  "perkinsiella_controller_tytthus_parviceps": "перкинсиела - контролери - титус парвицепс",
  "perkinsiella_controller_zelus": "перкинсиела - контролери - зелус",
  "perkinsiella_death_adult": "перкинсиела - мртви одрасли",
  "perkinsiella_death_nymph": "перкинсиела - мртве нимфе",
  "petiole_sampling": "Узорковање петељки",
  "ph": "пХ",
  "pheromone_trap_diatraea_adults_number": "феромонска замка - одрасли диатраеа",
  "pheromone_trap_installation_date": "феромонска замка - датум инсталације",
  "pheromone_trap_number": "феромонска замка - замке",
  "pheromone_trap_other_butterfly": "феромонска замка - друге лептирице",
  "pheromone_trap_pheromone_change": "феромонска замка - промена феромона",
  "phosphorus": "Фосфор",
  "PIMIENTO": "ПАПРИКА",
  "PINO": "БОР",
  "PINOT NOIR": "ПИНО НОАР",
  "PISTACHO": "ПИСТАЋИ",
  "Pivot": "Пивот",
  "Plague": "Куга",
  "plague_level": "Ниво куге",
  "Plant": "Биљка",
  "Plant Density": "Густина биљака",
  "plantation_date": "Датум садње",
  "plants_per_surface_unit": "Биљке по јединици површине",
  "poda": "Резидба",
  "pol": "Пол",
  "POMELO": "ГРЕЈПФРУТ",
  "POMELOS": "ПОМЕЛОСИ",
  "potassium": "Калијум",
  "potassium_saturation": "Zasićenje Kalijumom",
  "powdery_mildew_afa_perc": "Прашкаста плесан - АФА(%)",
  "powdery_mildew_perc_of_affected_plants": "Прашкаста плесан - % заражених биљака (ИНЦИДЕНЦИЈА)",
  "powdery_mildew_treatment": "Третман прашкасте плесни",
  "Production": "Производња",
  "pseudomonas_rubrilineans_stem_number": "црвена пруга - укупно стабљика",
  "pseudomonas_rubrilineans_stem_number_affected": "црвена пруга - заражене стабљике",
  "pseudomonas_rubrilineans_stem_number_with_symptoms": "црвена пруга - стабљике са симптомима",
  "puccinia_melanocephala_accumulated_scale": "рђа - кумулативна скала",
  "puccinia_melanocephala_infestation": "рђа - заражено",
  "puccinia_melanocephala_infested_leafs": "рђа - заражени листови",
  "puccinia_melanocephala_points": "рђа - поени",
  "puccinia_melanocephala_total_leafs": "рђа - укупно листова",
  "puccinia_sp_incidence": "пуцциниа сп \"рђа\" - инциденца",
  "puccinia_sp_severity": "пуцциниа сп \"рђа\" - озбиљност",
  "puccinia_sp_total_infected_leafs": "пуцциниа сп \"рђа\" - укупно заражених листова",
  "PUERRO": "ПРАЗИЛУК",
  "Quality": "Квалитет",
  "QUINOA": "КВИНОА",
  "RAIGRAS": "РАЈГРАС",
  "real_leaves": "прави листови",
  "reform": "Реформа",
  "REMOLACHA": "ЦВЕКЛА",
  "REMOLACHA AZUCARERA": "ШЕЋЕРНА РЕПА",
  "resowing_surface": "Површина за поновну сетву",
  "result": "резултат",
  "riego": "Наводњавање",
  "rodents_infestation_percent": "Глодари - инфестација",
  "rot10roots_complete": "Трулеж 10 корена - Комплетно",
  "rot10roots_nothing": "Трулеж 10 корена - Ништа",
  "rot10roots_partial": "Трулеж 10 корена - Делимично",
  "rust_10_25_afa": "Рђа - 10-25 % АФА",
  "rust_100_afa": "Рђа -Н- 100 % АФА. Оригинално лишће потпуно уништено",
  "rust_25_35_afa": "Рђа -Ј- 25-35 % АФА. 1/4 -1/3 лишћа је озбиљно заражено",
  "rust_3_10_afa": "Рђа -И- 3-10 % АФА",
  "rust_35_50_afa": "Рђа -К- 35-50 % АФА.  1/3 - 1/2 лишћа је озбиљно заражено",
  "rust_50_75_afa": "Рђа -Л- 50-75 % АФА. 1/2 лишћа је озбиљно заражено",
  "rust_75_90_afa": "Рђа -М- 75-90 % АФА. Све лишће је озбиљно заражено",
  "rust_healthy_plant": "Рђа -А- Здрава биљка",
  "rust_isolated_spots_on_some_leaves_1_9_perc": "Рђа -Б- Изоловане тачке на неким листовима. 1-9 (%СЛ)",
  "rust_isolated_spots_on_some_leaves_10_19_perc": "Рђа -Ц- Изоловане тачке на неким листовима. 10-19 (%СЛ)",
  "rust_isolated_spots_on_some_leaves_20_29_perc": "Рђа -Д- Изоловане тачке на неким листовима. 20-29 (%СЛ)",
  "rust_isolated_spots_on_some_leaves_30_50_perc": "Рђа -Е- Изоловане тачке на неким листовима. 30-50 (%СЛ)",
  "rust_regrowth_restart": "Рђа -О- Почетак поновног раста. Додајте 0.5 поена за сваку недељу до жетве",
  "rust_spots_leaves_100_300": "Рђа -Х- Листови са 100-300 тачака",
  "rust_spots_leaves_20_100": "Рђа -Г- 20-100 тачака по листу на већини листова",
  "rust_spots_leaves_50": "Рђа -Ф- >50% СЛ и <20 тачака по листу на већини листова",
  "rust_treatment": "Третман рђе",
  "sac": "Сак",
  "Safety": "Безбедност",
  "sample_color": "Боја узорка",
  "sand": "Песак",
  "SAUVIGNON BLANC": "СОВИЊОН БЛАНК",
  "severity": "Озбиљност",
  "soca_width": "Број корена",
  "sodium": "Натријум",
  "sodium_saturation": "Zasićenje Natrijumom",
  "Soil": "Тло",
  "soil_moisture_status": "Статус влажности тла",
  "SOJA": "СОЈА",
  "sowing_failure": "Неуспех сетве",
  "sprout_dead": "мртав изданак",
  "sprout_total": "укупно изданака",
  "starch": "Скроб",
  "stems_evaluated": "процењене стабљике",
  "sulfur": "Сумпор",
  "Superficial": "Површно",
  "SYRAH": "СИРА",
  "TABACO": "ДУВАН",
  "TE": "ЧАЈ",
  "TEA": "ЧАЈ",
  "TEMPRANILLO": "ТЕМПРАНИЉО",
  "TOMATE": "ПАРАДАЈЗ",
  "tombing": "Лежаће биљке",
  "total_arsenic": "Ukupni Arsen",
  "total_cadmium": "Ukupni Kadmijum",
  "TOURIGA NACIONAL": "ТОУРИГА НАЦИОНАЛ",
  "TRIGO": "ПШЕНИЦА",
  "TRIGO BLANDO": "МЕКА ПШЕНИЦА",
  "TRIGO DURO": "ТВРДА ПШЕНИЦА",
  "TRITICALE": "ТРИТИКАЛЕ",
  "type_disease": "Тип болести",
  "uneven_budding": "Неравномерно бујање",
  "ustilago_scitaminea_infection": "устилаго сцитаминеа \"угаљ\" - заражено",
  "ustilago_scitaminea_stem_affected": "устилаго сцитаминеа \"угаљ\" - укупно болесних стабљика",
  "ustilago_scitaminea_stem_symptoms": "устилаго сцитаминеа \"угаљ\" - стабљике са симптомима",
  "ustilago_scitaminea_stem_total": "устилаго сцитаминеа \"угаљ\" - укупно стабљика",
  "ustilago_scitaminea_stem_whip": "устилаго сцитаминеа \"угаљ\" - бичасте стабљике",
  "UVA": "ГРОЖЂЕ",
  "UVA DE MESA": "СТОНО ГРОЖЂЕ",
  "Variabilidad": "Варијабилност",
  "Variabilidad (NDVI-LUVI)": "Варијабилност (НДВИ-ЛУВИ)",
  "VERDEJO": "ВЕРДЕХО",
  "very high irrigation": "Веома висок водни стрес",
  "very low irrigation": "Веома низак водни стрес",
  "VIGNE": "ВИЊА",
  "Vigor Vegetativo (NDVI-Drone)": "Вегетативни вигор (НДВИ-Дрон)",
  "Vigor Vegetativo (NDVI-LUVI)": "Вегетативни вигор (НДВИ-ЛУВИ)",
  "vigor vegetativo (ndvi) - vigor alto": "Вегетативни вигор (НДВИ) - Висок вигор",
  "VIÑA": "ВИНОГРАД",
  "VIOGNIER": "ВИОЊЕ",
  "virgin_female_georeferenced_trap_diatraea_adult_total": "замка девичанске женке - укупно одраслих диатраеа",
  "virgin_female_georeferenced_trap_female_change": "замка девичанске женке - промена женке",
  "virgin_female_georeferenced_trap_other_butterfly": "замка девичанске женке - друге лептирице",
  "virgin_female_georeferenced_trap_point": "замка девичанске женке - тачка замке",
  "virgin_female_trap_diatraea_adult_number": "замка девичанске женке - број одраслих диатраеа",
  "virgin_female_trap_female_change": "замка девичанске женке - промена женке",
  "virgin_female_trap_installation_date": "замка девичанске женке - датум инсталације",
  "virgin_female_trap_number": "замка девичанске женке - број замки",
  "virgin_female_trap_other_butterfly": "замка девичанске женке - друге лептирице",
  "VIURA": "ВИУРА",
  "weed_unload": "Корови (пражњење)",
  "Weeds": "Корови",
  "weight_100_beans": "Биљке од 100 пасуља",
  "weight_kg": "06.Тежина узорка",
  "whey": "Сурутка",
  "without_footwear": "Без обуће",
  "without_gloves": "Без рукавица",
  "without_vest": "Без прслука",
  "XARELLO": "КСАРЕЛО",
  "yellowness_mild": "Жутило - Умерен напад (слободне биљке)",
  "yellowness_moderate": "Жутило - Умерен напад (редови)",
  "yellowness_strong": "Жутило - Јак напад (парцела потпуно заражена)",
  "yellowness_without_symptoms": "Žutost - Bez simptoma",
  "zinc": "Цинк"
}
