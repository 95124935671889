// prettier-ignore
export default {
  "% Fallas": "% Збоїв",
  "% Mala Hierba": "% Бур'яни",
  "ACELGA": "Мангольд",
  "ACELGA VERDE": "Зелений Мангольд",
  "action": "дія",
  "ag_accesses_the_viewer": "k. AG отримує доступ до переглядача?",
  "AGUACATE": "Авокадо",
  "AJO": "Часник",
  "ALBARICOQUE": "Абрикос",
  "ALBARINO": "Альбариньо",
  "ALFALFA": "Люцерна",
  "ALGODON": "Бавовна",
  "aluminum": "Алюміній",
  "aluminum_saturation": "Насичення Алюмінієм",
  "annotation": "анотація",
  "aphid_controller": "контроль за афідами",
  "aphid_infested": "інфіковані афідами",
  "aphid_infested_leafs": "листя, уражене афідами",
  "aphid_mite_total_leafs": "афіди та кліщі - всього листя",
  "APIO": "Селера",
  "apparent_density": "удавана щільність",
  "ARANDANOS": "Чорниці",
  "ARBOSANA": "Арбосана",
  "ARROZ": "Рис",
  "assesor_name": "ім'я оцінювача",
  "atr": "Atr",
  "AVENA": "Вівса",
  "BARBECHO": "Пар",
  "BATAVIA": "Батавія",
  "beans_10_plants": "10 рослин бобів",
  "billaea_healthy_pupe": "здорові лялечки біллаея",
  "billaea_larva": "ларви біллаея",
  "billaea_parasitized_larva": "паразитовані ларви біллаея",
  "billaea_residual_pupe": "залишкові лялечки біллаея",
  "bipolaris_sacchari_incidence": "інцидентність \"bipolaris sacchari\"",
  "bipolaris_sacchari_severity": "тяжкість \"bipolaris sacchari\"",
  "bipolaris_sacchari_total_infected_leafs": "загальна кількість інфікованих листків \"bipolaris sacchari\"",
  "BLANCO": "Білий",
  "blastobasis_healthy_chrysalis": "здорові хризаліди blastobasis",
  "blastobasis_larva_number": "кількість личинок blastobasis",
  "blastobasis_residual_chrysalis": "залишкові хризаліди blastobasis",
  "boron": "Бор",
  "bouquet10roots_2_8cm": "02.Букет 10 коренів (2-8см)",
  "bouquet10roots_2cm": "01.Букет 10 коренів (0-2см)",
  "bouquet10roots_8cm": "03.Букет 10 коренів (>8см)",
  "brix": "Брікс",
  "BROCOLI": "Брокколі",
  "bud_blastobasis_drilled": "просвердлені бруньки blastobasis",
  "bud_dehydrated": "зневоднені бруньки",
  "bud_diatrea_drilled": "просвердлені бруньки diatrea",
  "bud_mechanical_damage": "механічне пошкодження бруньок",
  "bud_sprout": "ростки бруньок",
  "bud_total": "всього бруньок",
  "bud_turgid": "пружні бруньки",
  "CABERNET SAUVIGNON": "Каберне Совіньйон",
  "CALABACIN": "Кабачок",
  "calcium": "Кальцій",
  "calcium_saturation": "Насичення Кальцієм",
  "CAÑA DE AZUCAR": "Цукрова тростина",
  "CAQUI": "Хурма",
  "casida_afa_perc": "Касіда - AFA(%)",
  "casida_n_of_adults_in_50_plants": "Касіда - кількість дорослих на 50 рослинах",
  "casida_n_of_eggs_in_50_plants": "Касіда - кількість яєць на 50 рослинах",
  "casida_n_of_large_larvae_in_50_plants": "Касіда - кількість великих личинок на 50 рослинах",
  "casida_n_of_small_larvae_in_50_plants": "Касіда - кількість маленьких личинок на 50 рослинах",
  "CAUCHO": "Каучук",
  "CEBADA": "Ячмінь",
  "CEBADA HIBRIDA": "Гібридний ячмінь",
  "CEBADA MALTERA": "Солодовий ячмінь",
  "CEBOLLA": "Цибуля",
  "cercospora_20_100_spots_leaf": "Cercospora -F- 20-100 плям на листку",
  "cercospora_50_afa": "Cercospora -L- 50% AFA",
  "cercospora_50_perc_leaves_ext_with_80_100_severity": "Cercospora -K- 50% листків з тяжкістю 80-100%",
  "cercospora_75_afa": "Cercospora -M- 75%AFA",
  "cercospora_90_100_afa": "Cercospora -N- 90-100%AFA",
  "cercospora_healthy_plant": "Cercospora -A- Здорова рослина",
  "cercospora_isolated_spots_on_some_leaves_10_15_sl_100_leaves": "Cercospora -D- Окремі плями на деяких листках (10-50%SL/100 листків).",
  "cercospora_isolated_spots_on_some_leaves_5_10_sl_100_leaves": "Cercospora -C- Окремі плями на деяких листках (5-10%SL/100 листків)",
  "cercospora_isolated_spots_on_some_leaves_minus_5_sl_100_leaves": "Cercospora -B- Окремі плями на деяких листках (<5%SL/100 листків)",
  "cercospora_leaf_necrosis_ext": "Cercospora -H- Некроз листка EXT.",
  "cercospora_leaves_ext_almost_total_necrosis": "Cercospora -J- Листки EXT: майже повний некроз",
  "cercospora_leaves_with_major_100_sports": "Cercospora -G- Листки з > 100 плямами",
  "cercospora_minus_20_spots_leaf": "Cercospora -E- <20 плям на листку",
  "cercospora_necrosis_on_most_leaves_int": "Cercospora -I- Некроз на більшості листків INT.",
  "cercospora_treatment": "Cercospora -o- Лікування",
  "CEREZA": "Вишня",
  "CESPED": "Трава",
  "CHARDONNAY": "Шардоне",
  "chromium": "Хром",
  "cic": "Cic",
  "CIRUELA": "Слива",
  "clay": "Глина",
  "CLEMENTINA": "Клементин",
  "close": "закрити",
  "COLZA": "Ріпак",
  "Conteo": "підрахунок",
  "copper": "Мідь",
  "cotesia_cocon": "cotesia - кокони",
  "cotesia_parasitized_larva": "cotesia - паразитовані ларви",
  "cotyledon": "котиледон",
  "current_ndre_status": "a. Поточний статус NDRE",
  "date_of_first_informative_email_sent": "j. Дата відправлення першого інформаційного листа",
  "date_of_hemav_ndre_layer_screenshot": "h. Дата скріншоту шару Hemav NDRE",
  "defoliator_leaf_evaluated": "дефоліатор - оцінені листя",
  "defoliator_marasmia_sp_larva_alive": "дефоліатор - marasmia sp. - живі ларви",
  "defoliator_marasmia_sp_larva_death": "дефоліатор - marasmia sp. - мертві ларви",
  "defoliator_marasmia_sp_larva_per_stem": "дефоліатор - marasmia sp. - ларви на стеблі",
  "defoliator_mythimna_sp_larva_alive": "дефоліатор - mythimna sp. - живі ларви",
  "defoliator_mythimna_sp_larva_death": "дефоліатор - mythimna sp. - мертві ларви",
  "defoliator_mythimna_sp_larva_per_stem": "дефоліатор - mythimna sp. - ларви на стеблі",
  "defoliator_spodoptera_sp_larva_alive": "дефоліатор - spodoptera sp. - живі ларви",
  "defoliator_spodoptera_sp_larva_death": "дефоліатор - spodoptera sp. - мертві ларви",
  "defoliator_spodoptera_sp_larva_per_stem": "дефоліатор - spodoptera sp. - ларви на стеблі",
  "defoliator_stem_total": "дефоліатор - загальна кількість стебел",
  "depth": "Глибина",
  "desconocido": "Невідомий",
  "DESCONOCIDO": "НЕВІДОМИЙ",
  "diatraea_saccharalis_larva_big": "diatraea sachharalis - великі ларви",
  "diatraea_saccharalis_larva_healthy_chrysalis": "diatraea sachharalis - здорові хризаліди",
  "diatraea_saccharalis_larva_medium": "diatraea sachharalis - середні ларви",
  "diatraea_saccharalis_larva_residual_chrysalis": "diatraea sachharalis - залишкові хризаліди",
  "diatraea_saccharalis_larva_small": "diatraea sachharalis - маленькі ларви",
  "diatraea_saccharalis_larva_total": "diatraea sachharalis - всього ларви",
  "disease": "Хвороба",
  "Disease": "Хвороба",
  "disease_level": "08.Рівень захворювання",
  "distance_between_rows": "03.Відстань між рядами",
  "does_it_follow_the_recommendation": "f. Чи слідує воно рекомендаціям?",
  "Drip": "Крапельниця",
  "dry_weight": "Суха вага",
  "effective_cation_exchange_capacity": "Ефективна Катіонна Вмістимість Обміну (ЕКВО)",
  "ESCAROLA": "Ескарол",
  "ESCAROLA LISA": "Гладка Ескарол",
  "ESCAROLA RIZADA": "Кучерява Ескарол",
  "ESPARRAGO": "Спаржа",
  "ESPINACA": "Шпинат",
  "estrés hídrico": "Водний стрес",
  "Estrés hídrico (LWS)": "Водний стрес (LWS)",
  "evaluator": "10.Ім'я оцінювача",
  "Ex": "Приклад.",
  "exchangeable_acidity": "Obminna Kyslotnist'",
  "fallas": "Прогалини",
  "fire": "Пожежа",
  "flowering": "Цвітіння",
  "forecast_yield_sampling_1": "ПРОГНОЗ ВРОЖАЮ ВЗЯТТЯ 1",
  "forecast_yield_sampling_2": "ПРОГНОЗ ВРОЖАЮ ВЗЯТТЯ 2",
  "forecast_yield_sampling_3": "ПРОГНОЗ ВРОЖАЮ ВЗЯТТЯ 3",
  "forecast_yield_sampling_4": "ПРОГНОЗ ВРОЖАЮ ВЗЯТТЯ 4",
  "fresh_weight": "Свіжа вага",
  "FRIJOL": "Квасоля",
  "fruits_count": "Підрахунок фруктів",
  "FRUTAL": "Фруктовий",
  "Generic": "Загальний",
  "GEWURZTRAMINNER": "Гевюрцтрамінер",
  "GIRASOL": "Соняшник",
  "gm_avg_ndvi": "Вегетативний вігор (NDVI-Drone)",
  "gm_luvi_ndvi_mean": "Вегетативний вігор (NDVI-LUVI)",
  "gm_ndmi_mean": "Вегетативна волога (NDMI) ",
  "gm_ndre_mean": "Азот-Хлорофіл (NDRE)",
  "gm_perc_overseeding": "% Прогалини",
  "gm_perc_weeds": "% Бур'яни",
  "GODELLO": "Годелло",
  "GOLDEN DELICIOUS": "Золотий Делішес",
  "GOLDEN REINDERS": "Золоті Рейндерс",
  "Grams": "Грами",
  "GRANADA": "Гранат",
  "green_dots": "зелені точки",
  "GRENACHE BLANC": "Гренаш Блан",
  "GUISANTE": "Горох",
  "HABA": "Боби",
  "hanger_installation": "Встановлення сідала",
  "height": "Висота",
  "HIBRIDO": "Гібрид",
  "high irrigation": "Високий водний стрес",
  "high_n_from_irrigation_water": "d. Високий N з поливної води",
  "high_n_from_nitrogen_input": "e. Високий N з внесення азоту",
  "high_n_from_previous_years": "c. Високий N з попередніх років",
  "higher level": "Вищий рівень",
  "HIGO": "Інжир",
  "Humedad Vegetativa (NDMI) ": "Вегетативна волога (NDMI) ",
  "humidity": "Вологість",
  "humidity_100_beans": "Вологість у 100 бобах",
  "Imperial": "Імперіал",
  "inspection": "інспекція",
  "internodes_blastobasis_damage_longitude": "пошкодження довжини інтернодій blastobasis",
  "internodes_blastobasis_drilled": "просвердлені інтернодії blastobasis",
  "internodes_diatraea_damage_longitude": "пошкодження довжини інтернодій diatraea",
  "internodes_diatraea_drilled": "просвердлені інтернодії diatraea",
  "internodes_evaluated": "всього інтернодій",
  "internodes_healthy": "здорові інтернодії",
  "internodes_mechanical_damage": "внутрішнє механічне пошкодження",
  "internodes_physiological_damage": "фізіологічне пошкодження інтернодій",
  "iron": "Залізо",
  "irrigation": "ЗРОШЕННЯ",
  "Irrigation": "Зрошення",
  "irrigation_dosage": "Дозування зрошення",
  "irrigation_uniformity": "Рівномірність зрошення",
  "KUMQUATS": "Кумквати",
  "language": "Мова системи",
  "larva_number_big": "ларвальна стадія - велика ларва",
  "larva_number_medium": "ларвальна стадія - середня ларва",
  "larva_number_small": "ларвальна стадія - маленька ларва",
  "last_fertilizer_app_date": "b. Дата останнього внесення добрив",
  "last_irrigation": "Останнє зрошення",
  "lead": "Свинець",
  "Leaf": "Листок",
  "leaf_moisture_status": "Стан вологи листка",
  "LECHUGA": "Салат",
  "LECHUGA ICEBERG": "Салат Айсберг",
  "leptosphaeria_sacchari_incidence": "інцидентність \"leptosphaeria sacchari\"",
  "leptosphaeria_sacchari_severity": "тяжкість \"leptosphaeria sacchari\"",
  "leptosphaeria_sacchari_total_infected_leafs": "загальна кількість інфікованих листків \"leptosphaeria sacchari\"",
  "LIMON": "Лимон",
  "linear_meters": "02.Лінійні метри",
  "loam": "Суглинок",
  "loss_of_vigor": "Втрата життєвої сили",
  "low irrigation": "Низький водний стрес",
  "lower level": "Нижчий рівень",
  "lowered_unload": "03.Знижено при розвантаженні",
  "MACABEU": "Макабео",
  "MACABEU VIURA": "Макабео Віура",
  "magnesium": "Магній",
  "magnesium_saturation": "Насичення Магнієм",
  "MAIZ": "Кукурудза",
  "maleza (ciclo<60 dias)": "Бур'яни (Цикл<60 днів)",
  "MALVASIA AROMATICA": "Ароматична Мальвазія",
  "MALVASIA DE SITGES": "Мальвазія де Сітжес",
  "MALVASIA GROSSA": "Велика Мальвазія",
  "MANDARINA": "Мандарин",
  "manganese": "Марганець",
  "MANZANA": "Яблуко",
  "mean level": "Середній рівень",
  "medium irrigation": "Середній водний стрес",
  "medium_moisture_saturation": "Середня Вологість Насичення",
  "MELOCOTON": "Персик",
  "MELON": "Диня",
  "mercury": "Ртуть",
  "MERLOT": "Мерло",
  "Metric": "Метрична система",
  "microorganisms_larva": "ларви мікроорганізмів",
  "mite_grade": "середній клас кліщів",
  "mite_infested": "інфіковані кліщами",
  "mite_infested_leafs": "листя, уражене кліщами",
  "moisture_status": "07.Стан вологи",
  "molybdenum": "Молібден",
  "MONTONEC": "Монтонек",
  "MONTONEGA": "Монтонега",
  "MOSCATELL DE ALEXANDRIA": "Мускат Александрії",
  "MOSCATELL ROMA": "Мускат Рима",
  "NARANJA": "Апельсин",
  "NDRE": "NDRE",
  "NDVI": "NDVI",
  "NECATRINA": "Нектарин",
  "NECTARINA": "Нектарин",
  "nickel": "Нікель",
  "Nitrogen": "Азот",
  "Nitrógeno Foliar": "Листковий азот",
  "nitrógeno-clorofila (ndre)": "Азот-Хлорофіл (NDRE)",
  "Nitrógeno-Clorofila (NDRE)": "Азот-Хлорофіл (NDRE)",
  "No crop or too dry": "Немає врожаю або занадто сухо",
  "NO CULTIVO": "БЕЗ КУЛЬТИВАЦІЇ",
  "No hay datos": "Немає даних",
  "NOGAL": "Горіх",
  "notification_to_the_farmer_high_n_index": "g. Повідомлення фермеру: Високий індекс N",
  "Observations": "Спостереження",
  "OLIVO": "Оливка",
  "opogona_larva_number": "opogona - личинки",
  "organic_material": "Органічний матеріал",
  "organic_oxidizable_carbon": "Органічний Оксидабельний Вуглець",
  "PANSA BLANCA": "Біла Панса",
  "PANSAL": "Пансаль",
  "PARAGUAYO": "Парагваєць",
  "PARELLADA": "Парельяда",
  "PATATA": "Картопля",
  "pattern": "візерунок",
  "PERA": "Груша",
  "percent_disease": "Процент захворювання",
  "perkinsiella_345_instar_nymph": "perkinsiella - німфи 3-4-5 стадії",
  "perkinsiella_adult": "perkinsiella - дорослі",
  "perkinsiella_controller_coccinelidos": "perkinsiella - контролери - сонечка",
  "perkinsiella_controller_crysopas": "perkinsiella - контролери - хризопа",
  "perkinsiella_controller_spiders": "perkinsiella - контролери - павуки",
  "perkinsiella_controller_tytthus_parviceps": "perkinsiella - керманичі - tytthus parviceps",
  "perkinsiella_controller_zelus": "perkinsiella - керманичі - zelus",
  "perkinsiella_death_adult": "perkinsiella - мертві дорослі",
  "perkinsiella_death_nymph": "perkinsiella - мертві німфи",
  "petiole_sampling": "i. Зразок черешка",
  "ph": "Ph",
  "pheromone_trap_diatraea_adults_number": "пастка з феромонами - дорослі diatraea",
  "pheromone_trap_installation_date": "пастка з феромонами - дата встановлення",
  "pheromone_trap_number": "пастка з феромонами - пастки",
  "pheromone_trap_other_butterfly": "пастка з феромонами - інші метелики",
  "pheromone_trap_pheromone_change": "пастка з феромонами - зміна феромону",
  "phosphorus": "Фосфор",
  "PIMIENTO": "Перець",
  "PINO": "Сосна",
  "PINOT NOIR": "Піно Нуар",
  "PISTACHO": "Фісташка",
  "Pivot": "Півот",
  "Plague": "Чума",
  "plague_level": "09.Рівень чуми",
  "Plant": "Рослина",
  "Plant Density": "Щільність рослин",
  "plantation_date": "Дата посадки",
  "plants_per_surface_unit": "Рослини на одиницю площі",
  "poda": "Обрізка",
  "pol": "Pol",
  "POMELO": "Помело",
  "POMELOS": "Помелос",
  "potassium": "Калій",
  "potassium_saturation": "Насичення Калієм",
  "powdery_mildew_afa_perc": "Борошниста роса - AFA(%)",
  "powdery_mildew_perc_of_affected_plants": "Борошниста роса - % уражених рослин (ІНЦИДЕНТНІСТЬ)",
  "powdery_mildew_treatment": "Лікування борошнистої роси",
  "Production": "Виробництво",
  "pseudomonas_rubrilineans_stem_number": "червона смуга - загальна кількість стебел",
  "pseudomonas_rubrilineans_stem_number_affected": "червона смуга - уражені стебла",
  "pseudomonas_rubrilineans_stem_number_with_symptoms": "червона смуга - стебла з симптомами",
  "puccinia_melanocephala_accumulated_scale": "іржа - кумулятивна шкала",
  "puccinia_melanocephala_infestation": "іржа-інфестація",
  "puccinia_melanocephala_infested_leafs": "листя, уражене іржею",
  "puccinia_melanocephala_points": "іржа - пункти",
  "puccinia_melanocephala_total_leafs": "іржа - загальна кількість листя",
  "puccinia_sp_incidence": "інцидентність \"puccinia sp\"",
  "puccinia_sp_severity": "тяжкість \"puccinia sp\"",
  "puccinia_sp_total_infected_leafs": "загальна кількість інфікованих листків \"puccinia sp\"",
  "PUERRO": "Порей",
  "Quality": "Якість",
  "QUINOA": "Кіноа",
  "RAIGRAS": "Райграс",
  "real_leaves": "справжні листя",
  "reform": "Реформа",
  "REMOLACHA": "Цукровий буряк",
  "REMOLACHA AZUCARERA": "Цукровий буряк",
  "resowing_surface": "Пересівна поверхня",
  "result": "результат",
  "riego": "Зрошення",
  "rodents_infestation_percent": "Гризуни - зараження",
  "rot10roots_complete": "03.Гниття 10 коренів - Повне",
  "rot10roots_nothing": "01.Гниття 10 коренів - Нічого",
  "rot10roots_partial": "02.Гниття 10 коренів - Часткове",
  "rust_10_25_afa": "Іржа - 10-25 % AFA",
  "rust_100_afa": "Іржа -N- 100 % AFA. Первинне листя повністю знищене",
  "rust_25_35_afa": "Іржа -J- 25-35 % AFA. 1/4 -1/3 листя сильно уражені",
  "rust_3_10_afa": "Іржа -I- 3-10 % AFA",
  "rust_35_50_afa": "Іржа -K- 35-50 % AFA.  1/3 - 1/2 листя сильно уражені",
  "rust_50_75_afa": "Іржа -L- 50-75 % AFA. 1/2 листя сильно уражені",
  "rust_75_90_afa": "Іржа -M- 75-90 % AFA. Все листя сильно уражене",
  "rust_healthy_plant": "Іржа -A- Здорова рослина",
  "rust_isolated_spots_on_some_leaves_1_9_perc": "Іржа -B- Окремі плями на деяких листках. 1-9 (%SL)",
  "rust_isolated_spots_on_some_leaves_10_19_perc": "Іржа -C- Окремі плями на деяких листках. 10-19 (%SL)",
  "rust_isolated_spots_on_some_leaves_20_29_perc": "Іржа -D- Окремі плями на деяких листках. 20-29 (%SL)",
  "rust_isolated_spots_on_some_leaves_30_50_perc": "Іржа -E- Окремі плями на деяких листках. 30-50 (%SL)",
  "rust_regrowth_restart": "Іржа -O- Початок відростання. Додайте 0.5 пункти за кожен тиждень до збору врожаю",
  "rust_spots_leaves_100_300": "Іржа -H- Листки з 100-300 плямами",
  "rust_spots_leaves_20_100": "Іржа -G- 20-100 плям на листку на більшості листків",
  "rust_spots_leaves_50": "Іржа -F- >50% SL і <20 плям на листку на більшості листків",
  "rust_treatment": "Іржа -p- Лікування",
  "sac": "Сак",
  "Safety": "Безпека",
  "sample_color": "05.Колір зразка",
  "sand": "Пісок",
  "SAUVIGNON BLANC": "Совіньйон Блан",
  "severity": "Тяжкість",
  "soca_width": "04.Кількість коренів",
  "sodium": "Натрій",
  "sodium_saturation": "Насичення Натрієм",
  "Soil": "Ґрунт",
  "soil_moisture_status": "Стан вологи ґрунту",
  "SOJA": "Соя",
  "sowing_failure": "Невдала сівба",
  "sprout_dead": "мертва паросток",
  "sprout_total": "загальна кількість відростків",
  "starch": "Крохмаль",
  "stems_evaluated": "оцінені стебла",
  "sulfur": "Сірка",
  "Superficial": "Поверхневий",
  "SYRAH": "Сира",
  "TABACO": "Тютюн",
  "TE": "Чай",
  "TEA": "СМОЛОСКИП",
  "TEMPRANILLO": "Темпранільйо",
  "TOMATE": "Помідор",
  "tombing": "Полягання рослин",
  "total_arsenic": "Загальний Арсен",
  "total_cadmium": "Загальний Кадмій",
  "TOURIGA NACIONAL": "Туріга Насьонал",
  "TRIGO": "Пшениця",
  "TRIGO BLANDO": "М'яка пшениця",
  "TRIGO DURO": "Тверда пшениця",
  "TRITICALE": "Тритикале",
  "type_disease": "Тип захворювання",
  "uneven_budding": "Нерівномірне брунькування",
  "ustilago_scitaminea_infection": "інфекція ustilago scitaminea \"вугілля\"",
  "ustilago_scitaminea_stem_affected": "ustilago scitaminea \"деревне вугілля\" - загальні хворі стебла",
  "ustilago_scitaminea_stem_symptoms": "ustilago scitaminea \"вугілля\" - стебла з симптомами",
  "ustilago_scitaminea_stem_total": "ustilago scitaminea \"вугілля\" - цілі стебла",
  "ustilago_scitaminea_stem_whip": "ustilago scitaminea \"вугілля\" - стебла-батоги",
  "UVA": "Виноград",
  "UVA DE MESA": "Столовий виноград",
  "Variabilidad": "Змінність",
  "Variabilidad (NDVI-LUVI)": "Змінність (NDVI-LUVI)",
  "VERDEJO": "Вердехо",
  "very high irrigation": "Дуже високий водний стрес",
  "very low irrigation": "Дуже низький водний стрес",
  "VIGNE": "Вігн",
  "Vigor Vegetativo (NDVI-Drone)": "Вегетативний вігор (NDVI-Drone)",
  "Vigor Vegetativo (NDVI-LUVI)": "Вегетативний вігор (NDVI-LUVI)",
  "vigor vegetativo (ndvi) - vigor alto": "Вегетативний вігор (NDVI) - Високий вігор",
  "VIÑA": "Виноградник",
  "VIOGNIER": "Віоньє",
  "virgin_female_georeferenced_trap_diatraea_adult_total": "пастка дівоча дівчина - загальні дорослі diatraea",
  "virgin_female_georeferenced_trap_female_change": "пастка дівоча дівчина - зміна самки",
  "virgin_female_georeferenced_trap_other_butterfly": "пастка дівоча дівчина - інші метелики",
  "virgin_female_georeferenced_trap_point": "пастка дівоча дівчина - точка пастки",
  "virgin_female_trap_diatraea_adult_number": "пастка дівоча дівчина - дорослі diatraea",
  "virgin_female_trap_female_change": "пастка дівоча дівчина - зміна самки",
  "virgin_female_trap_installation_date": "пастка дівоча дівчина - дата встановлення",
  "virgin_female_trap_number": "пастка дівоча дівчина - пастки",
  "virgin_female_trap_other_butterfly": "пастка дівоча дівчина - інші метелики",
  "VIURA": "Віура",
  "weed_unload": "01. Бур'яни (розвантаження)",
  "Weeds": "Бур'яни",
  "weight_100_beans": "Рослини 100 бобів",
  "weight_kg": "06.Вага зразка",
  "whey": "Сироватка",
  "without_footwear": "01.Без взуття",
  "without_gloves": "04.Без рукавичок",
  "without_vest": "02.Без жилета",
  "XARELLO": "Ксарелло",
  "yellowness_mild": "Жовтість - Слабкий напад (розсипані рослини)",
  "yellowness_moderate": "Жовтість - Помірний напад (стійки)",
  "yellowness_strong": "Жовтість - Сильний напад (ділянка повністю уражена)",
  "yellowness_without_symptoms": "Жовтизна - Без симптомів",
  "zinc": "Цинк"
}
